import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  notification,
  Progress,
  Row,
  Select,
  Slider,
  Switch,
  Skeleton,
  Space,
} from "antd";
import patientRoundFormStyles from "./NewIntegratedRoundForm.module.css";
import { Content } from "antd/lib/layout/layout";
import { useNavigate, useParams } from "react-router-dom";
import { useUserStore } from "../../../../context/UserStore";
import { set } from "react-hook-form";

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 8,
  },
};

export default function NewIntegratedRoundForm() {
  const navigate = useNavigate();
  const [completionPercentage, setCompletionPercentage] = useState(0);
  const [employees, setEmployees] = useState([]);
  const [employeeChoices, setEmployeeChoices] = useState([]);
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({});
  const { roundType } = useParams();
  const [loadingEmployees, setLoadingEmployees] = useState(true);
  const [loadingUnits, setLoadingUnits] = useState(true);
  const [roundTypeText, setRoundTypeText] = useState("");
  const { TextArea } = Input;
  const [questions, setQuestions] = useState([]);
  const [unitChoices, setUnitChoices] = useState([]);
  const user = useUserStore((state) => state.user);

  const createIntegratedRound = async () => {
    return fetch(`${process.env.REACT_APP_API_URL}/api/v1/users/interactions`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify({
        interaction: {
          questions: formData,
          round_type: roundType,
          interaction_type: 1,
        },
      }),
      type: "json",
    })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((response) => {
        notification.success({
          message: "New round created successfully",
          description: "",
        });
        navigate(`/teach/integrated-rounds`);
      })
      .catch((error) => {
        notification.error({
          message: error.message,
        });
        navigate(`/teach/integrated-rounds/new`);
      });
  };

  const getEmployees = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/users/employees`,
      {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        type: "json",
      },
    )
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((response) => {
        setEmployeeChoices(
          response.employees.data.map((employee) => {
            return {
              key: employee.id,
              value: employee.id,
              label: employee.attributes.full_name,
            };
          }),
        );
        setEmployees(response.employees.data);
        setLoadingEmployees(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // When the user selects a unit, filter the employees to only those in that unit
  // Unit ID is passed in as a parameter from the UnitSelect component
  // unitID is the ID of the Unit attribute which is under relationships in the JSON
  const filterEmployeesByUnit = (unitId) => {
    const filteredEmployees = employees.filter((item) => {
      if (
        item.relationships &&
        item.relationships.units &&
        Array.isArray(item.relationships.units.data)
      ) {
        return item.relationships.units.data.some(
          (unit) => unit.id === unitId.toString(),
        );
      }
      return false;
    });
    setEmployeeChoices(
      filteredEmployees.map((employee) => {
        return {
          key: employee.id,
          value: employee.id,
          label: employee.attributes.full_name,
        };
      }),
    );
  };

  const getQuestions = async (value) => {
    getEmployees();
    getUnits();
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/users/questions/by_round_type/${roundType}`,
      {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        type: "json",
      },
    )
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((response) => {
        const newData = response.questions.data.map((q) => {
          return {
            key: q.id,
            help_before: q.attributes.help_before,
            help_after: q.attributes.help_after,
            label: q.attributes.text,
            page_number: q.attributes.page_number,
            perspective: q.attributes.perspective,
            question_type: q.attributes.question_type,
            subtext: q.attributes.subtext,
          };
        });
        setQuestions(newData);
        setLoadingUnits(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getRoundType = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/round_types/${roundType}`,
      {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        type: "json",
      },
    )
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((response) => {
        setRoundTypeText(response.roundType.data.attributes.name);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getUnits = async (page, pageSize) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/users/units`,
      {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        type: "json",
      },
    )
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((response) => {
        const newData = response.units.data.map((unit) => {
          return {
            key: unit.id,
            label: unit.attributes.name,
            value: unit.id,
          };
        });
        setUnitChoices(newData);
        setLoadingUnits(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

 const onFieldsChange = (changedFields, allFields) => {
    changedFields.map((field) => {
      setFormData({ ...formData, [field.name[0]]: field.value });
    });
  };

  const onFinish = (values) => {
    createIntegratedRound();
    setCompletionPercentage(0);
    // navigate(`/teach/integrated-rounds/new`);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    notification["error"]({
      message: "Failed to create new patient round",
      description: errorInfo.errors[0].message,
    });
  };

  const onReset = () => {
    form.resetFields();
  };

  useEffect(() => {
    getRoundType(roundType);
    getQuestions();
  }, [alert]);

  return (
    <>
      <div
        className={`py-5 mb-5 flex flex-col items-center justify-center ${patientRoundFormStyles.headerBar}`}
      >
        <h1 className="text-4xl text-center text-white">
          New Round - {roundTypeText} 
        </h1>
      </div>
      <Content>
        <Row>
          <Col span={22}>
            <Skeleton loading={loadingUnits && loadingEmployees} active>
              <Progress percent={completionPercentage} status="active" />
              <Form
                {...layout}
                form={form}
                layout="vertical"
                onFieldsChange={onFieldsChange}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                {unitChoices.length > 0 &&
                  employees.length > 0 &&
                  questions.map((question, index) => {
                    {
                      question.perspective != "1" ? (
                        <h3 className={`text-xl`}>
                          {question.subtext ? question.subtext : "Ask"}
                        </h3>
                      ) : (
                        ""
                      );
                    }

                    switch (question.question_type) {
                      case 10:
                        return (
                          <div className="border-solid border-2 p-5 mb-5">
                            <Form.Item
                              name={question.key}
                              label={question.label}
                            >
                              <Select
                                options={unitChoices}
                                placeholder="Select a Unit"
                                onChange={(value) => {
                                  setCompletionPercentage(5);
                                  filterEmployeesByUnit(value);
                                }}
                              />
                            </Form.Item>
                          </div>
                        );
                      case 0:
                        return (
                          <div className="border-solid border-2 p-5 mb-5">
                            <Form.Item
                              name={question.key}
                              label={question.label}
                            >
                              <Input
                                onChange={(value) => {
                                  setCompletionPercentage(20);
                                }}
                              />
                            </Form.Item>
                          </div>
                        );
                      case 1:
                        return (
                          <div className="border-solid border-2 p-5 mb-5">
                            {question.help_before && (
                              <p>{question.help_before}</p>
                            )}
                            {question.perspective != "1" ? (
                              <h3 className={`text-xl`}>
                                {question.subtext ? question.subtext : "Ask"}
                              </h3>
                            ) : (
                              ""
                            )}
                            <Form.Item
                              initialvalues={0}
                              name={question.key}
                              label={question.label}
                            >
                              <Slider
                                marks={{
                                  0: "0",
                                  1: "1",
                                  2: "2",
                                  3: "3",
                                  4: "4",
                                  5: "5",
                                }}
                                min={0}
                                max={5}
                              />
                            </Form.Item>
                            {question.help_after && (
                              <p>{question.help_after}</p>
                            )}
                          </div>
                        );
                      case 3:
                        return (
                          <div className="border-solid border-2 p-5 mb-5">
                            {question.help_before && (
                              <p>{question.help_before}</p>
                            )}
                            {question.perspective != "1" ? (
                              <h3 className={`text-xl`}>
                                {question.subtext ? question.subtext : "Ask"}
                              </h3>
                            ) : (
                              ""
                            )}
                            <Form.Item
                              name={question.key}
                              label={question.label}
                            >
                              <Switch
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                onChange={(value) => {
                                  setCompletionPercentage(20);
                                }}
                              />
                            </Form.Item>
                            {question.help_after && (
                              <p>{question.help_after}</p>
                            )}
                          </div>
                        );
                      case 4:
                        return (
                          <div className="border-solid border-2 p-5 mb-5">
                            {question.help_before && (
                              <p>{question.help_before}</p>
                            )}
                            {question.perspective != "1" ? (
                              <h3 className={`text-xl`}>
                                {question.subtext ? question.subtext : ""}
                              </h3>
                            ) : (
                              ""
                            )}
                            <Form.Item
                              name={question.key}
                              label={question.label}
                            >
                              <TextArea
                                onChange={(value) => {
                                  setCompletionPercentage(20);
                                }}
                              />
                            </Form.Item>
                            {question.help_after && (
                              <p>{question.help_after}</p>
                            )}
                          </div>
                        );
                      case 5:
                        return (
                          <div className="border-solid border-2 p-5 mb-5">
                            {question.help_before && (
                              <p>{question.help_before}</p>
                            )}
                            {question.perspective != "1" ? (
                              <h3 className={`text-xl`}>
                                {question.subtext ? question.subtext : ""}
                              </h3>
                            ) : (
                              ""
                            )}
                            <Form.Item
                              name={question.key}
                              label={question.label}
                            >
                              <Select
                                mode="multiple"
                                options={employeeChoices}
                                loading={loadingEmployees}
                                filterOption={(input, option) =>
                                  (option?.label ?? "").includes(input)
                                }
                                filterSort={(optionA, optionB) =>
                                  (optionA?.label ?? "")
                                    .toLowerCase()
                                    .localeCompare(
                                      (optionB?.label ?? "").toLowerCase(),
                                    )
                                }
                                onChange={(value) => {
                                  setCompletionPercentage(20);
                                }}
                              />
                            </Form.Item>
                            {question.help_after && (
                              <p>{question.help_after}</p>
                            )}
                          </div>
                        );
                      default:
                        return (
                          <div className="border-solid border-2 p-5 mb-5">
                            <Form.Item
                              loading={loadingEmployees}
                              name={question.key}
                              label={question.label}
                            >
                              <Input
                                onChange={(value) => {
                                  setCompletionPercentage(20);
                                }}
                              />
                            </Form.Item>
                          </div>
                        );
                    }
                  })}
                <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-12 sm:col-span-2">
                      <Form.Item
                        wrapperCol={{
                          offset: 8,
                          span: 16,
                        }}
                      >
                        <Button
                          className="bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 rounded"
                          htmlType="submit"
                        >
                          Submit
                        </Button>
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </Form>
            </Skeleton>
          </Col>
        </Row>
      </Content>
    </>
  );
}
