import React from "react";
import { Button, message, Select } from "antd";
import { useNavigate } from "react-router-dom";
import newFeedbackStyles from "./NewFeedback.module.css";
import { useUserStore } from "../../../context/UserStore";
import monitoringResultsStyles from "../../Align/MonitoringResults/monitoringResults.module.css";
const { useState, useEffect } = React;

export default function NewFeedback() {
  const navigate = useNavigate();
  const [employee, setEmployee] = useState({});
  const [employees, setEmployees] = useState([]);
  const [feedback_types, setFeedbackTypes] = useState([]);
  const [feedback_type, setFeedbackType] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const user = useUserStore((state) => state.user);

  const customFetch = async (values) => {
    setIsLoading(true);
    return fetch(`${process.env.REACT_APP_API_URL}/api/v1/users/employees`, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      type: "json",
    })
      .then((response) => {
        if (!response.ok) {
          message.error("Something went wrong. Please try again later.");
          setIsLoading(false);
          return;
        }
        return response.json();
      })
      .then((response) => {
        response.employees.data.map((employee) => {
          employee.key = employee.id;
          employee.label = employee.attributes.full_name;
          employee.value = employee.id;
        });
        setIsLoading(false);
        setEmployees(response.employees.data);
        setFeedbackTypes(response.mentor_types.data);
      })
      .catch((error) => {
        message.error("Something went really wrong. Please try again later.");
        setIsLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    customFetch({});
  }, []);

  return (
    <>
      <div className={`py-8 mb-10 flex items-center justify-center rounded-lg shadow-lg ${newFeedbackStyles.headerBar}`}
           style={{ background: 'linear-gradient(135deg, #2485B2 0%, #70AEDD 100%)', color: '#fff' }}
      >
        <h1 className="text-4xl md:text-4xl text-center font-bold text-white tracking-wide">Add Mentoring Feedback</h1>
      </div>

      <div className="shadow sm:rounded-md sm:overflow-hidden">
        <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
          <div className="max-w-lg mx-auto">
            <Select
              className="w-full"
              options={employees}
              placeholder="Select an employee"
              onChange={(value) => setEmployee(value)}
            />
          </div>
        </div>
        <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
          <div className="max-w-lg mx-auto">
            <Select
              className="w-full"
              options={feedback_types}
              placeholder="Select a Feedback Type"
              onChange={(value) => setFeedbackType(value)}
            />
          </div>
        </div>
        <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
          <div className="flex justify-center">
            <Button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 rounded"
              onClick={() =>
                navigate(
                  `/my-employees/mentor/feedback/${employee}/${feedback_type}`,
                )
              }
            >
              Next
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
