import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Button, Form, Image, Input, Layout, notification } from 'antd';
import './Login.css';
import { useUserStore } from '../../context/UserStore';

export default function Login() {
    const [email, setEmail] = useState();
    const [loadingHospital, setLoadingHospital] = useState(true);
    const setMe = useUserStore((state) => state.setMe);
    const setUser = useUserStore((state) => state.setUser);
    const [welcomeMessage, setWelcomeMessage] = useState('');
    const [logo, setLogo] = useState(null);
    const [password, setPassword] = useState();
    const navigate = useNavigate();

    // Get the subdomain from the URL
    const subdomain = window.location.hostname.split('.')[0];

    const getCurrentUser = async (user) => {
        return fetch(`${process.env.REACT_APP_API_URL}/api/v1/users/me`, {
            method: "get",
            headers: {
                'Accept': 'application/json',
                "Content-Type": "application/json",
                "Authorization": `Bearer ${user.token}`,
            },
        })
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    throw new Error(res);
                }
            })
            .then((json) => {
                setMe(json.user.data);
                navigate("/");
                window.location.reload(false);
            })
            .catch((err) => {
                notification.error({
                    message: 'Login Failed',
                    description: 'Please check your credentials and try again.',
                });
            });
    };

    // Find out what hospital the user is associated with based upon the subdomain
    const getHospital = async (subdomain) => {
        return fetch(`${process.env.REACT_APP_API_URL}/api/v1/hospitals/find_by_subdomain/${subdomain}`, {
            method: "get",
            headers: {
                'Accept': 'application/json',
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    throw new Error(res);
                }
            })
            .then((json) => {
                setLoadingHospital(false);
                setWelcomeMessage(json.welcome_message);
                setLogo(`${subdomain}.png`);
                localStorage.setItem('hospital', JSON.stringify(json.hospital.data));
            })
            .catch((err) => {
                setWelcomeMessage('Welcome to MyTeamToday!')
                setLogo('MyTEAM-main-logo.png');

            });
    };

    const loginUser = async (credentials) => {
        return fetch(`${process.env.REACT_APP_API_URL}/api/v1/users/sign_in`, {
            method: "post",
            headers: {
                'Accept': 'application/json',
                "Content-Type": "application/json",
            },
            body: JSON.stringify(credentials),
        })
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    throw new Error(res);
                }
            })
            .then((json) => {
                setUser(json.user);
                getCurrentUser(json.user);
            })
            .catch((err) => {
                notification.error({
                    message: 'Login Failed',
                    description: 'Please check your credentials and try again.',
                });
            });
    };

    const onFinish = (values) => {
        loginUser({ user: { email: values.email, password: values.password } });
    };

    const onFinishFailed = (errorInfo) => {
        notification.error({
            message: 'Failed to Login',
            description: 'Please correct the errors and try again.',
            onClick: () => {
                console.log('Notification Clicked!');
            },
        });
    };

    const onFinishForgotPasswordFailed = (errorInfo) => {
        notification.error({
            message: 'Failed to Send Password Reset Email',
            description: 'Please correct the errors and try again.',
            onClick: () => {
                console.log('Notification Clicked!');
            },
        });
    };

    const onFinishForgotPassword = (values) => {
        resetPassword({ user: { email: values.email } });
    };

    const resetPassword = async (values) => {
        return fetch(`${process.env.REACT_APP_API_URL}/api/v1/users/forgot_password`, {
            method: "post",
            headers: {
                'Accept': 'application/json',
                "Content-Type": "application/json",
            },
            body: JSON.stringify(values),
        })
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    throw new Error(res);
                }
            })
            .then((json) => {
                notification.success({
                    message: 'Request Received',
                    description: 'Your request was received. If the email exists in our system, you will receive an email with instructions to reset your password.',
                });
            })
            .catch((err) => {
                notification.error({
                    message: 'Request Received',
                    description: 'Your request was received. If the email exists in our system, you will receive an email with instructions to reset your password.',
                });
            });
    };

    useEffect(() => {
        getHospital(subdomain);
    }, []);

    return (
        <Layout>
            <div className="container mx-auto mb-4">
                <section className="login-container">
                    <div className="md:grid md:grid-cols-3 md:gap-6">
                        <div className="md:col-span-1">
                            <div className="px-4 sm:px-0">
                                {loadingHospital == false ? <Image style={{ padding: '10px', marginTop: '15px' }} src={`/assets/images/hospitals/${logo}`} preview={false} /> : null}
                                <Image style={{ paddingTop: '20px', marginTop: '15px' }} src='/assets/images/hospitals/myTEAM_wo_tagline.png' preview={false} />
                            </div>
                        </div>
                        <div className="mt-5 md:mt-0 md:col-span-2 px-0 pt-3 pb-0 bg-gray-50 text-right sm:px-0 ">
                            <h3 className="text-left p-2 text-xl font-normal leading-6 text-white mr-1 rounded bg-blue-700">Login</h3>
                            <Form
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                            >
                                <div className="shadow sm:rounded-md sm:overflow-hidden">
                                    <div className="px-5 py-3 bg-white space-y-2 sm:p-2 ">
                                        <div className="columns-1">
                                            <div className="">
                                                <Form.Item
                                                    label="Email Address"
                                                    name="email"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please enter your email address.',
                                                        },
                                                    ]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="px-5 py-3 bg-white space-y-2 sm:p-2">
                                        <div className="columns-1">
                                            <div className="">
                                                <Form.Item
                                                    label="Password"
                                                    name="password"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please enter your password.',
                                                        },
                                                    ]}
                                                >
                                                    <Input.Password visibilityToggle={true} />
                                                </Form.Item>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="px-4 py-2 text-right sm:px-3 bg-[#365060]">
                                        <Form.Item>
                                            <Button
                                                htmlType='submit'
                                                className='hover:bg-blue-700 text-white font-bold px-4 rounded'
                                            >
                                                Login
                                            </Button>
                                        </Form.Item>

                                    </div>
                                </div>
                            </Form>
                            <hr className="my-3" />
                            <div className="px-0 pt-3 pb-0 bg-gray-50 text-right sm:px-0">
                                {/*bg-[#e17e0e]*/}
                                <h3 className="text-left p-2 text-xl font-normal leading-6 text-white bg-blue-700 mr-1 rounded">If you forgot your password, please enter your email below.  </h3>
                                <Form
                                    onFinish={onFinishForgotPassword}
                                    onFinishFailed={onFinishForgotPasswordFailed}
                                >
                                    <div className="shadow sm:rounded-md sm:overflow-hidden">
                                        <div className="px-4 py-2 bg-white space-y-2 sm:p-3">
                                            <div className="columns-1">
                                                <div className="">
                                                    <Form.Item
                                                        label="Email Address"
                                                        name="email"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please enter your email address.',
                                                            },
                                                        ]}
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="px-4 py-2 text-right sm:px-3 bg-[#365060]">
                                            <Form.Item>
                                                <Button
                                                    className='hover:bg-blue-700 text-white font-bold px-4 rounded'
                                                    htmlType="submit"
                                                >
                                                    Reset Password
                                                </Button>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                            <hr className="my-2" />
                        </div>
                    </div>
                </section>
            </div>
        </Layout>
    )
}