import React, { useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AdminMonitoringResults from "./components/Admin/AdminMetrics/AdminMonitoringResults";
import AdminEditMetric from "./components/Admin/AdminMetrics/Metrics/AdminEditMetric/AdminEditMetric";
import AdminEditMetricStatus from "./components/Admin/AdminMetrics/Metrics/AdminEditMetricStatus/AdminEditMetricStatus";
import AdminNewMetric from "./components/Admin/AdminMetrics/Metrics/AdminNewMetric/AdminNewMetric";
import AdminViewMetric from "./components/Admin/AdminMetrics/Metrics/AdminViewMetric/AdminViewMetric";
import EditEmployee from "./components/Admin/Employees/EditEmployee/EditEmployee";
import Employee from "./components/Admin/Employees/Employee/Employee";
import Employees from "./components/Admin/Employees/Employees";
import NewEmployee from "./components/Admin/Employees/NewEmployee/NewEmployee";
import EditHospital from "./components/Admin/Hospitals/EditHospital/EditHospital";
import Hospital from "./components/Admin/Hospitals/Hospital/Hospital";
import Hospitals from "./components/Admin/Hospitals/Hospitals";
import ImportHospitalStaff from "./components/Admin/Hospitals/ImportHospitalStaff/ImportHospitalStaff";
import NewHospital from "./components/Admin/Hospitals/NewHospital/NewHospital";
import EditQuestion from "./components/Admin/Questions/EditQuestion/EditQuestion";
import NewQuestion from "./components/Admin/Questions/NewQuestion/NewQuestion";
import Question from "./components/Admin/Questions/Question/Question";
import Questions from "./components/Admin/Questions/Questions";
import EditUnit from "./components/Admin/Units/EditUnit/EditUnit";
import NewUnit from "./components/Admin/Units/NewUnit/NewUnit";
import Unit from "./components/Admin/Units/Unit/Unit";
import Units from "./components/Admin/Units/Units";
import AdminEditUser from "./components/Admin/Users/AdminEditUser/AdminEditUser";
import AdminNewUser from "./components/Admin/Users/AdminNewUser/AdminNewUser";
import AdminUser from "./components/Admin/Users/AdminUser/AdminUser";
import AdminUsers from "./components/Admin/Users/AdminUsers";
import CascadingGoals from "./components/Align/CascadingGoals/CascadingGoals";
import EditMetric from "./components/Align/MonitoringResults/Metrics/EditMetric/EditMetric";
import EditMetricStatus from "./components/Align/MonitoringResults/Metrics/EditMetricStatus/EditMetricStatus";
import NewMetric from "./components/Align/MonitoringResults/Metrics/NewMetric/NewMetric";
import ViewMetric from "./components/Align/MonitoringResults/Metrics/ViewMetric/ViewMetric";
import MonitoringResults from "./components/Align/MonitoringResults/MonitoringResults";
import ChangePassword from "./components/ChangePassword/ChangePassword";
import CreateAccount from "./components/CreateAccount/CreateAccount";
import EditSharedDecision from "./components/Empower/SharedDecisions/EditSharedDecision/EditSharedDecision";
import NewSharedDecision from "./components/Empower/SharedDecisions/NewSharedDecision/NewSharedDecision";
import SharedDecision from "./components/Empower/SharedDecisions/SharedDecision/SharedDecision";
import SharedDecisions from "./components/Empower/SharedDecisions/SharedDecisions";
import Help from "./components/Help/Help";
import Home from "./components/Home/Home";
import Login from "./components/Login/Login";
import Coaching from "./components/Mentor/Coaching/Coaching";
import DevelopmentDialogues from "./components/Mentor/DevelopmentDialogues/DevelopmentDialogues";
import EditMyEmployee from "./components/Mentor/EditMyEmployee/EditMyEmployee";
import CreateDailyCoaching from "./components/Mentor/Feedback/FeedbackForms/DailyCoaching/CreateDailyCoaching";
import EditDailyCoaching from "./components/Mentor/Feedback/FeedbackForms/DailyCoaching/EditDailyCoaching";
import CreateDevelopmentDialogue from "./components/Mentor/Feedback/FeedbackForms/DevelopmentDialogue/CreateDevelopmentDialogue";
import CreateObservation from "./components/Mentor/Feedback/FeedbackForms/Observation/CreateObservation";
import CreateObservationRss from "./components/Mentor/Feedback/FeedbackForms/ObservationRss/CreateObservationRss";
import NewFeedback from "./components/Mentor/Feedback/NewFeedback";
import MyEmployee from "./components/Mentor/MyEmployee/Employee";
import MyEmployees from "./components/Mentor/MyEmployees/MyEmployees";
import NewMyEmployee from "./components/Mentor/NewMyEmployee/NewMyEmployee";
import MentorReview from "./components/Mentor/Review/MentorReview";
import NoMatch from "./components/NoMatch/NoMatch";
import Preferences from "./components/Preferences/Preferences";
import ReportHelp from "./components/ReportHelp/ReportHelp";
import ResetPassword from "./components/ResetPassword/ResetPassword";
import NavBar from "./components/Shared/NavBar";
import SideNavBar from "./components/Shared/SideNavBar";
import DailyHuddles from "./components/Teach/DailyHuddles/DailyHuddles";
import IntegratedRound from "./components/Teach/IntegratedRound/IntegratedRound";
import IntegratedRounds from "./components/Teach/IntegratedRounds/IntegratedRounds";
import NewIntegratedRoundForm from "./components/Teach/NewIntegratedRound/IntegratedRoundForms/NewIntegratedRoundForm";
import NewIntegratedRound from "./components/Teach/NewIntegratedRound/NewIntegratedRound";
import StaffMeetings from "./components/Teach/StaffMeetings/StaffMeetings";
import TechnicalHelp from "./components/TechnicalHelp/TechnicalHelp";
import EditUser from "./components/User/EditUser";
import User from "./components/User/User";
import UserAuthContainer from "./components/UserAuthContainer/UserAuthContainer";
import { useUserStore } from "./context/UserStore";
import "./styles/App.less";

import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function App() {
  const user = useUserStore((state) => state.user);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  if (!user.token) {
    return <UserAuthContainer />;
  }

  return (
    <>
      <div className="App">
        <NavBar />
        <div className="flex justify-between items-center px-4 py-3 bg-[#365060]">
          <button
            className="absolute top-0 right-6 mt-4 mr-4 text-white cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent lg:hidden outline-none focus:outline-none z-50"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <FontAwesomeIcon icon={faBars} />
          </button>
        </div>
        <div className={`grid grid-cols-12 gap-4 h-full`}>
          <div
            id="SideNavBarWrapper"
            className={`absolute top-0 left-0 bg-[#365060] h-full ${isMenuOpen ? "block z-50" : "hidden"} lg:static lg:block lg:col-span-2 transition-all duration-300`}
          >
            <SideNavBar />
          </div>
          <div
            id="MainContent"
            className={`col-span-12 lg:col-span-10 transition-all duration-300 justify-between px-4`}
          >
            <Routes>
              <Route path="/" element={<Home />} />
              {/* Admion Routes Start */}
              <Route
                path="/admin/employees"
                element={<Employees token={user.token} />}
              />
              <Route
                path="/admin/employees/import"
                element={<ImportHospitalStaff token={user.token} />}
              />
              <Route
                path="/admin/employees/new"
                element={<NewEmployee token={user.token} />}
              />
              <Route
                path="/admin/employees/:id"
                element={<Employee token={user.token} />}
              />
              <Route
                path="/admin/employees/:id/edit"
                element={<EditEmployee token={user.token} />}
              />
              <Route
                path="/admin/hospitals"
                element={<Hospitals token={user.token} />}
              />
              <Route
                path="/admin/hospitals/new"
                element={<NewHospital token={user.token} />}
              />
              <Route
                path="/admin/hospitals/:id"
                element={<Hospital token={user.token} />}
              />
              <Route
                path="/admin/hospitals/:id/import"
                element={<ImportHospitalStaff token={user.token} />}
              />
              <Route
                path="/admin/hospitals/:id/edit"
                element={<EditHospital token={user.token} />}
              />
              <Route
                path="/admin/monitoring-results"
                element={<AdminMonitoringResults token={user.token} />}
              />
              <Route
                path="/admin/monitoring-results/metrics/new"
                element={<AdminNewMetric token={user.token} />}
              />
              <Route
                path="/admin/monitoring-results/metrics/:metricId/edit"
                element={<AdminEditMetric token={user.token} />}
              />
              <Route
                path="/admin/monitoring-results/metrics/:metricId/status"
                element={<AdminEditMetricStatus token={user.token} />}
              />
              <Route
                path="/admin/monitoring-results/metrics/:metricId"
                element={<AdminViewMetric token={user.token} />}
              />
              <Route
                path="/admin/questions"
                element={<Questions token={user.token} />}
              />
              <Route
                path="/admin/questions/new"
                element={<NewQuestion token={user.token} />}
              />
              <Route
                path="/admin/questions/:id"
                element={<Question token={user.token} />}
              />
              <Route
                path="/admin/questions/:id/edit"
                element={<EditQuestion token={user.token} />}
              />
              <Route
                path="/admin/units/new/"
                element={<NewUnit token={user.token} />}
              />
              <Route
                path="/admin/units/:id"
                element={<Unit token={user.token} />}
              />
              <Route
                path="/admin/units/:id/edit"
                element={<EditUnit token={user.token} />}
              />
              <Route
                path="/admin/units"
                element={<Units token={user.token} />}
              />
              <Route
                path="/admin/users"
                element={<AdminUsers token={user.token} />}
              />
              <Route
                path="/admin/users/import"
                element={<ImportHospitalStaff token={user.token} />}
              />
              <Route
                path="/admin/users/new"
                element={<AdminNewUser token={user.token} />}
              />
              <Route
                path="/admin/users/:id"
                element={<AdminUser token={user.token} />}
              />
              <Route
                path="/admin/users/:id/edit"
                element={<AdminEditUser token={user.token} />}
              />
              {/* Admin Routes End */}

              <Route path="/user/:id" element={<User token={user.token} />} />
              <Route
                path="/user/:id/edit"
                element={<EditUser token={user.token} />}
              />
              <Route
                path="/align/cascading-goals"
                element={<CascadingGoals token={user.token} />}
              />
              <Route
                path="/align/monitoring-results"
                element={<MonitoringResults token={user.token} />}
              />
              <Route
                path="/align/monitoring-results/metrics/new"
                element={<NewMetric token={user.token} />}
              />
              <Route
                path="/align/monitoring-results/metrics/:metricId/edit"
                element={<EditMetric token={user.token} />}
              />
              <Route
                path="/align/monitoring-results/metrics/:metricId/status"
                element={<EditMetricStatus token={user.token} />}
              />
              <Route
                path="/align/monitoring-results/metrics/:metricId"
                element={<ViewMetric token={user.token} />}
              />
              <Route
                path="/change-password"
                element={<ChangePassword token={user.token} />}
              />
              <Route
                path="/empower/shared-decisions"
                element={<SharedDecisions token={user.token} />}
              />
              <Route
                path="/empower/shared-decisions/new"
                element={<NewSharedDecision token={user.token} />}
              />
              <Route
                path="/empower/shared-decisions/:id"
                element={<SharedDecision token={user.token} />}
              />
              <Route
                path="/my-employees/feedback/new"
                element={<NewFeedback token={user.token} />}
              />
              <Route
                path="/empower/shared-decisions/:id/edit"
                element={<EditSharedDecision token={user.token} />}
              />
              <Route
                path="/my-employee/:id"
                element={<MyEmployee token={user.token} />}
              />
              <Route
                path="/my-employee/:id/edit"
                element={<EditMyEmployee token={user.token} />}
              />
              <Route
                path="/empower/shared-decisions/:id"
                element={<SharedDecision token={user.token} />}
              />
              <Route
                path="/empower/shared-decisions/:id"
                element={<SharedDecision token={user.token} />}
              />
              <Route
                path="/empower/shared-decisions/:id/edit"
                element={<EditSharedDecision token={user.token} />}
              />
              <Route
                path="/my-employees/mentor/feedback/:employeeId/0"
                element={<CreateDailyCoaching token={user.token} />}
              />
              <Route
                path="/my-employees/mentor/feedback/:employeeId/1"
                element={<CreateObservation token={user.token} />}
              />
              <Route
                path="/my-employees/mentor/feedback/:employeeId/2"
                element={<CreateDevelopmentDialogue token={user.token} />}
              />
              <Route
                path="/my-employees/mentor/feedback/:employeeId/3"
                element={<CreateObservationRss token={user.token} />}
              />
              <Route
                path="/my-employees/mentor/development-dialogues"
                element={<DevelopmentDialogues token={user.token} />}
              />
              <Route
                path="/my-employees/mentor/review"
                element={<MentorReview token={user.token} />}
              />
              <Route
                path="/my-employees/new"
                element={<NewMyEmployee token={user.token} />}
              />
              <Route
                path="/my-employees"
                element={<MyEmployees token={user.token} />}
              />
              <Route
                path="/my-employees/:employeeId"
                element={<Coaching token={user.token} />}
              />
              <Route
                path="/my-employees/:employeeId/:mentorType"
                element={<Coaching token={user.token} />}
              />
              <Route
                path="/my-employees/:employeeId/:mentorType/:mentorId/view"
                element={<EditDailyCoaching token={user.token} />}
              />
              <Route
                path="/report-help"
                element={<ReportHelp token={user.token} />}
              />
              <Route
                path="/reset-password"
                element={<ResetPassword token={user.token} />}
              />
              <Route
                path="/teach/daily-huddles"
                element={<DailyHuddles token={user.token} />}
              />
              <Route
                path="/teach/integrated-rounds"
                element={<IntegratedRounds token={user.token} />}
              />
              <Route
                path="/teach/integrated-rounds/needs_follow_up"
                element={<IntegratedRounds token={user.token} />}
              />
              <Route
                path="/teach/integrated-rounds/new"
                element={<NewIntegratedRound token={user.token} />}
              />
              <Route
                path="/teach/integrated-rounds/:roundType/new"
                element={<NewIntegratedRoundForm token={user.token} />}
              />
              <Route
                path="/teach/integrated-rounds/:id"
                element={<IntegratedRound token={user.token} />}
              />
              <Route
                path="/teach/staff-meetings"
                element={<StaffMeetings token={user.token} />}
              />
              <Route
                path="/technical-help"
                element={<TechnicalHelp token={user.token} />}
              />
              <Route path="/login" element={<Login />} />
              <Route path="/create-account" element={<CreateAccount />} />
              <Route path="/preferences" element={<Preferences />} />
              <Route path="/help" element={<Help />} />

              <Route path="/default" render={() => <Navigate to="/" />} />
              <Route element={<NoMatch />} />
            </Routes>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
