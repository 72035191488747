import React from "react";
import {
  Button,
  Card,
  Col,
  Input,
  notification,
  Row,
  Skeleton,
  Space,
  Table,
} from "antd";
import hospitalsStyles from "./Hospitals.module.css";
import { useEffect, useState } from "react";
import { useUserStore } from "../../../context/UserStore";
import { useNavigate } from "react-router-dom";
import {
  SearchOutlined,
  EditOutlined,
  CheckOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";

export default function Hospitals() {
  const navigate = useNavigate();
  const [hospitals, setHospitals] = React.useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const user = useUserStore((state) => state.user);

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "attributes.name",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <div style={{ padding: 8 }}>
            <Input
              autoFocus
              placeholder="Search"
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
              value={selectedKeys}
              style={{ marginBottom: 8, display: "block" }}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropDown: false });
              }}
            />
            <Space>
              <Button
                onClick={() => {
                  confirm();
                }}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 rounded"
                icon={<SearchOutlined />}
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button
                onClick={() => {
                  clearFilters();
                }}
                className="bg-orange-500 hover:bg-orange-700 font-bold rounded"
              >
                Reset
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) =>
        record.attributes.name.toLowerCase().includes(value.toLowerCase()),
      render: (id, record) => {
        return `${record.attributes.name}`;
      },
    },
    {
      title: "City",
      dataIndex: "attributes.city",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <div style={{ padding: 8 }}>
            <Input
              autoFocus
              placeholder="Search"
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
              value={selectedKeys}
              style={{ marginBottom: 8, display: "block" }}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropDown: false });
              }}
            />
            <Space>
              <Button
                onClick={() => {
                  confirm();
                }}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 rounded"
                icon={<SearchOutlined />}
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button
                onClick={() => {
                  clearFilters();
                }}
                className="bg-orange-500 hover:bg-orange-700 font-bold rounded"
              >
                Reset
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) =>
        record.attributes.city.toLowerCase().includes(value.toLowerCase()),
      render: (id, record) => {
        return `${record.attributes.city}`;
      },
    },
    {
      title: "State",
      dataIndex: "attributes.state",
      render: (id, record) => {
        return `${record.attributes.state}`;
      },
    },
    {
      title: "Zip Code",
      dataIndex: "attributes.zip",
      render: (id, record) => {
        return `${record.attributes.zip}`;
      },
    },
    {
      title: "Employee Count",
      dataIndex: "attributes.employee_count",
      render: (id, record) => {
        return `${record.attributes.employee_count}`;
      },
    },
    {
      title: "Last Active",
      dataIndex: "attributes.updated_at",
      render: (id, record) => {
        return `${dayjs(record.attributes.updated_at).format("MM/DD/YYYY")}`;
      },
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "id",
      render: (id, record) => (
        <Space size="small">
          <Button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 rounded"
            onClick={() => navigate(`/admin/hospitals/${record.id}`)}
          >
            View
          </Button>
          <Button
            className="bg-orange-500 hover:bg-orange-700 font-bold rounded"
            onClick={() => navigate(`/admin/hospitals/${record.id}/edit`)}
          >
            <EditOutlined />
          </Button>
          <Button
            className="bg-red-500 hover:bg-red-700 font-bold rounded"
            onClick={() => disableHospital(record.id)}
          >
            <DeleteOutlined />
          </Button>
        </Space>
      ),
    },
  ];

  const disableHospital = async (id) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/admin/hospitals/${id}/disable`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        type: "json",
      },
    )
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error(response.statusText);
        }
      })
      .then((response) => {
        notification.success({
          message: response.message,
          description: response.message,
        });
      })
      .catch((error) => {
        notification.error({
          message: error.errors,
        });
        console.log(error);
      });
  };

  const loadHospitals = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/admin/hospitals/list`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        type: "json",
      },
    )
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error(response.statusText);
        }
      })
      .then((response) => {
        const newData = response.hospitals.data.map((item) => {
          return {
            ...item,
            key: item.id,
          };
        });
        setHospitals(newData);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    loadHospitals();
  }, []);

  return (
    <>
      <div
        className={`py-5 grid grid-cols-1 mb-5 ${hospitalsStyles.headerBar}`}
      >
        <h1 className="text-4xl text-center text-white">Hospitals</h1>
      </div>
      <Row>
        <Col span={24}>
          <Card
            bordered={false}
            bodyStyle={{ padding: 0 }}
            extra={
              <Space>
                <Button
                  icon={<PlusOutlined />}
                  onClick={() => navigate("/admin/hospitals/new")}
                >
                  Add Hospital
                </Button>
              </Space>
            }
          ></Card>
        </Col>
      </Row>
      <Skeleton loading={isLoading} active>
        <Table
          dataSource={hospitals}
          columns={columns}
          loading={isLoading}
          rowKey="id"
        />
      </Skeleton>
    </>
  );
}
