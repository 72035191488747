import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Button, Col, Form, Input, notification, Row, Select } from 'antd';
import { Link } from 'react-router-dom';
import { useUserStore } from '../../../../context/UserStore';
import newSharedDecisionStyles from './NewSharedDecision.module.css';

export default function NewSharedDecision() {
    const navigate = useNavigate();
    const { TextArea } = Input;
    const [form] = Form.useForm();
    const [employee, setEmployee] = useState({});
    const [employees, setEmployees] = useState([]);
    const [formData, setFormData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingUnits, setIsLoadingUnits] = useState(true);
    const [loadingEmployees, setLoadingEmployees] = useState(true);
    const [unitOptions, setUnitOptions] = React.useState([]);
    const me = useUserStore((state) => state.me);
    const user = useUserStore((state) => state.user);

    const createProblem = async (values) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/users/problems/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                "Authorization": `Bearer ${user.token}`,
            },
            body: JSON.stringify({
                problem: values
            }),
            type: 'json',
        }).then(response => {
            if (!response.ok) {
                throw new Error(response.statusText);
            }
            return response.json();
        }).then(response => {
            notification['success']({
                message: 'Success',
                description: `Successfully created problem.`,
            });
            navigate('/empower/shared-decisions');
        }).catch(error => {
            notification.error({
                message: 'Error',
                description: `Failed to create problem. ${error}`,
            })
        })
    };

    const getEmployees = async () => {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/v1/users/employees`, {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
            },
            type: "json"
        }).then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        }).then(response => {
            setEmployees(response.employees.data.map(employee => {
                return {
                    key: employee.id,
                    value: employee.id,
                    label: employee.attributes.full_name,
                }
            }));
            setLoadingEmployees(false);
        }).catch(error => {
            console.log(error)
        });
    };

    const loadUnits = async () => {
        setIsLoadingUnits(true);
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/v1/users/units`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                "Authorization": `Bearer ${user.token}`,
            },
            type: 'json',
        }).then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                throw new Error(response.statusText);
            }
        })
            .then(response => {
                const newData = response.units.data.map((item) => {
                    return {
                        label: item.attributes.name,
                        value: item.attributes.id,
                        key: item.id,
                    }
                })
                setUnitOptions(newData);
                setIsLoadingUnits(false);
            })
            .catch(error => {
                console.log(error);
            })
    };

    const outcomeOptions = [
        { label: 'Yes', value: 1 },
        { label: 'No', value: 0 },
    ]

    const onFieldsChange = (changedFields, allFields) => {
        changedFields.map((field) => {
            setFormData({ ...formData, [field.name[0]]: field.value })
        })
    };

    const onFinish = (values) => {
        createProblem(formData);
    };

    const onFinishFailed = (errorInfo) => {
        let error_message = errorInfo.errorFields.map((item) => {
            return item.errors[0];
        })

        notification['error']({
            message: 'Failed to create user',
            description: `${error_message}. `,
        });
    };

    useEffect(() => {
        getEmployees();
        loadUnits();
        setIsLoading(false);
    }, []);

    return (
        <>
            <div className={`py-5 mb-5 flex flex-col items-center justify-center ${newSharedDecisionStyles.headerBar}`}>
                <h1 className="text-4xl text-center text-white">New Shared Decision Charter</h1>
            </div>

            <Row>
                <Col span={20}>
                    <Form
                        layout='vertical'
                        onFieldsChange={onFieldsChange}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        <Form.Item label='Units'>
                            <Form.Item
                                name='unit_id'
                                rules={[{ required: true, message: 'Please choose a Hospital' }]}
                            >
                                <Select
                                    style={{ width: '100%' }}
                                    loading={isLoadingUnits}
                                    placeholder="Select Unit"
                                    options={unitOptions}
                                />
                            </Form.Item>
                        </Form.Item>
                        <Form.Item label='Project Name'>
                            <Form.Item name='problem' rules={[{ required: true, message: 'Please input a Problem' }]}>
                                <Input />
                            </Form.Item>
                        </Form.Item>
                        <Form.Item label='Project Description'>
                            <Form.Item name='description' rules={[{ required: true, message: 'Please input a Description' }]}>
                                <TextArea rows={4} />
                            </Form.Item>
                        </Form.Item>
                        <Form.Item label='Staff'>
                            <Form.Item name='employees' rules={[{ required: true, message: 'Please input a Staff Member' }]}>
                                <Select
                                    mode="multiple"
                                    options={employees}
                                    placeholder="Select an employee"
                                />
                            </Form.Item>
                        </Form.Item>
                        <Form.Item label='Additional Team Members'>
                            <Form.Item name='team' rules={[{ required: false, message: 'Please input a Team' }]}>
                                <Input />
                            </Form.Item>
                        </Form.Item>
                        <Form.Item label='Status'>
                            <Form.Item name='status' rules={[{ required: true, message: 'Please input a Status' }]}>
                                <TextArea rows={4} />
                            </Form.Item>
                        </Form.Item>
                        <Form.Item label='Complete?'>
                            <Form.Item name='complete' rules={[{ required: true, message: 'Please input a Complete' }]}>
                                <Select
                                    style={{ width: '100%' }}
                                    loading={isLoadingUnits}
                                    placeholder="Complete?"
                                    options={outcomeOptions}
                                />
                            </Form.Item>
                        </Form.Item>
                        <Form.Item>
                            <Form.Item>
                                <Button
                                    className='bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 rounded'
                                    htmlType="submit"
                                >
                                    Submit
                                </Button>
                            </Form.Item>
                        </Form.Item>

                    </Form>
                </Col>
            </Row>
        </>
    )
}
