import React, { useEffect, useState } from 'react';
import newIntegratedRoundStyles from './NewIntegratedRound.module.css'
import { useNavigate } from 'react-router-dom';
import { Col, Row, Select, Skeleton, Space } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { useUserStore } from '../../../context/UserStore';

export default function NewIntegratedRound() {
    const navigate = useNavigate();
    const [loadingRoundTypes, setLoadingRoundTypes] = useState(true);
    const [roundTypes, setRoundTypes] = useState([]);
    const [roundType, setRoundType] = useState(null);
    const user = useUserStore((state) => state.user);

    const getRoundTypes = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/round_types`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                }
            });
            const data = await response.json();
            setRoundTypes(data.roundTypes.data.map(
                (roundType) => ({
                    value: roundType.attributes.id,
                    label: roundType.attributes.name
                })
            ));
            setLoadingRoundTypes(false);

        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getRoundTypes();
    }, []);

    return (
        <>
            <div className={`py-5 mb-5 flex flex-col items-center justify-center ${newIntegratedRoundStyles.headerBar}`}>
                <h1 className="text-4xl text-center text-white">New Integrated Round</h1>
            </div>

            <Content>
                <Row>
                    <Col span={24}>
                        <Skeleton loading={loadingRoundTypes} active>
                            <Space direction="vertical" size="large" className='grid grid-cols-1 mb-5'>
                                <Select
                                    options={roundTypes}
                                    placeholder={<span style={{ color: 'black' }}>Select a Round Type</span>}
                                    onChange={(value) => {
                                        setRoundType(value);
                                        navigate(`/teach/integrated-rounds/${value}/new`);
                                    }}
                                />
                            </Space>
                        </Skeleton>
                    </Col>
                </Row>
            </Content>
        </>
    )
}
