import React from "react";
import newQuestionStyles from "./NewQuestion.module.css";
import {
  Button,
  Col,
  Form,
  Input,
  notification,
  Row,
  Select,
  Skeleton,
} from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useUserStore } from "../../../../context/UserStore";

export default function NewQuestion() {
  const navigate = useNavigate();
  const { TextArea } = Input;
  const [initialValues, setInitialValues] = React.useState({});
  const [hospitalOptions, setHospitalOptions] = useState([]);
  const [interactionTypes, setInteractionTypes] = useState([]);
  const [perspectiveTypes, setPerspectiveTypes] = React.useState([]);
  const [questionTypes, setQuestionTypes] = React.useState([]);
  const [roundTypes, setRoundTypes] = React.useState([]);
  const [isLoadingQuestionParams, setIsLoadingQuestionParams] = useState(true);
  const me = useUserStore((state) => state.me);
  const user = useUserStore((state) => state.user);
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({});

  const createQuestion = async (values) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/admin/questions`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({ question: values }),
        type: "json",
      },
    )
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((response) => {
        notification.success({
          message: response.message,
          description: response.message,
        });
        navigate(`/admin/questions`);
      })
      .catch((error) => {
        notification.error({
          message: error.errors,
        });
      });
  };

  const loadQuestionParams = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/admin/questions/get_question_params`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        type: "json",
      },
    )
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error(response.statusText);
        }
      })
      .then((response) => {
        if (me.attributes.role === "Administrator") {
          setHospitalOptions(
            response.hospitals.filter(
              (item) => item.value === me.attributes.hospital_id,
            ),
          );
        } else {
          setHospitalOptions(response.hospitals);
        }

        setInteractionTypes(response.interaction_types);
        setRoundTypes(response.round_types);
        setQuestionTypes(response.question_types);
        setPerspectiveTypes(response.perspective_types);
        setIsLoadingQuestionParams(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onFieldsChange = (changedFields, allFields) => {
    changedFields.map((field) => {
      setFormData({ ...formData, [field.name[0]]: field.value });
    });
  };

  const onFinish = (values) => {
    createQuestion(formData);
  };

  const onFinishFailed = (errorInfo) => {
    let error_message = errorInfo.errorFields.map((item) => {
      return item.errors[0];
    });

    notification["error"]({
      message: "Failed to create question",
      description: `${error_message}. `,
    });
  };

  useEffect(() => {
    loadQuestionParams();
  }, []);

  return (
    <>
      <div
        className={`py-5 grid grid-cols-1 mb-5 ${newQuestionStyles.headerBar}`}
      >
        <h1 className="text-4xl text-center text-white">New Question</h1>
      </div>
      <Row>
        <Col span={20}>
          <Form
            form={form}
            layout="vertical"
            initialValues={initialValues}
            onFieldsChange={onFieldsChange}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Row justify="space-between">
              <Col md={10} xs={24}>
                <Form.Item label="Question Text">
                  <Form.Item
                    name="text"
                    rules={[
                      {
                        required: true,
                        message: "Please input question text!",
                      },
                    ]}
                  >
                    <Input placeholder="Question Text" />
                  </Form.Item>
                </Form.Item>
              </Col>
              <Col md={10} xs={24}>
                <Form.Item label="Before Help Content">
                  <Form.Item
                    name="help_before"
                    rules={[
                      {
                        required: false,
                        message: "Input text to go before the question.",
                      },
                    ]}
                  >
                    <TextArea placeholder="Before Help Content" />
                  </Form.Item>
                </Form.Item>
              </Col>
            </Row>
            <Row justify="space-between">
              <Col md={10} xs={24}>
                <Form.Item label="Round Type">
                  <Form.Item
                    name="round_type_id"
                    rules={[{ required: true, message: "Select a Round Type" }]}
                  >
                    <Select
                      loading={isLoadingQuestionParams}
                      options={roundTypes}
                    ></Select>
                  </Form.Item>
                </Form.Item>
              </Col>
              <Col md={10} xs={24}>
                <Form.Item label="After Help Content">
                  <Form.Item
                    name="help_after"
                    rules={[
                      {
                        required: false,
                        message: "Input text to go after the question.",
                      },
                    ]}
                  >
                    <TextArea placeholder="After Help Content" />
                  </Form.Item>
                </Form.Item>
              </Col>
            </Row>
            <Row justify="space-between">
              <Col md={10} xs={24}>
                <Row justify="space-between">
                  <Col md={10} xs={24}>
                    <Form.Item label="Page">
                      <Form.Item
                        name="page_number"
                        rules={[
                          { required: true, message: "Add a Page Number" },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Form.Item>
                  </Col>
                  <Col md={10} xs={24}>
                    <Form.Item label="Order">
                      <Form.Item
                        name="order"
                        rules={[
                          { required: true, message: "Input a Page Number" },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col md={10} xs={24}>
                <Form.Item label="Perspective">
                  <Form.Item
                    name="perspective"
                    rules={[{ required: false, message: "Choose Perspective" }]}
                  >
                    <Select
                      loading={isLoadingQuestionParams}
                      options={perspectiveTypes}
                    />
                  </Form.Item>
                </Form.Item>
              </Col>
            </Row>
            <Row justify="space-between">
              <Col md={10} xs={24}>
                <Form.Item label="Question Type">
                  <Form.Item
                    name="question_type"
                    rules={[
                      { required: true, message: "Select a Question Type" },
                    ]}
                  >
                    <Select
                      loading={isLoadingQuestionParams}
                      options={questionTypes}
                    ></Select>
                  </Form.Item>
                </Form.Item>
              </Col>
              <Col md={10} xs={24}>
                <Form.Item label="Hospital">
                  <Form.Item
                    name="hospital_id"
                    rules={[{ required: true, message: "Select a Hospital" }]}
                  >
                    <Select
                      loading={isLoadingQuestionParams}
                      options={hospitalOptions}
                    ></Select>
                  </Form.Item>
                </Form.Item>
              </Col>
            </Row>
            <Row justify="space-between">
              <Col md={10} xs={24}>
                <Form.Item label="Interaction Type">
                  <Form.Item
                    name="interaction_type_id"
                    rules={[{ required: true, message: "Select a Hospital" }]}
                  >
                    <Select
                      loading={isLoadingQuestionParams}
                      options={interactionTypes}
                    ></Select>
                  </Form.Item>
                </Form.Item>
              </Col>
              <Col md={10} xs={24}>
                <Form.Item label="Required for round to be complete?">
                  <Form.Item
                    name="required"
                    rules={[
                      {
                        required: false,
                        message: "Input text to go after the question.",
                      },
                    ]}
                  >
                    <Select
                      loading={isLoadingQuestionParams}
                      options={[
                        { value: true, label: "Yes" },
                        { value: false, label: "No" },
                      ]}
                    ></Select>
                  </Form.Item>
                </Form.Item>
              </Col>
            </Row>
            <Row justify="space-between">
              <Col md={10} xs={24}>
                <Row justify="space-between">
                  <Col md={10} xs={24}>
                    <Form.Item label="Disabled">
                      <Form.Item
                        name="disabled"
                        rules={[
                          {
                            required: false,
                            message: "Is this question disabled?",
                          },
                        ]}
                      >
                        <Select
                          options={[
                            { value: true, label: "Yes" },
                            { value: false, label: "No" },
                          ]}
                        ></Select>
                      </Form.Item>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row justify="space-between">
              <Col md={10} xs={24}>
                <Form.Item>
                  <Form.Item>
                    <Button
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 rounded"
                      htmlType="submit"
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  );
}
