import React from 'react'
import { Button, Col, Descriptions, Form, Input, InputNumber, notification, Row, Select, Skeleton, Space, Tag } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import editEmployeeStyles from './EditEmployee.module.css';
import { useEffect, useState } from 'react';
import { useUserStore } from '../../../../context/UserStore';
import { useNavigate, useParams } from 'react-router-dom';
import { SearchOutlined, EditOutlined, CheckOutlined, DeleteOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

export default function EditEmployee() {
    const navigate = useNavigate();
    const { TextArea } = Input;
    const { id } = useParams();
    const [employee, setEmployee] = useState(null);
    const [hospitals, setHospitals] = useState([]);
    const [initialValues, setInitialValues] = React.useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingHospitals, setIsLoadingHospitals] = useState(true);
    const [isLoadingManagers, setIsLoadingManagers] = useState(true);
    const [isLoadingUnits, setIsLoadingUnits] = useState(true);
    const [managers, setManagers] = useState([]);
    const [managerOptions, setManagerOptions] = React.useState([]);
    const [unitOptions, setUnitOptions] = React.useState([]);
    const [employeeUnits, setEmployeeUnits] = React.useState([]);
    const user = useUserStore((state) => state.user);
    const me = useUserStore((state) => state.me);
    const [form] = Form.useForm();
    const [formData, setFormData] = useState({});

    const disableEmployee = async (id) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/admin/employees/${id}/disable`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                "Authorization": `Bearer ${user.token}`,
            },
            type: 'json',
        }).then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                throw new Error(response.statusText);
            }
        }).then(response => {
            notification.success({
                message: response.message,
                description: response.message,
            });
        }).catch(error => {
            notification.error({
                message: error.errors,
            });
            console.log(error);
        })
    };

    const loadEmployee = async () => {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/v1/admin/employees/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                "Authorization": `Bearer ${user.token}`,
            },
            type: 'json',
        }).then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                throw new Error(response.statusText);
            }
        })
            .then(response => {

                // Get the list of employee unit options from response.employee.data
                // This is a hash of the units the employee is assigned to
                const newData = response.employee.data.attributes.employee_unit_options.map((item) => {
                    return {
                        label: item.label,
                        value: item.value,
                        key: item.key,
                    }
                })

                if (response.employee.data.attributes.needs_attention === null) {
                    response.employee.data.attributes.needs_attention = 0
                } else {
                    let future = dayjs(response.employee.data.attributes.needs_attention).format('YYYY-MM-DD HH:mm:ss')
                    let today = dayjs().format('YYYY-MM-DD HH:mm:ss')
                    let difference = dayjs(future).diff(dayjs(today), 'day')
                    response.employee.data.attributes.needs_attention = difference
                }

                setEmployeeUnits(newData);
                loadUnits(response.employee.data.attributes.hospital_id);
                setEmployee(response.employee.data);


                // add values from newData to initialValues
                const unitIds = newData.map(item => item.value);
                const employeeObject = Object.assign(response.employee.data.attributes, { unit_ids: unitIds })
                setInitialValues(employeeObject);
                setIsLoading(false);
            })
            .catch(error => {
                console.log(error);
            })
    };

    const loadHospitals = async () => {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/v1/admin/hospitals`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                "Authorization": `Bearer ${user.token}`,
            },
            type: 'json',
        }).then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                throw new Error(response.statusText);
            }
        })
            .then(response => {
                const newData = response.hospitals.data.map((item) => {
                    return {
                        label: item.attributes.name,
                        value: item.attributes.id,
                        key: item.id,
                    }
                })
                setHospitals(newData);
                setIsLoadingHospitals(false);
            })
            .catch(error => {
                console.log(error);
            })
    };

    const loadUnits = async (hospital_id) => {
        setIsLoadingUnits(true);
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/v1/admin/hospitals/${hospital_id}/unit_manager_options`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                "Authorization": `Bearer ${user.token}`,
            },
            type: 'json',
        }).then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                throw new Error(response.statusText);
            }
        })
            .then(response => {
                const newData = response.unit_manager_options.map((item) => {
                    return {
                        label: item.label,
                        value: item.value,
                        key: item.key,
                    }
                })
                setUnitOptions(newData);
                setIsLoadingUnits(false);
            })
            .catch(error => {
                console.log(error);
            })
    };

    const updateEmployee = async (values) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/admin/employees/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            },
            body: JSON.stringify({ employee: values }),
            type: 'json',
        }).then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        }).then(response => {
            notification.success({
                message: response.message,
                description: response.message,
            });
            if (me.attributes.role == 'Administrator' || me.attributes.role == 'Super Administrator') {
                navigate(`/admin/employees`);
            } else {
                navigate(`/my-employees`);
            }

        }).catch(error => {
            notification.error({
                message: error.errors,
            });
        });
    };

    const onFieldsChange = (changedFields, allFields) => {
        changedFields.map((field) => {
            setFormData({ ...formData, [field.name[0]]: field.value })
        })
    };

    const onFinish = (values) => {
        updateEmployee(formData);
    };

    const onFinishFailed = (errorInfo) => {
        let error_message = errorInfo.errorFields.map((item) => {
            return item.errors[0];
        })

        notification['error']({
            message: 'Failed to edit Employee',
            description: `${error_message}. `,
        });
    };

    useEffect(() => {
        loadEmployee();
        loadHospitals();
    }, []);
    return (
        <>
            <div className={`py-5 grid grid-cols-1 mb-5 ${editEmployeeStyles.headerBar}`}>
                <h1 className="text-4xl text-center text-white">Edit Employee</h1>
            </div>
            <Skeleton loading={isLoading} active>
                {(isLoading == false) && (
                    <>
                        {(employee != null) && (
                            <PageHeader
                                onBack={() => navigate(-1)}
                                ghost={false}
                                title={`${employee.attributes.full_name}`}
                                extra={[
                                    <Space size='small'>
                                        <Button
                                            key='back'
                                            className='bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 rounded'
                                            onClick={() => navigate(`/admin/employees/${id}`)}>
                                            View
                                        </Button>
                                        <Button
                                            key='edit'
                                            className='bg-orange-500 hover:bg-orange-700 font-bold rounded'
                                            onClick={() => navigate(`/admin/employees/${id}/edit`)}>
                                            <EditOutlined />
                                        </Button>
                                        <Button
                                            key='delete'
                                            type='danger'
                                            className='bg-red-500 hover:bg-red-700 font-bold rounded'
                                            onClick={() => disableEmployee(id)}>
                                            <DeleteOutlined />
                                        </Button>
                                    </Space>
                                ]}></PageHeader>
                        )}
                        <Row>
                            <Col span={20}>
                                <Form
                                    form={form}
                                    layout='vertical'
                                    initialValues={initialValues}
                                    onFieldsChange={onFieldsChange}
                                    onFinish={onFinish}
                                    onFinishFailed={onFinishFailed}
                                >
                                    <Row justify='space-between'>
                                        <Col md={10} xs={20}>
                                            <Form.Item label='Hospital'>
                                                <Form.Item
                                                    name='hospital_id'
                                                    rules={[{ required: false, message: 'Please choose a Hospital' }]}
                                                >
                                                    <Select
                                                        disabled={me.attributes.role == 'Administrator'}
                                                        loading={isLoadingHospitals}
                                                        options={hospitals}
                                                        onChange={(value) => {
                                                            loadUnits(value)
                                                        }}
                                                    ></Select>
                                                </Form.Item>
                                            </Form.Item>
                                        </Col>
                                        <Col md={10} xs={20}>
                                            <Form.Item label='Units'>
                                                <Form.Item
                                                    name='unit_ids'
                                                    rules={[{ required: false, message: 'Please choose a Hospital' }]}
                                                >
                                                    <Select
                                                        mode="multiple"
                                                        style={{ width: '100%' }}
                                                        loading={isLoadingUnits}
                                                        placeholder="Select Unit(s)"
                                                        options={unitOptions}
                                                    />
                                                </Form.Item>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row justify='space-between'>
                                        <Col md={10} xs={20}>
                                            <Form.Item label='First Name'>
                                                <Form.Item name='first_name' rules={[{ required: true, message: 'Input a First Name' }]}>
                                                    <Input />
                                                </Form.Item>
                                            </Form.Item>

                                        </Col>
                                        <Col md={10} xs={20}>
                                            <Form.Item label='Last Name'>
                                                <Form.Item name='last_name' rules={[{ required: true, message: 'Select a Last Name' }]}>
                                                    <Input />
                                                </Form.Item>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row justify='space-between'>
                                        <Col md={10} xs={20}>
                                            <Form.Item label='Job Title'>
                                                <Form.Item name='job_title' rules={[{ required: true, message: 'Input a First Name' }]}>
                                                    <Input />
                                                </Form.Item>
                                            </Form.Item>

                                        </Col>
                                        <Col md={10} xs={20}>
                                            <Form.Item label='Employee ID'>
                                                <Form.Item name='employee_id' rules={[{ required: false, message: 'Select a Last Name' }]}>
                                                    <Input />
                                                </Form.Item>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row justify='space-between'>
                                        <Col md={10} xs={20}>
                                            <Form.Item label='Email'>
                                                <Form.Item name='email' rules={[{ required: false, message: 'Input an Email Address' }]}>
                                                    <Input />
                                                </Form.Item>
                                            </Form.Item>

                                        </Col>
                                        <Col md={10} xs={20}>
                                            <Form.Item label='Needs Attention in X days'>
                                                <Form.Item name='needs_attention' rules={[{ required: false, message: 'Needs attention in X Days' }]}>
                                                    <InputNumber />
                                                </Form.Item>
                                            </Form.Item>

                                        </Col>
                                    </Row>
                                    <Row justify='space-between'>
                                        <Col md={10} xs={20}>
                                        </Col>
                                        <Col md={10} xs={20}>
                                            <Form.Item label='Notes'>
                                                <Form.Item name='notes' rules={[{ required: false, message: 'Input some Notes.' }]}>
                                                    <TextArea />
                                                </Form.Item>
                                            </Form.Item>

                                        </Col>
                                    </Row>
                                    <Row justify='space-between'>
                                        <Col md={10} xs={24}>
                                            <Form.Item>
                                                <Form.Item>
                                                    <Button
                                                        className='bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 rounded'
                                                        htmlType="submit"
                                                    >
                                                        Submit
                                                    </Button>
                                                </Form.Item>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    </>
                )}
            </Skeleton >
        </>
    )
}
