import React from 'react';
import staffMeetingsStyles from './StaffMeetings.module.css';

export default function StaffMeetings() {
    return (
        <>
            <div
                className={`py-8 mb-8 flex flex-col items-center justify-center space-y-6 ${staffMeetingsStyles.headerBar}`}
                style={{ background: 'linear-gradient(135deg, #e87c10 0%, #f59222 100%)', color: '#fff' }}>
                <img className="w-40 rounded-full shadow-lg" src='/assets/images/meeting-icon.png' alt="Meeting Icon" />
                <h1 className='text-center text-4xl font-bold tracking-wider'>Inclusive Staff Meetings</h1>
            </div>

            <div className="max-w-6xl mx-auto p-10 bg-white shadow-2xl rounded-3xl" style={{ transition: 'box-shadow 0.3s ease', boxShadow: '0px 15px 30px rgba(0, 0, 0, 0.15)' }}>
                <div className='mb-8'>
                    <h4 className='text-xl font-semibold mb-4'>Purpose</h4>
                    <p className='text-base text-gray-700 leading-relaxed'>Provide meaningful opportunities for staff to
                        learn, provide input, express ideas, and dialogue with one another.</p>
                </div>

                <p className="mt-4 text-base text-gray-700 leading-relaxed">Well-planned and facilitated staff meetings
                    are one of the most effective forums for encouraging dialogue and open exchange with employees.</p>
                <hr className='my-6 border-gray-300' />
                <p className='text-base text-gray-700 leading-relaxed mb-6'>Following are suggestions for more effective
                    staff meetings:</p>

                <ol className='list-decimal ml-5 text-gray-800 text-base leading-relaxed'>
                    <li className='mb-6'>
                        <p className='font-semibold'>Set specific and realistic goals and objectives for each
                            meeting.</p>
                        <ul className="list-disc list-inside ml-6 mt-2 text-gray-700">
                            <li>Prepare and distribute a written agenda several days before the meeting so that staff
                                can come prepared to discuss relevant issues.
                            </li>
                            <li>Allocate time for each agenda item as appropriate.</li>
                        </ul>
                    </li>

                    <li className='mb-6'>
                        <p className='font-semibold'>Judiciously manage time to be respectful of staff.</p>
                        <ul className="list-disc list-inside ml-6 mt-2 text-gray-700">
                            <li>Start on time.</li>
                            <li>Stay on time.</li>
                            <li>End on time.</li>
                        </ul>
                    </li>

                    <li className='mb-6'>
                        <p className='font-semibold'>Start strong.</p>
                        <ul className="list-disc list-inside ml-6 mt-2 text-gray-700">
                            <li>Outline specific goals for the meeting.</li>
                            <li>Briefly review and gain agreement on the agenda.</li>
                        </ul>
                    </li>

                    <li className='mb-6'>
                        <p className='font-semibold'>Allocate significant time for staff participation and input.</p>
                        <ul className="list-disc list-inside ml-6 mt-2 text-gray-700">
                            <li>Two-way communication makes staff meetings dynamic and encourages future attendance.
                            </li>
                            <li>Active participation increases employees' understanding of issues and their buy-in to
                                changes in practice.
                            </li>
                        </ul>
                    </li>

                    <li className='mb-6'>
                        <p className='font-semibold'>End strong.</p>
                        <ul className="list-disc list-inside ml-6 mt-2 text-gray-700">
                            <li>Summarize key points and decisions discussed during the meeting.</li>
                            <li>Clarify questions/issues surrounding those points.</li>
                            <li>Assign/clarify responsibility for follow-up in the Action Register.</li>
                            <li>Distribute minutes of key points discussed during the staff meeting for employees who
                                could not attend.
                            </li>
                        </ul>
                    </li>
                </ol>

                <hr className='my-6 border-gray-300' />
                <h3 className="text-xl text-center font-bold mb-6">Meeting Agenda</h3>
                <div className="table-responsive flex justify-center">
                    <table className="table-fixed bg-white rounded-lg overflow-hidden shadow-md mb-6 max-w-4xl">
                        <tbody>
                        <tr>
                            <th className="px-4 py-2 text-left bg-gray-100 text-gray-700 font-semibold">Group/Department</th>
                            <td className="px-4 py-2 text-gray-800">Third Floor Nursing Unit</td>
                        </tr>
                        <tr>
                            <th className="px-4 py-2 text-left bg-gray-100 text-gray-700 font-semibold">Dates/Times</th>
                            <td className="px-4 py-2 text-gray-800">July 10, 2015 6:30-7:30am & 5:30-6:30pm</td>
                        </tr>
                        <tr>
                            <th className="px-4 py-2 text-left bg-gray-100 text-gray-700 font-semibold">Location</th>
                            <td className="px-4 py-2 text-gray-800">Unit Classroom</td>
                        </tr>
                        <tr>
                            <th className="px-4 py-2 text-left bg-gray-100 text-gray-700 font-semibold">Meeting Goals</th>
                            <td className="px-4 py-2 text-gray-800">
                                <ul className="list-disc list-inside">
                                    <li>Review performance dashboard to celebrate accomplishment and discuss strategies for continued improvement</li>
                                    <li>Discuss open issues related to new unit opening and prioritize continuing work</li>
                                    <li>Clarify access/security issues at ED entrance</li>
                                    <li>Finalize plan for peer interviews for prospective team members</li>
                                </ul>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div className="table-responsive mt-10 flex justify-center">
                    <table className="table-fixed bg-white rounded-lg overflow-hidden shadow-xl max-w-4xl">
                        <thead>
                        <tr>
                            <th className="px-4 py-2 text-left bg-gray-100 text-gray-700 font-semibold">Time</th>
                            <th className="px-4 py-2 text-left bg-gray-100 text-gray-700 font-semibold">Topic</th>
                            <th className="px-4 py-2 text-left bg-gray-100 text-gray-700 font-semibold">Lead</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td className="px-4 py-2 text-gray-800">6:30 am</td>
                            <td className="px-4 py-2 text-gray-800">Welcome/Introductions</td>
                            <td className="px-4 py-2 text-gray-800">S. Smith</td>
                        </tr>
                        <tr>
                            <td className="px-4 py-2 text-gray-800">6:35 am</td>
                            <td className="px-4 py-2 text-gray-800">
                                <hr className='my-3' />
                                Meeting Goals & Objectives
                                <ul className="list-disc list-inside mt-3">
                                    <li>Review and discuss department dashboard; identify areas for focus</li>
                                    <li>Discuss continuing challenges related to opening; identify action items</li>
                                    <li>Review priorities for patient experience</li>
                                </ul>
                            </td>
                            <td className="px-4 py-2 text-gray-800">S. Smith</td>
                        </tr>
                        <tr>
                            <td className="px-4 py-2 text-gray-800">6:40 am</td>
                            <td className="px-4 py-2 text-gray-800">
                                <hr className='my-3' />
                                Department Dashboard
                                <ul className="list-disc list-inside mt-3">
                                    <li>Quality metrics</li>
                                    <li>Patient experience goals</li>
                                    <li>Staffing</li>
                                </ul>
                            </td>
                            <td className="px-4 py-2 text-gray-800">B. Williams</td>
                        </tr>
                        <tr>
                            <td className="px-4 py-2 text-gray-800">7:00 am</td>
                            <td className="px-4 py-2 text-gray-800">
                                <hr className='my-3' />
                                Group Discussion – New Unit Issues and Challenges<br />
                                <i className='block mt-2 text-sm text-gray-600'>What significant questions and/or challenges have we addressed and solved together related to our opening?</i>
                                <i className='block mt-2 text-sm text-gray-600'>What issues or processes continue to be challenging that we need to resolve?</i>
                                <i className='block mt-2 text-sm text-gray-600'>What are patients and family members saying about our care experience?</i>
                            </td>
                            <td className="px-4 py-2 text-gray-800">P. Foster</td>
                        </tr>
                        <tr>
                            <td className="px-4 py-2 text-gray-800">7:20 am</td>
                            <td className="px-4 py-2 text-gray-800">
                                <hr className='my-3' />
                                Organizational Information and Updates
                                <ul className="list-disc list-inside mt-3">
                                    <li>Access/security at ED entrance</li>
                                    <li>Interviews for new staff members</li>
                                    <li>Clocking in/out procedures</li>
                                </ul>
                                <hr className='my-3' />
                            </td>
                            <td className="px-4 py-2 text-gray-800">S. Smith</td>
                        </tr>
                        <tr>
                            <td className="px-4 py-2 text-gray-800">7:25 am</td>
                            <td className="px-4 py-2 text-gray-800">Summary/Next Steps</td>
                            <td className="px-4 py-2 text-gray-800">S. Smith</td>
                        </tr>
                        <br/>
                        </tbody>
                    </table>
                </div>
                <div className='flex justify-center space-x-6 mt-10'>
                    <a
                        target="_blank"
                        className='bg-blue-600 hover:bg-blue-800 text-center text-white font-bold py-3 px-5 rounded-full shadow-lg transform transition-transform duration-300 hover:scale-110'
                        href="/assets/pdfs/staff-meeting-template-team-meeting-agenda.docx">
                        Download Staff Agenda Template
                    </a>
                    <a
                        target="_blank"
                        className='bg-blue-600 hover:bg-blue-800 text-center text-white font-bold py-3 px-5 rounded-full shadow-lg transform transition-transform duration-300 hover:scale-110'
                        href="/assets/pdfs/staff-meeting-template-action-register.docx">
                        Download Action Register Template
                    </a>
                </div>
            </div>
        </>
    )
}
