export const SET_USER = 'SET_USER';

export const initialUserState = {
    user: {},
};

export const addUser = (user) => ({
    type: SET_USER,
    user,
});

export const userReducer = (state = initialUserState, action) => {

    switch (action.type) {
        case 'SET_USER':
            localStorage.setItem('UserObject', JSON.stringify(action.user));
            return {
                ...state,
                user: action.user,
            };
        default:
            return state;
    }
};

