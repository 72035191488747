import React, { useRef } from "react";
import {
  Button,
  Col,
  Descriptions,
  Divider,
  Form,
  Input,
  notification,
  Row,
  Select,
  Skeleton,
  Space,
} from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import viewMetricStyles from "./viewMetric.module.css";
import { Content } from "antd/lib/layout/layout";
import useToken from "../../../../useToken";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { PrinterOutlined } from "@ant-design/icons";
import { useUserStore } from "../../../../../context/UserStore";

export default function AdminViewMetric() {
  const [loadingMetric, setLoadingMetric] = useState(true);
  const [metric, setMetric] = useState({});
  const { metricId } = useParams();
  const user = useUserStore((state) => state.user);

  const getMetric = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/users/metrics/${metricId}`,
      {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        type: "json",
      },
    )
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((response) => {
        setMetric(response.metric.data);
        setLoadingMetric(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const printView = (e) => {
    window.print();
  };

  useEffect(() => {
    getMetric();
  }, []);

  return (
    <>
      <div
        className={`py-5 grid grid-cols-1 mb-5 ${viewMetricStyles.headerBar}`}
      >
        <h1 className="text-4xl text-center text-white">View Metric</h1>
      </div>
      <Skeleton loading={loadingMetric} active>
        {metric && metric.attributes && (
          <>
            <PageHeader
              ghost={false}
              onBack={() => navigate(-1)}
              title={metric.attributes.name}
              subTitle={metric.attributes.goal_name}
              extra={[
                <Space size="small">
                  <Button
                    key="1"
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 rounded"
                    icon={<PrinterOutlined />}
                    onClick={() => printView(e)}
                  />
                </Space>,
              ]}
            >
              <Descriptions size="small" column={3} bordered>
                <Descriptions.Item label="Unit">
                  {metric.attributes.unit.name}
                </Descriptions.Item>
                <Descriptions.Item label="Goal Year">
                  <a>{metric.attributes.metric_year}</a>
                </Descriptions.Item>
                <Descriptions.Item label="Area">
                  <a>{metric.attributes.area_type}</a>
                </Descriptions.Item>
                <Descriptions.Item label="Measurement Type">
                  <a>{metric.attributes.measurement_type}</a>
                </Descriptions.Item>
                <Descriptions.Item label="Metric Interval">
                  {metric.attributes.measurement_interval}
                </Descriptions.Item>
                <Descriptions.Item label="Creation Date">
                  {metric.attributes.friendly_created_at}
                </Descriptions.Item>
                <Descriptions.Item label="Effective Time">
                  2017-10-10
                </Descriptions.Item>
                <Descriptions.Item label="Description">
                  {metric.attributes.description}
                </Descriptions.Item>
              </Descriptions>
            </PageHeader>
            <AreaChart
              width={1000}
              height={400}
              data={metric.attributes.graph_data}
              margin={{
                top: 10,
                right: 30,
                left: 0,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Area
                type="monotone"
                dataKey="goal_target"
                activeDot={{ stroke: "#888", strokeWidth: 2, r: 2 }}
                dot={{ stroke: "rgba(220,220,220,1)", strokeWidth: 2 }}
                stackId="2"
                strokeWidth="3"
                stroke="rgba(220,220,220,1)"
                fill="rgba(200,200,200,0.2)"
              />
              <Area
                type="monotone"
                dataKey="value"
                activeDot={{
                  stroke: "rgba(220,220,220,1)",
                  strokeWidth: 2,
                  r: 2,
                }}
                dot={{ stroke: "rgba(172,189,55,1)", strokeWidth: 2 }}
                stackId="1"
                strokeWidth="3"
                stroke="rgba(220,220,220,1)"
                fill="rgba(172,189,55,0.2)"
              />
            </AreaChart>
          </>
        )}
      </Skeleton>
    </>
  );
}
