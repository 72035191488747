import React from "react";
import {
  Button,
  Col,
  Form,
  Input,
  notification,
  Row,
  Select,
  Skeleton,
  Space,
} from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import adminEditUserStyles from "./AdminEditUser.module.css";
import { useEffect, useState } from "react";
import { useUserStore } from "../../../../context/UserStore";
import { useNavigate, useParams } from "react-router-dom";
import {
  SearchOutlined,
  EditOutlined,
  CheckOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

export default function AdminEditUser() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [hospitals, setHospitals] = useState([]);
  const [adminUser, setAdminUser] = React.useState([]);
  const [initialValues, setInitialValues] = React.useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingHospitals, setIsLoadingHospitals] = useState(true);
  const [isLoadingRoles, setIsLoadingRoles] = useState(true);
  const [isLoadingUnits, setIsLoadingUnits] = useState(true);
  const [roles, setRoles] = React.useState([]);
  const [unitOptions, setUnitOptions] = React.useState([]);
  const [userUnits, setUserUnits] = React.useState([]);
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({});
  const me = useUserStore((state) => state.me);
  const user = useUserStore((state) => state.user);

  const loadHospitals = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/admin/hospitals`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        type: "json",
      },
    )
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error(response.statusText);
        }
      })
      .then((response) => {
        const newData = response.hospitals.data.map((item) => {
          return {
            label: item.attributes.name,
            value: item.attributes.id,
            key: item.id,
          };
        });
        setHospitals(newData);
        setIsLoadingHospitals(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const loadRoles = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/admin/roles`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        type: "json",
      },
    )
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error(response.statusText);
        }
      })
      .then((response) => {
        const newData = response.roles.data.map((item) => {
          return {
            label: item.attributes.name,
            value: item.attributes.id,
            key: item.id,
          };
        });
        setRoles(newData);
        setIsLoadingHospitals(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const loadUnits = async (hospital_id) => {
    setIsLoadingUnits(true);
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/admin/hospitals/${hospital_id}/units`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        type: "json",
      },
    )
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error(response.statusText);
        }
      })
      .then((response) => {
        const newData = response.units.data.map((item) => {
          return {
            label: item.attributes.name,
            value: item.attributes.id,
            key: item.id,
          };
        });
        setUnitOptions(newData);
        setIsLoadingUnits(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const loadUser = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/admin/users/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        type: "json",
      },
    )
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error(response.statusText);
        }
      })
      .then((response) => {
        // Setting User Units
        const newData = response.user.included
          .filter((item) => item.type === "unit")
          .map((item) => {
            return {
              label: item.attributes.name,
              value: item.attributes.id,
              key: item.id,
            };
          });
        setUserUnits(newData);
        loadUnits(response.user.data.attributes.hospital_id);
        setAdminUser(response.user.data);
        // add values from newData to initialValues
        const unitIds = newData.map((item) => item.value);
        const userObject = Object.assign(response.user.data.attributes, {
          unit_ids: unitIds,
        });
        setInitialValues(userObject);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const disableUser = async (id) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/admin/users/${id}/disable`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        type: "json",
      },
    )
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error(response.statusText);
        }
      })
      .then((response) => {
        notification.success({
          message: response.message,
          description: response.message,
        });
      })
      .catch((error) => {
        notification.error({
          message: error.errors,
        });
        console.log(error);
      });
  };

  const updateUser = async (values) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/admin/users/${id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({ user: values }),
        type: "json",
      },
    )
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((response) => {
        notification.success({
          message: response.message,
          description: response.message,
        });
        navigate(`/admin/users`);
      })
      .catch((error) => {
        notification.error({
          message: error.errors,
        });
      });
  };

  const onFieldsChange = (changedFields, allFields) => {
    changedFields.map((field) => {
      setFormData({ ...formData, [field.name[0]]: field.value });
    });
  };

  const onFinish = (values) => {
    updateUser(formData);
  };

  const onFinishFailed = (errorInfo) => {
    let error_message = errorInfo.errorFields.map((item) => {
      return item.errors[0];
    });

    notification["error"]({
      message: "Failed to edit User",
      description: `${error_message}. `,
    });
  };

  useEffect(() => {
    loadUser();
    loadHospitals();
    loadRoles();
  }, []);

  return (
    <>
      <div
        className={`py-5 grid grid-cols-1 mb-5 ${adminEditUserStyles.headerBar}`}
      >
        <h1 className="text-4xl text-center text-white">Edit User</h1>
      </div>
      <Skeleton loading={isLoading} active>
        {isLoading == false && (
          <>
            <PageHeader
              onBack={() => navigate(-1)}
              ghost={false}
              title={`${adminUser.attributes.full_name}`}
              extra={[
                <Space size="small">
                  <Button
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 rounded"
                    key="back"
                    onClick={() => navigate(`/admin/users/${id}`)}
                  >
                    View
                  </Button>
                  <Button
                    className="bg-orange-500 hover:bg-orange-700 font-bold rounded"
                    key="edit"
                    onClick={() => navigate(`/admin/users/${id}/edit`)}
                  >
                    <EditOutlined />
                  </Button>
                  <Button
                    className="bg-red-500 hover:bg-red-700 font-bold rounded"
                    key="delete"
                    onClick={() => disableUser(id)}
                  >
                    <DeleteOutlined />
                  </Button>
                </Space>,
              ]}
            ></PageHeader>
            <Row>
              <Col span={20}>
                <Form
                  form={form}
                  layout="vertical"
                  initialValues={initialValues}
                  onFieldsChange={onFieldsChange}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                >
                  <Row>
                    <Col md={10} xs={20}>
                      <Form.Item label="Role">
                        <Form.Item
                          name="role_id"
                          rules={[
                            { required: true, message: "Please choose a Role" },
                          ]}
                        >
                          <Select
                            loading={isLoadingRoles}
                            options={roles}
                          ></Select>
                        </Form.Item>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row justify="space-between">
                    <Col md={10} xs={20}>
                      <Form.Item label="Hospital">
                        <Form.Item
                          name="hospital_id"
                          rules={[
                            {
                              required: false,
                              message: "Please choose a Hospital",
                            },
                          ]}
                        >
                          <Select
                            disabled={me.attributes.role == "Administrator"}
                            loading={isLoadingHospitals}
                            options={hospitals}
                            onChange={(value) => {
                              loadUnits(value);
                            }}
                          ></Select>
                        </Form.Item>
                      </Form.Item>
                    </Col>
                    <Col md={10} xs={20}>
                      <Form.Item label="Units">
                        <Form.Item
                          name="unit_ids"
                          rules={[
                            {
                              required: false,
                              message: "Please choose a Hospital",
                            },
                          ]}
                        >
                          <Select
                            mode="multiple"
                            style={{ width: "100%" }}
                            loading={isLoadingUnits}
                            placeholder="Select Unit(s)"
                            options={unitOptions}
                          />
                        </Form.Item>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row justify="space-between">
                    <Col md={10} xs={20}>
                      <Form.Item label="First Name">
                        <Form.Item
                          name="first_name"
                          rules={[
                            { required: true, message: "Input a First Name" },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Form.Item>
                    </Col>
                    <Col md={10} xs={20}>
                      <Form.Item label="Last Name">
                        <Form.Item
                          name="last_name"
                          rules={[
                            { required: true, message: "Select a Last Name" },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row justify="space-between">
                    <Col md={10} xs={20}>
                      <Form.Item label="Job Title">
                        <Form.Item
                          name="job_title"
                          rules={[
                            { required: true, message: "Input a First Name" },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Form.Item>
                    </Col>
                    <Col md={10} xs={20}>
                      <Form.Item label="Employee ID">
                        <Form.Item
                          name="employee_id"
                          rules={[
                            { required: false, message: "Select a Last Name" },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row justify="space-between">
                    <Col md={10} xs={20}>
                      <Form.Item label="Email">
                        <Form.Item
                          name="email"
                          rules={[
                            {
                              required: true,
                              message: "Input an Email Address",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Form.Item>
                    </Col>
                    <Col md={10} xs={20}>
                      <Form.Item label="Password">
                        <Form.Item
                          name="password"
                          rules={[
                            { required: false, message: "Select a Password" },
                          ]}
                        >
                          <Input.Password />
                        </Form.Item>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row justify="space-between">
                    <Col md={10} xs={24}>
                      <Form.Item>
                        <Form.Item>
                          <Button
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 rounded"
                            htmlType="submit"
                          >
                            Submit
                          </Button>
                        </Form.Item>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </>
        )}
      </Skeleton>
    </>
  );
}
