import React from 'react'
import Dashboard from '../Dashboard/Dashboard'

export default function Home() {
    return (
        <>
            <Dashboard />
        </>
    )
}
