import React from "react";
import { useNavigate } from "react-router-dom";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Content } from "antd/lib/layout/layout";
import {
  DeleteOutlined,
  EditOutlined,
  PrinterOutlined,
  SnippetsOutlined,
} from "@ant-design/icons";
import { useUserStore } from "../../../../../context/UserStore";

export default function SmallMetric({ metric, removeMetric }) {
  const navigate = useNavigate();
  const user = useUserStore((state) => state.user);

  // Function to determine the lowest data point for the Y-axis
  const getMinY = (data) => {
    const values = data.map((item) => item.value);
    const goals = data.map((item) => item.goal_target);
    return Math.min(...values, ...goals);
  };

  const deleteMetric = (id) => {
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/users/metrics/${id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        type: "json",
      },
    ).then((response) => {
      if (response.ok) {
        removeMetric(id);
        return response.json();
      } else {
        throw new Error(response.statusText);
      }
    });
  };

  return (
    <>
      <Content>
        <div className="bg-white shadow">
          <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
            <div className="lg:flex lg:items-center lg:justify-between">
              <div className="flex-1 min-w-0">
                <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                  {`${metric.attributes.name} [${metric.attributes.unit.name}]`}
                </h2>
              </div>
              <div className="mt-5 grid grid-cols-2 gap-2 md:grid-cols-4 lg:mt-0 lg:ml-4">
                <button
                  type="button"
                  style={{ maxWidth: '10rem' }}
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700"
                  onClick={() =>
                    navigate(
                      `/align/monitoring-results/metrics/${metric.id}/status`,
                    )
                  }
                >
                  <SnippetsOutlined className="mr-2 h-5 w-5" aria-hidden="true" />
                  Status Update
                </button>
                {/* Replace `EditIcon` with your chosen icon component */}
                <button
                  type="button"
                  style={{ maxWidth: '10rem' }}
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-orange-500 hover:bg-orange-700"
                  onClick={() =>
                    navigate(
                      `/align/monitoring-results/metrics/${metric.id}/edit`,
                    )
                  }
                >
                  <EditOutlined className="mr-2 h-5 w-5" aria-hidden="true" />
                  Edit
                </button>
                {/* Replace `PrinterIcon` with your chosen icon component */}
                <button
                  type="button"
                  style={{ maxWidth: '10rem' }}
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700"
                  onClick={() =>
                    navigate(`/align/monitoring-results/metrics/${metric.id}`)
                  }
                >
                  <PrinterOutlined
                    className="mr-2 h-5 w-5"
                    aria-hidden="true"
                  />
                  View/Print
                </button>
                {/* Replace `DeleteIcon` with your chosen icon component */}
                <button
                  type="button"
                  style={{ maxWidth: '10rem' }}
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-red-500 hover:bg-red-700"
                  onClick={() => deleteMetric(metric.id)}
                >
                  <DeleteOutlined className="mr-2 h-5 w-5" aria-hidden="true" />
                  Delete
                </button>
              </div>
            </div>
          </div>
          {/* Descriptions replaced with simple flex div */}
          <div className="mt-5 border-t border-gray-200">
            <dl className="sm:divide-y sm:divide-gray-200">
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-base font-medium text-gray-500">Goal:</dt>
                <dd className="mt-1 text-base text-gray-900 sm:col-span-2 sm:mt-0">
                  <p className="text-blue-500 hover:underline">
                    {metric.attributes.metric_year} -{" "}
                    {metric.attributes.goal_name}
                  </p>
                </dd>
              </div>
            </dl>
          </div>
        </div>

        <ResponsiveContainer width="100%" height={400}>
          <AreaChart
            data={metric.attributes.graph_data}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis domain={[getMinY(metric.attributes.graph_data), "auto"]} />
            <Tooltip />
            <Legend />
            <Area
              type="monotone"
              dataKey="goal_target"
              name="target/goal"
              activeDot={{ stroke: "#888", strokeWidth: 2, r: 2 }}
              dot={{ stroke: "#888", strokeWidth: 2 }}
              stackId="2"
              strokeWidth="3"
              stroke="#ffc658"
              fill="#ffc65860"
            />
            <Area
              type="monotone"
              dataKey="value"
              name="observed value"
              activeDot={{ stroke: "#82ca9d", strokeWidth: 2, r: 2 }}
              dot={{ stroke: "#82ca9d", strokeWidth: 2 }}
              stackId="1"
              strokeWidth="3"
              stroke="#82ca9d"
              fill="#82ca9d60"
            />
          </AreaChart>
        </ResponsiveContainer>
      </Content>
    </>
  );
}
