import React from "react";
import {
  Button,
  Card,
  Col,
  Input,
  notification,
  Row,
  Skeleton,
  Space,
  Table,
} from "antd";
import questionsStyles from "./Questions.module.css";
import { useEffect, useState } from "react";
import { useUserStore } from "../../../context/UserStore";
import { useNavigate } from "react-router-dom";
import {
  SearchOutlined,
  EditOutlined,
  CheckOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";

export default function Questions() {
  const navigate = useNavigate();
  const [hospitals, setHospitals] = useState([]);
  const [questions, setQuestions] = React.useState([]);
  const [roundTypes, setRoundTypes] = useState([
    {
      text: "Patient",
      value: "Patient",
    },
    {
      text: "Staff",
      value: "Staff",
    },
    {
      text: "Physician",
      value: "Physician",
    },
    {
      text: "Family",
      value: "Family",
    },
  ]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingQuestionParams, setIsLoadingQuestionParams] = useState(true);
  const me = useUserStore((state) => state.me);
  const user = useUserStore((state) => state.user);

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Questions",
      dataIndex: "text",
      key: "text",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <div style={{ padding: 8 }}>
            <Input
              autoFocus
              placeholder="Search"
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
              value={selectedKeys}
              style={{ marginBottom: 8, display: "block" }}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropDown: false });
              }}
            />
            <Space>
              <Button
                onClick={() => {
                  confirm();
                }}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 rounded"
                icon={<SearchOutlined />}
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button
                onClick={() => {
                  clearFilters();
                }}
                className="bg-orange-500 hover:bg-orange-700 font-bold rounded"
              >
                Reset
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) =>
        record.attributes.text.toLowerCase().includes(value.toLowerCase()),
      render: (id, record) => {
        return <>{record.attributes.text}</>;
      },
    },
    {
      title: "Round Type",
      dataIndex: "round_type_text",
      key: "round_type_text",
      filters: roundTypes,
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => {
        console.log("record.attributes", record.attributes.round_type_text);
        // Ensure both value and record's round_type_text are strings
        const roundTypeText = String(record.attributes.round_type_text);
        const filterValue = String(value);
        return roundTypeText.toLowerCase().includes(filterValue.toLowerCase());
      },
      render: (text, record) => {
        return `${record.attributes.round_type_text}`;
      },
    },
    {
      title: "Page",
      dataIndex: "page_number",
      key: "page_number",
      sorter: (a, b) =>
        a.attributes.page_number > b.attributes.page_number ? 1 : -1,
      render: (text, record) => {
        return `${record.attributes.page_number}`;
      },
    },
    {
      title: "Order",
      dataIndex: "order",
      key: "order",
      sorter: (a, b) => (a.attributes.order > b.attributes.order ? 1 : -1),
      render: (text, record) => {
        return `${record.attributes.order}`;
      },
    },
    {
      title: "Hospital",
      dataIndex: "hospital_name",
      key: "hospital_name",
      filters: hospitals,
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => {
        return record.attributes.hospital_name
          .toLowerCase()
          .includes(value.toLowerCase());
      },
      render: (id, record) => {
        return `${record.attributes.hospital_name}`;
      },
    },
    {
      title: "Status",
      dataIndex: "disabled",
      key: "disabled",
      filters: [
        {
          text: "Active",
          value: false,
        },
        {
          text: "Inactive",
          value: true,
        },
      ],
      filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => {
        return record.attributes.disabled === value;
      },
      render: (text, record) => {
        return record.attributes.disabled ? "Inactive" : "Active";
      },
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "id",
      render: (id, record) => (
        <Space size="small">
          <Button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 rounded"
            onClick={() => navigate(`/admin/questions/${record.id}`)}
          >
            View
          </Button>
          <Button
            className="bg-orange-500 hover:bg-orange-700 font-bold rounded"
            onClick={() => navigate(`/admin/questions/${record.id}/edit`)}
          >
            <EditOutlined />
          </Button>
          <Button
            className="bg-red-500 hover:bg-red-700 font-bold rounded"
            onClick={() => disableQuestion(record.id)}
          >
            <DeleteOutlined />
          </Button>
        </Space>
      ),
    },
  ];

  const disableQuestion = async (id) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/admin/questions/${id}/disable`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        type: "json",
      },
    )
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error(response.statusText);
        }
      })
      .then((response) => {
        notification.success({
          message: response.message,
          description: response.message,
        });
      })
      .catch((error) => {
        notification.error({
          message: error.errors,
        });
        console.log(error);
      });
  };

  const loadQuestions = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/admin/questions/list`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        type: "json",
      },
    )
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error(response.statusText);
        }
      })
      .then((response) => {
        const newData = response.questions.data.map((item) => {
          return {
            ...item,
            key: item.id,
          };
        });
        if (me.attributes.role == "Administrator") {
          setQuestions(
            newData.filter(
              (item) =>
                item.attributes.hospital_id === me.attributes.hospital_id,
            ),
          );
        } else {
          setQuestions(newData);
        }

        let tempHospitals = response.questions.included.filter(
          (item) => item.type === "hospital",
        );

        if (me.attributes.role == "Administrator") {
          tempHospitals = tempHospitals.filter(
            (item) => item.attributes.id === me.attributes.hospital_id,
          );
        }

        setHospitals(
          tempHospitals.map((item) => {
            return {
              ...item,
              value: item.attributes.name,
              text: item.attributes.name,
            };
          }),
        );
        setHospitals((hospitals) => [...hospitals]);

        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    loadQuestions();
  }, []);

  return (
    <>
      <div
        className={`py-5 grid grid-cols-1 mb-5 ${questionsStyles.headerBar}`}
      >
        <h1 className="text-4xl text-center text-white">Questions</h1>
      </div>
      <Row>
        <Col span={24}>
          <Card
            title="Questions"
            bordered={false}
            bodyStyle={{ padding: 0 }}
            extra={
              <Space>
                <Button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 rounded"
                  icon={<PlusOutlined />}
                  onClick={() => navigate("/admin/questions/new")}
                >
                  Add Question
                </Button>
              </Space>
            }
          ></Card>
        </Col>
      </Row>
      <Skeleton loading={isLoading} active>
        <Table
          dataSource={questions}
          columns={columns}
          loading={isLoading}
          rowKey="id"
        />
      </Skeleton>
    </>
  );
}
