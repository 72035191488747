import React from "react";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  List,
  Row,
  Select,
  Skeleton,
  Space,
} from "antd";
import monitoringResultsStyles from "./monitoringResults.module.css";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import { Content } from "antd/lib/layout/layout";
import { PlusOutlined } from "@ant-design/icons";
import SmallMetric from "./Metrics/SmallMetric/SmallMetric";
import { useUserStore } from "../../../context/UserStore";
import integratedRoundsStyles from "../../Teach/IntegratedRounds/IntegratedRounds.module.css";

export default function MonitoringResults() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({
    area_id: null,
    metric_year: null,
    unit_id: null,
  });
  const areaIdOptions = [
    { value: "", label: "All Areas" },
    { value: 0, label: "Service" },
    { value: 1, label: "Quality" },
    { value: 2, label: "Safety" },
    { value: 3, label: "Financial" },
    { value: 4, label: "People" },
  ];
  const [loadingMetrics, setLoadingMetrics] = useState(true);
  const [loadingUnits, setLoadingUnits] = useState(true);
  const [metrics, setMetrics] = useState([]);
  const [unitChoices, setUnitChoices] = useState([]);
  const [yearChoices, setYearChoices] = useState([]);
  const user = useUserStore((state) => state.user);

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 8,
    },
  };

  // Removes Metric by ID from metrics array
  const removeMetric = (metricId) => {
    setMetrics(metrics.filter((metric) => metric.id !== metricId));
  };

  const getMetricData = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/users/metrics`,
      {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        type: "json",
      },
    )
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((response) => {
        setMetrics(response.metrics.data);
        setLoadingMetrics(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getUnitOptions = async (value) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/users/units`,
      {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        type: "json",
      },
    )
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((response) => {
        unitChoices.push({ label: "All Units", value: "" });
        for (let i = 0; i < response.units.data.length; i++) {
          unitChoices.push({
            label: response.units.data[i].attributes.name,
            value: response.units.data[i].id,
          });
        }
        setLoadingUnits(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onFinish = (values) => {};
  const onFinishFailed = (errorInfo) => {};

  const yearmax = new Date().getFullYear() + 1;
  const setYearOptions = () => {
    let yearOptions = [];
    yearOptions.push({ value: "", label: "All Years" });
    for (var i = yearmax; i > 1985; i--) {
      yearOptions.push({ value: i, label: i });
    }

    setYearChoices(yearOptions);
  };

  const onFieldsChange = (changedFields, allFields) => {
    changedFields.map((field) => {
      setFormData({ ...formData, [field.name[0]]: field.value });
    });
    searchMetrics(formData);
  };

  const searchMetrics = async (formData) => {
    setLoadingMetrics(true);
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/users/metrics/search`,
      {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({
          search: formData,
        }),
        type: "json",
      },
    )
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((response) => {
        setMetrics(response.metrics.data);
        setLoadingMetrics(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getUnitOptions();
    setYearOptions();
    getMetricData();
  }, []);

  return (
    <>
      <div
        className={`py-8 mb-10 flex items-center justify-center rounded-lg shadow-lg ${monitoringResultsStyles.headerBar}`}
        style={{ background: 'linear-gradient(135deg, #ACBD37 0%, #D1E075 100%)', color: '#fff' }}
      >
        <h1 className="text-4xl md:text-4xl text-center font-bold text-white tracking-wide">Team Goals</h1>
      </div>
      <div className="flex flex-col items-center md:items-start">
        <div className="w-full">
          <div className="bg-white rounded-lg p-0">
            <div className="flex justify-center md:justify-start space-x-2 mb-4">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 py-2 rounded"
                onClick={() =>
                  navigate("/align/monitoring-results/metrics/new")
                }
              >
                <svg
                  className="w-4 h-4 inline-block mr-2"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                New Metrics
              </button>
            </div>
          </div>
        </div>
      </div>
      <Content className="mt-2">
        <Skeleton loading={loadingUnits} active>
          <Form
            form={form}
            layout={layout}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Row>
              <Col span={5}>
                <Form.Item name="metric_year">
                  <Select
                    onChange={(value) => {
                      setFormData({
                        ...formData,
                        metric_year: value,
                        goal_year: value,
                      });
                      searchMetrics({
                        ...formData,
                        metric_year: value,
                        goal_year: value,
                      });
                    }}
                    placeholder="Select Year"
                    style={{ width: "100%" }}
                    options={yearChoices}
                  />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item name="area_id">
                  <Select
                    onChange={(value) => {
                      setFormData({
                        ...formData,
                        area_id: value,
                        type_id: value,
                      });
                      searchMetrics({
                        ...formData,
                        area_id: value,
                        type_id: value,
                      });
                    }}
                    placeholder="Select Area"
                    style={{ width: "100%" }}
                    options={areaIdOptions}
                  />
                </Form.Item>
              </Col>

              <Col span={5}>
                <Form.Item name="unit_id">
                  <Select
                    onChange={(value) => {
                      setFormData({ ...formData, unit_id: value });
                      searchMetrics({ ...formData, unit_id: value });
                    }}
                    placeholder="Select Unit"
                    style={{ width: "100%" }}
                    options={unitChoices}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Skeleton>
        <Skeleton loading={loadingMetrics} active>
          <List
            itemLayout="horizontal"
            dataSource={metrics}
            renderItem={(metric) => (
              <SmallMetric
                removeMetric={removeMetric}
                key={metric.id}
                metric={metric}
              />
            )}
          />
        </Skeleton>
      </Content>
    </>
  );
}
