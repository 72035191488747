import React from "react";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  notification,
  Row,
  Select,
  Skeleton,
} from "antd";
import newMetricStyles from "./newMetricResults.module.css";
import { Content } from "antd/lib/layout/layout";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { useUserStore } from "../../../../../context/UserStore";

export default function AdminNewMetric() {
  const { TextArea } = Input;
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({});
  const areaIdOptions = [
    { value: 0, label: "Service" },
    { value: 1, label: "Quality" },
    { value: 2, label: "Safety" },
    { value: 3, label: "Financial" },
    { value: 4, label: "People" },
  ];
  const [loadingUnits, setLoadingUnits] = useState(true);
  const [loadingYears, setLoadingYears] = useState(true);
  const measurementTypeOptions = [
    { value: 0, label: "Increase" },
    { value: 1, label: "Decrease" },
    { value: 2, label: "Variance from Target" },
  ];
  const targetIntervalOptions = [
    { value: 0, label: "Monthly" },
    { value: 1, label: "Quarterly" },
    { value: 2, label: "Annually" },
  ];
  const [unitChoices, setUnitChoices] = useState([]);
  const [yearChoices, setYearChoices] = useState([]);
  const yearmax = new Date().getFullYear() + 1;
  const user = useUserStore((state) => state.user);
  const me = useUserStore((state) => state.me);
  const getUnitOptions = async (value) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/admin/hospitals/${me.attributes.hospital_id}/units`,
      {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        type: "json",
      },
    )
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((response) => {
        const newData = response.units.data.map((unit) => {
          return {
            key: unit.id,
            label: unit.attributes.name,
            value: unit.id,
          };
        });
        setUnitChoices(newData);
        setLoadingUnits(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const setYearOptions = () => {
    let yearOptions = [];
    for (var i = yearmax; i > 1985; i--) {
      yearOptions.push({ value: i, label: i });
    }
    setYearChoices(yearOptions);
    setLoadingYears(false);
  };

  const createMetric = async (values) => {
    return fetch(`${process.env.REACT_APP_API_URL}/api/v1/admin/metrics`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify({
        metric: values,
      }),
      type: "json",
    })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((response) => {
        notification.success({
          message: response.message,
          description: response.message,
        });
        const metric = response.metric.data;
        navigate(`/admin/monitoring-results/metrics/${metric.id}/status`);
      })
      .catch((error) => {
        notification.error({
          message: error.message,
        });
        navigate(`/admin/monitoring-results/metrics/new`);
      });
  };

  const onFinish = (values) => {
    createMetric(formData);
  };

  const onFinishFailed = (errorInfo) => {
    let error_message = errorInfo.errorFields.map((item) => {
      return item.errors[0];
    });

    notification["error"]({
      message: "Failed to create new metric",
      description: `${error_message}. `,
    });
  };

  const onFieldsChange = (changedFields, allFields) => {
    changedFields.map((field) => {
      setFormData({ ...formData, [field.name[0]]: field.value });
    });
  };

  useEffect(() => {
    getUnitOptions();
    setYearOptions();
  }, []);

  return (
    <>
      <div
        className={`py-5 grid grid-cols-1 mb-5 ${newMetricStyles.headerBar}`}
      >
        <h1 className="text-4xl text-center text-white">New Metric</h1>
      </div>
      <Content>
        <Row>
          <Col span={20}>
            <Form
              form={form}
              layout="vertical"
              onFieldsChange={onFieldsChange}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item label="Area" name="area_id">
                <Select
                  onChange={(value) => {
                    setFormData({
                      ...formData,
                      area_id: value,
                      type_id: value,
                    });
                  }}
                  placeholder="Select Area"
                  options={areaIdOptions}
                />
              </Form.Item>

              <Form.Item label="Unit" name="unit_id">
                <Skeleton loading={loadingUnits} active>
                  <Select
                    onChange={(value) => {
                      setFormData({ ...formData, unit_id: value });
                    }}
                    options={unitChoices}
                    placeholder="Select a Unit"
                  />
                </Skeleton>
              </Form.Item>

              <Form.Item label="Metric Name" name="name">
                <Input
                  onChange={(e) => {
                    setFormData({ ...formData, name: e.target.value });
                  }}
                  placeholder="For example: Readmissions for CHF"
                />
              </Form.Item>

              <Form.Item label="Current Goal" name="target">
                <Input
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      target: e.target.value,
                      goal_name: e.target.value,
                    });
                  }}
                  placeholder='For example, "Reduce to 19%." Change this goal as conditions change. You will have an opportunity to add targets later.'
                />
              </Form.Item>

              <Form.Item label="Description" name="description">
                <TextArea />
              </Form.Item>

              <Form.Item label="Measurement Type" name="measured_as">
                <Select
                  onChange={(value) => {
                    setFormData({ ...formData, measured_as: value });
                  }}
                  options={measurementTypeOptions}
                  placeholder="Select a Measurement Type"
                />
              </Form.Item>

              <Form.Item
                label="How often is this measured?"
                name="target_interval"
              >
                <Select
                  onChange={(value) => {
                    setFormData({ ...formData, target_interval: value });
                  }}
                  options={targetIntervalOptions}
                  placeholder="Select an Interval"
                />
              </Form.Item>

              <Form.Item
                label="For what year is this being tracked?"
                name="goal_year"
                placeholder="For example: 2020"
              >
                <Skeleton loading={loadingYears} active>
                  <Select
                    onChange={(value) => {
                      setFormData({
                        ...formData,
                        goal_year: value,
                        metric_year: value,
                      });
                    }}
                    options={yearChoices}
                    placeholder="Select a Year"
                  />
                </Skeleton>
              </Form.Item>

              <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
              >
                <Button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 rounded"
                  htmlType="submit"
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Content>
    </>
  );
}
