import React from 'react';
import dailyHuddlesStyles from './DailyHuddles.module.css';
import { useNavigate, useParams } from 'react-router-dom';

export default function DailyHuddles() {
    const navigate = useNavigate();
    return (
        <>
            <div className={`py-5 mb-5 flex flex-col items-center justify-center space-y-4 ${dailyHuddlesStyles.headerBar}`} style={{ background: 'linear-gradient(135deg, #e87c10 0%, #f59222 100%)', color: '#fff' }}>
                <img className="w-48 rounded-full shadow-lg" src='/assets/images/huddle-icon.png' alt="Huddle Icon" />
                <h1 className='text-4xl text-center font-bold tracking-wide'>Huddles</h1>
            </div>
            <div className='max-w-4xl mx-auto p-6 bg-white shadow-lg rounded-lg'>
                <div className='mb-6'>
                    <h4 className='text-xl font-semibold mb-2'>Purpose</h4>
                    <p className='text-base text-gray-700'>Help the team succeed during today's shift or on current projects.</p>
                </div>
                <p className='text-gray-600 mt-5 leading-relaxed'>A Huddle is a brief “stand up” discussion led by the director, manager, or charge to help staff stay on the same page and address immediate issues.</p>
                <ol className='ml-5 list-decimal mb-5 mt-4 text-gray-800'>
                    <li className="mb-2">Keep it short - 5 minutes.</li>
                    <li className="mb-2">Highlight key issues for that shift, for example:
                        <ul className="list-disc list-inside ml-5 mt-2">
                            <li>Patient/family concerns</li>
                            <li>Volume</li>
                            <li>New procedures</li>
                        </ul>
                    </li>
                    <li className="mb-2">Solicit brief feedback and questions from staff.</li>
                    <li className="mb-2">Celebrate personal and team success, for example:
                        <ul className="list-disc list-inside ml-5 mt-2">
                            <li>New quality or patient experience scores</li>
                            <li>Work anniversaries</li>
                            <li>Birthdays</li>
                        </ul>
                    </li>
                    <li className="mb-2">End on a high note, with encouragement and a sense of confidence in the team.</li>
                </ol>
                <div className="flex justify-center mt-6">
                    <a
                        className='bg-blue-500 hover:bg-blue-700 text-center text-white font-bold py-2 px-6 rounded-full shadow-lg transform transition-transform duration-300 hover:scale-105'
                        target="_blank"
                        href="/assets/pdfs/daily-huddle-template.pdf"
                    >
                        Sample Huddle Agenda
                    </a>
                </div>
            </div>
        </>
    )
}
