import React from 'react'
import unitStyles from './Unit.module.css';
import { Button, Col, Form, notification, Row, Skeleton, Space, } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { useEffect, useState } from 'react';
import { useUserStore } from '../../../../context/UserStore';
import { useNavigate, useParams } from 'react-router-dom';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';


export default function Unit() {
    const navigate = useNavigate();
    const [units, setUnits] = React.useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [unit, setUnit] = React.useState({});
    const me = useUserStore((state) => state.me);
    const user = useUserStore((state) => state.user);
    const { id } = useParams();

    const disableUnit = async (id) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/admin/hospitals/${me.attributes.hospital_id}/units/${id}/disable`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                "Authorization": `Bearer ${user.token}`,
            },
            type: 'json',
        }).then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                throw new Error(response.statusText);
            }
        }).then(response => {
            notification.success({
                message: response.message,
                description: response.message,
            });
        }).catch(error => {
            notification.error({
                message: error.errors,
            });
            console.log(error);
        })
    };

    const loadUnit = async () => {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/v1/admin/hospitals/${me.attributes.hospital_id}/units/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                "Authorization": `Bearer ${user.token}`,
            },
            type: 'json',
        }).then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                throw new Error(response.statusText);
            }
        })
            .then(response => {
                setUnit(response.unit.data.attributes);
                setIsLoading(false);
            })
            .catch(error => {
                console.log(error);
            })
    };

    useEffect(() => {
        loadUnit();
    }, []);

    return (
        <>
            <div className={`py-5 grid grid-cols-1 mb-5 ${unitStyles.headerBar}`}>
                <h1 className='text-4xl text-center text-white'>View Unit</h1>
            </div>
            <Skeleton loading={isLoading} active>
                {(isLoading == false) && (
                    <>
                        <PageHeader
                            onBack={() => navigate(-1)}
                            ghost={false}
                            title={`${unit.name}`}
                            extra={[
                                <Space size='small'>
                                    <Button
                                        key='back'
                                        className='bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 rounded'
                                        onClick={() => navigate('/admin/units')}>
                                        Back
                                    </Button>
                                    <Button
                                        key='edit'
                                        className='bg-orange-500 hover:bg-orange-700 font-bold rounded'
                                        onClick={() => navigate(`/admin/units/${id}/edit`)}>
                                        <EditOutlined />
                                    </Button>
                                    <Button
                                        key='delete'
                                        className='bg-red-500 hover:bg-red-700 font-bold rounded'
                                        onClick={() => disableUnit(id)}>
                                        <DeleteOutlined />
                                    </Button>
                                </Space>
                            ]}>

                        </PageHeader>
                        <Row>
                            <Col span={20}>
                                <Form
                                    layout='vertical'
                                >
                                    <Row>
                                        <Col md={10} xs={20}>
                                            <Form.Item label='Unit Name'>
                                                {unit.name}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={10} xs={20}>
                                            <Form.Item label='Unit Description'>
                                                {unit.description}
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                </Form>
                            </Col>
                        </Row>
                    </>
                )}
            </Skeleton>
        </>
    )
}
