import React from 'react';
import { Button, Col, Divider, Form, Input, notification, Row, Select, Skeleton } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import editMetricStyles from './editMetric.module.css';
import { Content } from 'antd/lib/layout/layout';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { useUserStore } from '../../../../../context/UserStore';


export default function EditMetric() {
    const { metricId } = useParams();
    const { TextArea } = Input;
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [formData, setFormData] = useState({
    });
    const areaIdOptions = [
        { value: 0, label: 'Service' },
        { value: 1, label: 'Quality' },
        { value: 2, label: 'Safety' },
        { value: 3, label: 'Financial' },
        { value: 4, label: 'People' }
    ];
    const [initialValues, setInitialValues] = useState({
        area_id: 0,
        description: '',
        goal_year: 0,
        measured_as: '',
        target: '',
        target_interval: 0,
        unit_id: 0
    })
    const measurementTypeOptions = [
        { value: 0, label: 'Increase' },
        { value: 1, label: 'Decrease' },
        { value: 2, label: 'Variance from Target' }
    ];
    const [loadingMetricData, setLoadingMetricData] = useState(true);
    const [metric, setMetric] = useState({})
    const targetIntervalOptions = [
        { value: 0, label: 'Monthly' },
        { value: 1, label: 'Quarterly' },
        { value: 2, label: 'Annually' }
    ];
    const [unitChoices, setUnitChoices] = useState([]);
    const [yearChoices, setYearChoices] = useState([]);
    const yearmax = new Date().getFullYear() + 1;
    const user = useUserStore((state) => state.user);

    const getMetricData = async () => {
        getUnitOptions();
        setYearOptions();
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/v1/users/metrics/${metricId}`, {
            method: "get",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
            },
            type: "json"
        }).then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        }).then(response => {

            setInitialValues({
                area_id: response.metric.data.attributes.area_id,
                description: response.metric.data.attributes.description,
                metric_year: response.metric.data.attributes.metric_year,
                measured_as: Number(response.metric.data.attributes.measured_as),
                name: response.metric.data.attributes.name,
                target: response.metric.data.attributes.goal_name,
                target_interval: response.metric.data.attributes.target_interval,
                unit_id: response.metric.data.attributes.unit_id,
            });
            setMetric(response.metric.data);
            setLoadingMetricData(false);
        }).catch(error => {
            console.log(error)
        });
    }

    const getUnitOptions = async (value) => {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/v1/users/units`, {
            method: "get",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
            },
            type: "json"
        }).then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        }).then(response => {
            const newData = response.units.data.map((unit) => {
                return {
                    key: unit.id,
                    label: unit.attributes.name,
                    value: unit.id
                }
            })
            setUnitChoices(newData);
        }).catch(error => {
            console.log(error)
        });
    };

    const setYearOptions = () => {
        let yearOptions = [];
        for (var i = yearmax; i > 1985; i--) {
            yearOptions.push({ value: i, label: i })
        }
        setYearChoices(yearOptions);
    };

    const updateMetric = async (values) => {
        return fetch(`${process.env.REACT_APP_API_URL}/api/v1/users/metrics/${metricId}`, {
            method: 'put',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
            },
            body: JSON.stringify({
                metric: values
            }),
            type: "json"
        }).then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        }).then(response => {
            notification.success({
                message: 'Successfully updated metric',
                description: response.message,
            });
            navigate(`/align/monitoring-results/metrics/${metricId}`);

        }).catch(error => {
            notification.error({
                message: 'Failed to update metric, please try again',
                description: 'An error occurred when trying to update the metric, please try again.',
            });
            navigate(`/align/monitoring-results/metrics/${metricId}/edit`);
        });
    };

    const onFinish = (values) => {
        updateMetric(formData);
    };

    const onFinishFailed = (errorInfo) => {
        let error_message = errorInfo.errorFields.map((item) => {
            return item.errors[0];
        })

        notification['error']({
            message: 'Failed to update metric',
            description: `${error_message}. `,
        });
    };

    const onFieldsChange = (changedFields, allFields) => {
        changedFields.map((field) => {
            setFormData({ ...formData, [field.name[0]]: field.value })
        })
    };

    useEffect(() => {
        getMetricData();
    }, []);

    return (
        <>
            <div className={`py-5 mb-5 flex flex-col items-center justify-center ${editMetricStyles.headerBar}`}>
                <h1 className='text-4xl text-center text-white'>Edit Metric</h1>
            </div>
            <Content>
                {(metric && metric.attributes) && (
                    <>
                        <PageHeader
                            ghost={false}
                            onBack={() => navigate(-1)}
                            title={`${metric.attributes.name} [${metric.attributes.unit.name}]`}
                        >
                        </PageHeader>
                        <Row>
                            <Col span={20}>
                                <Skeleton loading={loadingMetricData} active>
                                    <Form
                                        form={form}
                                        layout='vertical'
                                        initialValues={initialValues}
                                        onFieldsChange={onFieldsChange}
                                        onFinish={onFinish}
                                        onFinishFailed={onFinishFailed}
                                    >
                                        <Form.Item
                                            label='Area'
                                            name='area_id'
                                        >
                                            <Select
                                                loading={loadingMetricData}
                                                onChange={
                                                    (value) => {
                                                        setFormData({ ...formData, area_id: value, type_id: value })
                                                    }
                                                }
                                                placeholder='Select Area'
                                                options={
                                                    areaIdOptions
                                                }
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            label='Unit'
                                            name='unit_id'
                                        >
                                            <Select
                                                loading={loadingMetricData}
                                                onChange={
                                                    (value) => {
                                                        setFormData({ ...formData, unit_id: value })
                                                    }
                                                }
                                                options={unitChoices}
                                                placeholder="Select a Unit"
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            label='Metric Name'
                                            name='name'
                                        >
                                            <Input
                                                onChange={
                                                    (e) => {
                                                        setFormData({ ...formData, name: e.target.value })
                                                    }
                                                }
                                                placeholder='For example: Readmissions for CHF'
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            label='Current Goal'
                                            name='target'

                                        >
                                            <Input
                                                onChange={
                                                    (e) => {
                                                        setFormData({ ...formData, target: e.target.value, goal_name: e.target.value })
                                                    }
                                                }
                                                placeholder='For example, "Reduce to 19%." Change this goal as conditions change. You will have an opportunity to add targets later.'
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            label='Description'
                                            name='description'
                                        >
                                            <TextArea />
                                        </Form.Item>

                                        <Form.Item
                                            label='Measurement Type'
                                            name='measured_as'
                                        >
                                            <Select
                                                loading={loadingMetricData}
                                                onChange={
                                                    (value) => {
                                                        setFormData({ ...formData, measured_as: value })
                                                    }
                                                }
                                                options={
                                                    measurementTypeOptions
                                                }
                                                placeholder="Select a Measurement Type"
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            label='How often is this measured?'
                                            name='target_interval'
                                        >
                                            <Select
                                                loading={loadingMetricData}
                                                onChange={
                                                    (value) => {
                                                        setFormData({ ...formData, target_interval: value })
                                                    }
                                                }
                                                options={
                                                    targetIntervalOptions
                                                }
                                                placeholder="Select an Interval"
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            label='For what year is this being tracked?'
                                            name='metric_year'
                                            placeholder='For example: 2020'
                                        >
                                            <Select
                                                loading={loadingMetricData}
                                                onChange={
                                                    (value) => {
                                                        setFormData({ ...formData, goal_year: value, metric_year: value })
                                                    }
                                                }
                                                options={yearChoices}
                                                placeholder="Select a Year"
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            wrapperCol={{
                                                offset: 8,
                                                span: 16,
                                            }}
                                        >
                                            <Button
                                                className='bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 rounded'
                                                htmlType="submit"
                                            >
                                                Submit
                                            </Button>
                                        </Form.Item>

                                    </Form>
                                </Skeleton>
                            </Col>
                        </Row>
                    </>
                )}

            </Content>
        </>
    )
}
