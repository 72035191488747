import React from 'react';

const UserStore = React.createContext();
UserStore.displayName = 'UserProvider Store';

export const userStore = () => React.useContext(UserStore);

export const UserStoreProvider = ({ children, initialState, reducer }) => {

    const [globalState, dispatch] = React.useReducer(reducer, initialState);

    return (
        <UserStore.Provider value={[globalState, dispatch]}>{children}</UserStore.Provider>
    );
};