import React from 'react';
import editSharedDecisionStyles from './EditSharedDecision.module.css';
import { Button, Col, Form, Input, notification, Row, Select, Skeleton } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useUserStore } from '../../../../context/UserStore';


export default function EditSharedDecision() {
    const me = useUserStore((state) => state.me);
    const user = useUserStore((state) => state.user);

    const { id } = useParams();
    const navigate = useNavigate();
    const { TextArea } = Input;
    const [form] = Form.useForm();

    const [employees, setEmployees] = useState([]);
    const [formData, setFormData] = useState({});
    const [initialValues, setInitialValues] = React.useState({});
    const [isLoadingSharedDecision, setIsLoadingSharedDecision] = useState(true);
    const [isLoadingUnits, setIsLoadingUnits] = useState(true);
    const [loadingEmployees, setLoadingEmployees] = useState(true);
    const [unitOptions, setUnitOptions] = React.useState([]);

    const getEmployees = async () => {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/v1/users/employees`, {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
            },
            type: "json"
        }).then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        }).then(response => {
            setEmployees(response.employees.data.map(employee => {
                return {
                    key: employee.id,
                    value: employee.id,
                    label: employee.attributes.full_name,
                }
            }));
            setLoadingEmployees(false);
        }).catch(error => {
            console.log(error)
        });
    };

    const getSharedDecision = async (id) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/users/problems/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${user.token}`
            },
            type: 'json',
        }).then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                throw new Error(response.statusText);
            }
        }).then(response => {
            let employees = response.problem.data.relationships.employees.data.map((item) => {
                return item.id;
            });
            response.problem.data.attributes['employees'] = employees;
            setInitialValues(response.problem.data.attributes);
            setIsLoadingSharedDecision(false);
        }).catch(error => {
            notification.error({
                message: error.errors,
            });
        })
    };

    const loadUnits = async () => {
        setIsLoadingUnits(true);
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/v1/admin/hospitals/${me.attributes.hospital_id}/units`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                "Authorization": `Bearer ${user.token}`,
            },
            type: 'json',
        }).then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                throw new Error(response.statusText);
            }
        })
            .then(response => {
                const newData = response.units.data.map((item) => {
                    return {
                        label: item.attributes.name,
                        value: item.attributes.id,
                        key: item.id,
                    }
                })
                setUnitOptions(newData);
                setIsLoadingUnits(false);
            })
            .catch(error => {
                console.log(error);
            })
    };

    const onFieldsChange = (changedFields, allFields) => {
        changedFields.map((field) => {
            setFormData({ ...formData, [field.name[0]]: field.value })
        })
    };

    const onFinish = (values) => {
        updateSharedDecision(formData);
    };

    const onFinishFailed = (errorInfo) => {
        let error_message = errorInfo.errorFields.map((item) => {
            return item.errors[0];
        })

        notification['error']({
            message: 'Failed to create question',
            description: `${error_message}. `,
        });
    };

    const outcomeOptions = [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
    ]

    const updateSharedDecision = async (data) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/users/problems/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${user.token}`
            },
            body: JSON.stringify({
                problem: data
            }),
            type: 'json',
        }).then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                throw new Error(response.statusText);
            }
        }).then(response => {
            notification['success']({
                message: 'Shared Decision Updated',
                description: `Shared Decision has been updated successfully.`,
            });
            navigate(`/empower/shared-decisions`);
        }).catch(error => {
            notification.error({
                message: error.errors,
            });
        })
    }

    useEffect(() => {
        if (id) {
            getEmployees();
            getSharedDecision(id);
            loadUnits();
        }
    }, [id]);


    return (
        <>
            <div className={`py-5 mb-5 flex flex-col items-center justify-center ${editSharedDecisionStyles.headerBar}`}>
                <h1 className="text-4xl text-center text-white">Edit Shared Decision</h1>
            </div>
            <Skeleton loading={isLoadingSharedDecision || isLoadingUnits || loadingEmployees} active>
                <Row>
                    <Col span={20}>
                        <Form
                            layout='vertical'
                            onFieldsChange={onFieldsChange}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            initialValues={initialValues}
                        >
                            <Form.Item label='Units'>
                                <Form.Item
                                    name='unit_id'
                                    rules={[{ required: true, message: 'Please choose a Hospital' }]}
                                >
                                    <Select
                                        style={{ width: '100%' }}
                                        loading={isLoadingUnits}
                                        placeholder="Select Unit"
                                        options={unitOptions}
                                    />
                                </Form.Item>
                            </Form.Item>
                            <Form.Item label='Project Name'>
                                <Form.Item name='problem' rules={[{ required: true, message: 'Please input a Problem' }]}>
                                    <Input />
                                </Form.Item>
                            </Form.Item>
                            <Form.Item label='Project Description'>
                                <Form.Item name='description' rules={[{ required: true, message: 'Please input a Description' }]}>
                                    <TextArea rows={4} />
                                </Form.Item>
                            </Form.Item>
                            <Form.Item label='Staff'>
                                <Form.Item
                                    name='employees'
                                    rules={[{ required: true, message: 'Please input a Staff Member' }]}
                                >
                                    <Select
                                        mode="multiple"
                                        options={employees}
                                        placeholder="Select an employee"
                                    />
                                </Form.Item>
                            </Form.Item>
                            <Form.Item label='Other Team'>
                                <Form.Item name='team' rules={[{ required: false, message: 'Please input a Team' }]}>
                                    <Input />
                                </Form.Item>
                            </Form.Item>
                            <Form.Item label='Status'>
                                <Form.Item name='status' rules={[{ required: true, message: 'Please input a Status' }]}>
                                    <TextArea rows={4} />
                                </Form.Item>
                            </Form.Item>
                            <Form.Item label='Complete?'>
                                <Form.Item name='complete' rules={[{ required: true, message: 'Please input a Complete' }]}>
                                    <Select
                                        style={{ width: '100%' }}
                                        loading={isLoadingUnits}
                                        placeholder="Complete?"
                                        options={outcomeOptions}
                                    />
                                </Form.Item>
                            </Form.Item>
                            <Form.Item>
                                <Form.Item>
                                    <Button
                                        className='bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 rounded'
                                        htmlType="submit"
                                    >
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Form.Item>

                        </Form>
                    </Col>
                </Row>
            </Skeleton>
        </>
    )
}
