import React from "react";
import { Avatar, Button, Table, Typography } from "antd";
import dayjs from "dayjs";
import LoadingSpinner from "../../Shared/LoadingSpinner/LoadingSpinner";
import { useNavigate, useParams } from "react-router-dom";
import coachingStyles from "./Coaching.module.css";
import { useUserStore } from "../../../context/UserStore";

const { Text, Title } = Typography;
const { useState, useEffect } = React;

export default function Coaching(props) {
  const navigate = useNavigate();
  const { employeeId, mentorType = "all" } = useParams();
  const [employee, setEmployee] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [mentors, setMentors] = useState([]);
  const [order, setOrder] = useState("asc");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [sort, setSort] = useState("id");
  const [total, setTotal] = useState(1);
  const user = useUserStore((state) => state.user);

  const customFetch = async (page, pageSize) => {
    typeof page === "object" || typeof page === "undefined"
      ? (page = 1)
      : (page = page);
    typeof pageSize === "undefined" ? (pageSize = 25) : (pageSize = pageSize);
    setIsLoading(true);
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/users/employees/${employeeId}/mentors/${mentorType}/list?page=${page}&per_page=${pageSize}` +
        `&sort=${sort}&order=${order}`,
      {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        type: "json",
      },
    )
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((response) => {
        const newData = response.mentors.data.map((item) => {
          return {
            ...item,
            key: item.id,
          };
        });
        setEmployee(response.employee.data);
        setMentors(newData);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const returnIcon = (type_id) => {
    switch (type_id) {
      case 0: // coaching
        return "/assets/images/coaching-icon.png";
        break;
      case 1: // observation
        return "/assets/images/observation-icon.png";
        break;
      case 2: // development
        return "/assets/images/dialogue-icon.png";
        break;
      case 3: // RRS Observation
        return "/assets/images/dialogue-icon.png";
        break;
    }
  };

  const viewMentor = (employee, record) => {
    navigate(`/my-employees/${employee}/coaching/${record}/view`);
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      sorter: (a, b) => (a.id > b.id ? 1 : -1),
      render: (id) => `${id}`,
      width: "7%",
    },
    {
      title: "",
      dataIndex: "attributes.mentor_type_id",
      render: (id, record) => {
        return (
          <>
            <Avatar
              shape={"square"}
              size={40}
              style={{ backgroundColor: "blue" }}
              src={returnIcon(record.attributes.mentor_type_id)}
            />
          </>
        );
      },
      width: "5%",
    },
    {
      title: "Date",
      dataIndex: "attributes.date_legible",
      defaultSortOrder: "ascend",
      sorter: (a, b) => (a.date_legible > b.date_legible ? 1 : -1),
      render: (id, record) => {
        return `${dayjs(record.attributes.date_legible, "MM/DD/YYYY").format("MM/DD/YYYY")}`;
      },
      width: "10%",
    },
    {
      title: "Positive/Negative",
      dataIndex: "attributes.feedback_type",
      sorter: (a, b) => (a.feedback_type > b.feedback_type ? 1 : -1),
      render: (id, record) => {
        return `${record.attributes.feedback_type?.charAt(0).toUpperCase() + record.attributes.feedback_type?.slice(1)}`;
      },
      width: "15%",
    },
    {
      title: "Notes",
      dataIndex: "attributes.feedback_text",
      render: (id, record) => {
        return `${record.attributes.feedback_text}`;
      },
      width: "25%",
    },
    {
      title: "Mentor Type",
      dataIndex: "attributes.mentor_type_name",
      render: (id, record) => {
        return `${record.attributes.mentor_type_name}`;
      },
    },
    {
      title: "Action",
      dataIndex: "id",
      render: (id, record) => {
        return (
          <>
            <Button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 rounded"
              onClick={() => viewMentor(employee.id, record.id)}
            >
              View Feedback
            </Button>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    customFetch({});
  }, []);

  return (
    <>
      <div className={`py-5 grid grid-cols-1 mb-5 ${coachingStyles.headerBar}`}>
        <h1 className="text-4xl text-center text-white">
          Record of Interactions
        </h1>
      </div>
      <div className="flex flex-col justify-left">
        {Object.keys(employee).length === 0 ? (
          <LoadingSpinner />
        ) : (
          <>
            <h1 className="text-xl">
              <strong>Name:</strong> {employee.attributes.first_name}{" "}
              {employee.attributes.last_name}
            </h1>
            <p className="text-lg">
              <strong>Email:</strong> {employee.attributes.email}
            </p>
            <p className="text-lg">
              <strong>Notes:</strong> {employee.attributes.notes}
            </p>
          </>
        )}
      </div>
      <div className="overflow-x-auto">
        <Table
          columns={columns}
          dataSource={mentors}
          loading={isLoading}
          pagination={{
            current: page,
            pageSize: pageSize,
            total: total,
            onChange: (page, pageSize) => {
              setPage(page);
              setPageSize(pageSize);
              customFetch(page, pageSize);
            },
          }}
          scroll={{ x: true }}
          rowKey="id"
        />
      </div>
    </>
  );
}
