import React from 'react'
import {Button, Col, Divider, Row, Select, Skeleton, Space, Table} from 'antd';
import integratedRoundsStyles from './IntegratedRounds.module.css'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';
import { useUserStore } from '../../../context/UserStore';
import {
    ReloadOutlined,
} from '@ant-design/icons';
import dayjs from "dayjs";
import dashboardStyles from "../../Dashboard/Dashboard.module.css";

export default function IntegratedRounds() {
    const navigate = useNavigate();
    const showNeedsFollowUp = useParams().needs_follow_up
    const [integratedRounds, setIntegratedRounds] = useState([]);
    const [loadingInteractions, setLoadingInteractions] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [interactions, setInteractions] = React.useState([]);
    const [filteredInteractions, setFilteredInteractions] = React.useState([]);
    const [selectedUnit, setSelectedUnit] = React.useState(0);
    const [selectedRoundType, setSelectedRoundType] = React.useState(0);
    const [selectedFollowUpStatus, setSelectedFollowUpStatus] = React.useState(0);
    const [units, setUnits] = React.useState({});
    const [roundTypes, setRoundTypes] = React.useState({});
    const user = useUserStore((state) => state.user);

    const url = window.location.pathname;
    const needs_follow_up = url.substring(url.lastIndexOf('/') + 1);

    const loadInteractions = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/users/interactions`, {
            method: "get",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
            },
            type: "json"
        }).then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        }).then(response => {
            const interactions = response.interactions.data;
            const units = response.units;
            const roundTypes = response.round_types;

            // Combine the data into one array for the table
            // const combinedData = interactions.map(interaction => ({
            //     ...interaction,
            //     unit: units[interaction.unit_id], // Assuming unit_id is a key in interaction
            //     roundType: roundTypes[interaction.round_type_id], // Assuming round_type_id is a key in interaction
            // }));

            const combinedData = interactions.map(interaction => ({
                ...interaction,
                unit: units.find(unit => unit.id === interaction.unit_id), // Ensure this finds the correct unit
                roundType: roundTypes.find(roundType => roundType.id === interaction.round_type_id), // Ensure this finds the correct roundType
            }));

            // Filter data if necessary
            const filteredData = needs_follow_up === 'needs_follow_up'
                ? combinedData.filter(interaction => interaction.attributes.needs_follow_up === true)
                : combinedData;

            setIntegratedRounds(filteredData);
            setIsLoading(false);
            setLoadingInteractions(false);
            // ###
            //change here to fill the state integratedRounds
            // setInteractions(response.interactions.data);
            // setUnits(response.units);
            // setRoundTypes(response.round_types);

            // if (needs_follow_up === 'needs_follow_up') {
            //     setSelectedFollowUpStatus(true);
            //     setFilteredInteractions(response.interactions.data.filter(interaction => interaction.attributes.needs_follow_up === true));
            // } else {
            //     setFilteredInteractions(response.interactions.data);
            // }
            // setLoadingInteractions(false);

        }).catch(error => {
            console.log(error)
            setIsLoading(false);
        });
    };

    // Filters Interactions By Unit or Round Type
    // Saves Results to filteredInteractions
    const handleChange = (unit, round, followUpStatus) => {
        setLoadingInteractions(true);
        let tempInteractions = integratedRounds;
        if (unit !== 0) {
            tempInteractions = tempInteractions.filter(interaction => interaction.unit?.id === unit);
        }
        if (round !== 0) {
            tempInteractions = tempInteractions.filter(interaction => interaction.roundType?.id === round);
        }
        if (followUpStatus !== 0) {
            tempInteractions = tempInteractions.filter(interaction => interaction.attributes.needs_follow_up === followUpStatus);
        }
        setFilteredInteractions(tempInteractions);
        setLoadingInteractions(false);
    };

    const followUpStatuses = [
        { value: 0, label: 'All Statuses' },
        { value: true, label: 'Needs Follow Up' },
        { value: false, label: 'No Follow Up Needed' },
    ]

    const needsFollowUp = (interaction) => {
        if (interaction.attributes.needs_follow_up === true) {
            return (
                <ReloadOutlined onClick={() => navigate(`/teach/integrated-rounds/${item.id}`)} />
            )
        }
        return false;
    }


    useEffect(() => {
        loadInteractions();
    }, []);

    const columns = [
        {
            title: "Details",
            dataIndex: "attributes",
            render: (attributes) => (
                <>
                    <div>{attributes.unit_name || 'N/A'}</div>
                    <div>{attributes.round_type_text || 'N/A'}</div>
                    <div>{attributes.room_number || 'N/A'}</div>
                </>
            ),
            responsive: ['xs'], // Show this combined column only on extra-small screens
        },
        {
            title: "Unit Name",
            dataIndex: "attributes",
            sorter: (a, b) => (a.id > b.id ? 1 : -1),
            render: (attributes) => `${attributes.unit_name || 'N/A'}`,
            responsive: ['sm','md'], // Only show on medium and larger screens
        },
        {
            title: "Round Type",
            dataIndex: "attributes",
            render: (attributes) => `${attributes.round_type_text || 'N/A'}`,
            responsive: ['sm','md'], // Only show on medium and larger screens
        },
        {
            title: "Room Number",
            dataIndex: "attributes",
            render: (attributes) => `${attributes.room_number || 'N/A'}`,
            responsive: ['sm','md'], // Only show on medium and larger screens
        },
        {
            title: "Follow Up?",
            dataIndex: "attributes",
            filters: [
                { text: 'Yes', value: true },
                { text: 'No', value: false },
            ],
            onFilter: (value, record) => record.attributes.needs_follow_up === value,
            render: (attributes) => attributes.needs_follow_up ? (
                <div
                    style={{
                        display: 'inline-block',
                        background: 'linear-gradient(135deg, #FFBABA, #FFD5D5)',
                        padding: '10px',
                        borderRadius: '8px',
                        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                    }}
                >
                    <span style={{ color: '#DC143C', fontWeight: 'bold' }}>Yes</span>
                </div>
            ) : (
                'No'
            ), // Display "Yes" as bold or "No" as regular
        },
        {
            title: "Date",
            dataIndex: "attributes.updated_at",
            sorter: (a, b) => (a.id > b.id ? 1 : -1),
            defaultSortOrder: 'descend',
            render: (id, record) => {
                return `${dayjs(record.attributes.updated_at, "YYYY-MM-DD").format("MM/DD/YYYY")}`;
            },

        },
        {
            title: "Action",
            dataIndex: "id",
            render: (id, record) => (
                <Space size="small">
                    <Button
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 rounded"
                        onClick={() => navigate(`/teach/integrated-rounds/${id}`)}
                    >
                        View
                    </Button>
                </Space>
            ),
        },
    ];

    return (
        <>
            <div className={`py-8 mb-10 flex items-center justify-center bg-gradient-to-r from-[#e87c10] to-[#f59222] rounded-lg shadow-lg ${integratedRoundsStyles.headerBar}`}>
                <h1 className="text-4xl md:text-4xl text-center font-bold text-white tracking-wide">Integrated Rounds</h1>
            </div>
            <div className="flex flex-col items-center md:items-start">
                <div className="w-full">
                    <div className="bg-white rounded-lg p-0">
                        <div className="flex justify-center md:justify-start space-x-2 mb-4">
                            <button
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 py-2 rounded"
                                onClick={() => navigate('/teach/integrated-rounds/new')}>
                                <svg className="w-4 h-4 inline-block mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd"></path></svg>
                                New Round
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/*<Row>*/}
            {/*    <Col>*/}
            {/*        {(loadingInteractions ? <p>Loading</p> :*/}
            {/*            <>*/}
            {/*                <Space wrap>*/}
            {/*                    <Select*/}
            {/*                        disabled={loadingInteractions}*/}
            {/*                        defaultValue="All Units"*/}
            {/*                        style={{ width: 120 }}*/}
            {/*                        onChange={*/}
            {/*                            (value) => {*/}
            {/*                                setSelectedUnit(Number(value));*/}
            {/*                                handleChange(value, selectedRoundType, selectedFollowUpStatus);*/}
            {/*                            }*/}
            {/*                        }*/}
            {/*                    >*/}
            {/*                        <Select.Option key={0} value={0}>All Units</Select.Option>*/}
            {/*                        {Object.keys(units).map(key => (*/}
            {/*                            <Select.Option key={key} value={key}>{units[key].name}</Select.Option>*/}
            {/*                        ))}*/}
            {/*                        /!*{Object.keys(units).map(key => (*!/*/}
            {/*                        /!*    <Select.Option key={key} value={key}>{}</Select.Option>*!/*/}
            {/*                        /!*))}*!/*/}
            {/*                    </Select>*/}
            {/*                    <Select*/}
            {/*                        disabled={loadingInteractions}*/}
            {/*                        defaultValue="All Types"*/}
            {/*                        style={{ width: 120 }}*/}
            {/*                        onChange={*/}
            {/*                            (value) => {*/}
            {/*                                setSelectedRoundType(Number(value));*/}
            {/*                                handleChange(selectedUnit, value, selectedFollowUpStatus);*/}
            {/*                            }*/}
            {/*                        }*/}
            {/*                    >*/}
            {/*                        <Select.Option key={0} value={0}>All Types</Select.Option>*/}
            {/*                        {Object.keys(roundTypes).map(key => (*/}
            {/*                            <Select.Option key={key} value={key}>{roundTypes[key].name}</Select.Option>*/}
            {/*                        ))}*/}
            {/*                    </Select>*/}
            {/*                    <Select*/}
            {/*                        disabled={loadingInteractions}*/}
            {/*                        defaultValue={selectedFollowUpStatus}*/}
            {/*                        style={{ width: 180 }}*/}
            {/*                        onChange={*/}
            {/*                            (value) => {*/}
            {/*                                setSelectedFollowUpStatus(Number(value));*/}
            {/*                                handleChange(selectedUnit, selectedRoundType, value);*/}
            {/*                            }}*/}
            {/*                        options={followUpStatuses}*/}
            {/*                    >*/}
            {/*                    </Select>*/}
            {/*                </Space>*/}

            {/*            </>*/}
            {/*        )}*/}

            {/*    </Col>*/}
            {/*</Row>*/}
            {/*<Divider />*/}
            {/*<div className="container mx-auto px-4">*/}
            {/*    {loadingInteractions ? (*/}
            {/*        <div>Loading...</div> // Placeholder for your loading state*/}
            {/*    ) : (*/}
            {/*        <div className="space-y-4">*/}
            {/*            {filteredInteractions.map((item) => (*/}
            {/*                <div key={item.id} className="border-b border-gray-200 py-4">*/}
            {/*                    <div className="flex flex-wrap -mx-4">*/}
            {/*                        <div className="w-full lg:w-10/12 px-4 flex items-center">*/}
            {/*                            <div className="w-1/12 text-center">*/}
            {/*                                {needsFollowUp(item)} /!* Assuming needsFollowUp returns a JSX element or null *!/*/}
            {/*                            </div>*/}
            {/*                            <div className="w-9/12">*/}
            {/*                                <Link to={`/teach/integrated-rounds/${item.id}`} className="text-blue-600 hover:text-blue-800">*/}
            {/*                                    {item.attributes.unit_name} {item.attributes.room_number}*/}
            {/*                                </Link>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                        <div className="w-full lg:w-2/12 px-4">*/}
            {/*                            <Link to={`/teach/integrated-rounds/${item.id}`} className="text-blue-600 hover:text-blue-800">*/}
            {/*                                {item.attributes.friendly_created_at} {item.attributes.round_type_text}*/}
            {/*                            </Link>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            ))}*/}
            {/*        </div>*/}
            {/*    )}*/}
            {/*</div>*/}
            <div className="oveflow-x-auto">
                <Table
                    columns={columns}
                    dataSource={integratedRounds}
                    loading={isLoading}
                    rowKey="id"
                    scroll={{ x: 'max-content' }}
                />
            </div>
        </>
    )
}
