import React from 'react'
import newUnitStyles from './NewUnit.module.css';
import { Button, Col, Form, Input, notification, Row } from 'antd';
import { useState } from 'react';
import { useUserStore } from '../../../../context/UserStore';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';


export default function NewUnit() {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [formData, setFormData] = useState({});
    const [initialValues, setInitialValues] = React.useState({});
    const [unit, setUnit] = React.useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const me = useUserStore((state) => state.me);
    const user = useUserStore((state) => state.user);
    const { TextArea } = Input;

    const createUnit = async (values) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/admin/hospitals/${me.attributes.hospital_id}/units`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            },
            body: JSON.stringify({ unit: values }),
            type: 'json',
        }).then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        }).then(response => {
            notification.success({
                message: response.message,
                description: response.message,
            });
            navigate(`/admin/units`);

        }).catch(error => {
            notification.error({
                message: error.errors,
            });
        });
    };

    const onFieldsChange = (changedFields, allFields) => {
        changedFields.map((field) => {
            setFormData({ ...formData, [field.name[0]]: field.value })
        })
    };

    const onFinish = (values) => {
        createUnit(formData);
    };

    const onFinishFailed = (errorInfo) => {
        let error_message = errorInfo.errorFields.map((item) => {
            return item.errors[0];
        })

        notification['error']({
            message: 'Failed to edit Unit',
            description: `${error_message}. `,
        });
    };

    return (
        <>
            <div className={`py-5 grid grid-cols-1 mb-5 ${newUnitStyles.headerBar}`}>
                <h1 className='text-4xl text-center text-white'>New Unit</h1>
            </div>
            <Row>
                <Col span={20}>
                    <Form
                        form={form}
                        layout='vertical'
                        initialValues={initialValues}
                        onFieldsChange={onFieldsChange}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        <Row>
                            <Col md={10} xs={20}>
                                <Form.Item
                                    name='name'
                                    label='Unit Name'
                                    rules={[{ required: true, message: 'Please enter a Unit Name' }]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={10} xs={20}>
                                <Form.Item name='description' label='Unit Description'>
                                    <TextArea />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify='space-between'>
                            <Col md={10} xs={24}>
                                <Form.Item>
                                    <Form.Item>
                                        <Button
                                            className='bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 rounded'
                                            htmlType="submit"
                                        >
                                            Submit
                                        </Button>
                                    </Form.Item>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </>
    )
}
