import React from "react";
import {
  Button,
  Col,
  Form,
  message,
  Upload,
  notification,
  Row,
  Select,
} from "antd";
import importHospitalStaffStyles from "./ImportHospitalStaff.module.css";
import { useEffect, useState } from "react";
import { useUserStore } from "../../../../context/UserStore";
import { useNavigate, useParams } from "react-router-dom";
import { UploadOutlined } from "@ant-design/icons";
import Papa from "papaparse";

export default function ImportHospitalStaff() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [hospitals, setHospitals] = useState([]);
  const [initialValues, setInitialValues] = React.useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingHospitals, setIsLoadingHospitals] = useState(true);
  const [isLoadingUnits, setIsLoadingUnits] = useState(true);
  const [hospitalId, setHospitalId] = useState(null);
  const [importType, setImportType] = useState(null);
  const [unitOptions, setUnitOptions] = React.useState([]);
  const [userUnits, setUserUnits] = React.useState([]);
  const user = useUserStore((state) => state.user);
  const [uploading, setUploading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [csvData, setCsvData] = useState({});

  const loadHospitals = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/admin/hospitals/list`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        type: "json",
      },
    )
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error(response.statusText);
        }
      })
      .then((response) => {
        const newData = response.hospitals.data.map((item) => {
          return {
            label: item.attributes.name,
            value: item.attributes.id,
            key: item.id,
          };
        });
        setHospitals(newData);
        setIsLoadingHospitals(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const loadUnits = async (hospital_id) => {
    setIsLoadingUnits(true);
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/admin/hospitals/${hospital_id}/units/list`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        type: "json",
      },
    )
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error(response.statusText);
        }
      })
      .then((response) => {
        const newData = response.units.data.map((item) => {
          return {
            label: item.attributes.name,
            value: item.attributes.id,
            key: item.id,
          };
        });
        setUnitOptions(newData);
        setIsLoadingUnits(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const uploadStaff = async () => {
    setUploading(true);
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/admin/hospitals/${hospitalId}/upload_staff`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({
          hospital_staff: {
            hospitalId: hospitalId,
            unitIds: userUnits,
            importType: importType,
            employees: csvData,
          },
        }),
        type: "json",
      },
    )
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((response) => {
        setFileList([]);
        notification.success({
          message: response.message,
          description: response.message,
        });
        setUploading(false);
        navigate(`/admin/users`);
      })
      .catch((error) => {
        notification.error({
          message: error.errors,
        });
      });
  };

  //const handleFileChange = ({ fileList: newFileList }) => setFileList(newFileList);

  const props = {
    onRemove: (file) => {
      Papa.parse(file, {
        complete: function (results) {
          setCsvData(results.data);
        },
      });
      // return isCSV || Upload.LIST_IGNORE;
    },
    beforeUpload: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      setFileList([...fileList, file]);
      console.log("file", file);

      Papa.parse(file, {
        header: true,
        complete: function (results) {
          setCsvData(results.data);
          results.data.forEach((item, index) => {
            let person = JSON.stringify(item);
          });
        },
      });

      return false;
    },
    fileList,
  };

  const handleUpload = () => {
    uploadStaff(csvData);
  };

  useEffect(() => {
    loadHospitals();
  }, []);

  return (
    <>
      <div
        className={`py-5 grid grid-cols-1 mb-5 ${importHospitalStaffStyles.headerBar}`}
      >
        <h1 className="text-4xl text-center text-white">Import Staff</h1>
      </div>
      <Row>
        <Col span={20}>
          <Form layout="vertical">
            <Row justify="space-between">
              <Col md={7} xs={20}>
                <Form.Item label="Hospital">
                  <Form.Item
                    name="hospital_id"
                    rules={[
                      { required: true, message: "Please choose a Hospital" },
                    ]}
                  >
                    <Select
                      placeholder="Select A Hospital"
                      loading={isLoadingHospitals}
                      onChange={(value) => {
                        setHospitalId(value);
                        loadUnits(value);
                      }}
                      options={hospitals}
                    ></Select>
                  </Form.Item>
                </Form.Item>
              </Col>
              <Col md={7} xs={20}>
                <Form.Item label="Units">
                  <Form.Item
                    name="unit_ids"
                    rules={[
                      { required: false, message: "Please choose a Unit" },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      style={{ width: "100%" }}
                      loading={isLoadingUnits}
                      placeholder="Select Unit(s)"
                      options={unitOptions}
                      onChange={(value) => {
                        setUserUnits(value);
                      }}
                    />
                  </Form.Item>
                </Form.Item>
              </Col>
              <Col md={7} xs={20}>
                <Form.Item label="Upload Type">
                  <Form.Item
                    name="import_type"
                    rules={[
                      { required: true, message: "Choose an Import Type" },
                    ]}
                  >
                    <Select
                      style={{ width: "100%" }}
                      placeholder="Select Import Type"
                      onChange={(value) => {
                        setImportType(value);
                      }}
                      options={[
                        {
                          label: "Managers",
                          value: "managers",
                        },
                        {
                          label: "Employees",
                          value: "employees",
                        },
                      ]}
                    />
                  </Form.Item>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md={10} xs={24}>
                <Form.Item label="Input File">
                  <Form.Item
                    name="inputFile"
                    rules={[{ required: true, message: "Attach A File" }]}
                  >
                    <Upload
                      multiple={false}
                      maxCount={1}
                      {...props}
                    >
                      <Button
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 rounded"
                        icon={<UploadOutlined />}
                      >
                        Upload csv only
                      </Button>
                    </Upload>
                  </Form.Item>
                </Form.Item>
              </Col>
            </Row>
            <Row justify="space-between">
              <Col md={10} xs={24}>
                <Form.Item>
                  <Form.Item>
                    <Button
                      onClick={handleUpload}
                      disabled={fileList.length === 0}
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 rounded"
                      htmlType="submit"
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  );
}
