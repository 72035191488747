// Renders a form for the user to change their password
// Form includes fields for:
// email address
// new password
// confirm new password
// submit button
//
// Form should be validated before submission
// Passwords must match and be over eight characters.
// If the form is not valid, display error messages
// If the form is valid, submit the form to the server
// If the server returns an error, display the error message
// If the server returns a success message, display the success message
// If the server returns a success message, redirect the user to the login page
//a

import { Button, Form, Image, Input, Layout, notification } from "antd";
import React, { useEffect, useState } from "react";
import "./ChangePassword.css";

export default function ChangePassword() {
  const [loadingHospital, setLoadingHospital] = useState(true);
  const [logo, setLogo] = useState("");

  // Get the subdomain from the URL
  const subdomain = window.location.hostname.split(".")[0];

  // get the token from the url
  // token is the value of reset_key in the query string of the url.
  const token = new URLSearchParams(window.location.search).get("reset_key");
  const [welcomeMessage, setWelcomeMessage] = useState("");

  const createNewPassword = async (credentials) => {
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/users/change_password`,
      {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(credentials),
      },
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error(res);
        }
      })
      .then((json) => {
        setUser(json.user);
        getCurrentUser(json.user);
      })
      .catch((err) => {
        notification.error({
          message: "Login Failed",
          description: "Please check your credentials and try again.",
        });
      });
  };

  // Find out what hospital the user is associated with based upon the subdomain
  const getHospital = async (subdomain) => {
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/hospitals/find_by_subdomain/${subdomain}`,
      {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      },
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error(res);
        }
      })
      .then((json) => {
        setLoadingHospital(false);
        setWelcomeMessage(json.welcome_message);
        setLogo(`${subdomain}.webp`);
        localStorage.setItem("hospital", JSON.stringify(json.hospital.data));
      })
      .catch((err) => {
        setWelcomeMessage("Welcome to MyTeamToday!");
        setLogo("MyTEAM-new-logo.png");
      });
  };

  const onFinish = (values) => {
    // validate the form, then submit the form to the server
    // If the password and confirm password fields match, submit the form to the server
    // If the password and confirm password fields do not match, display an error message
    // If the password is not eight characters long, display an error message

    if (values.password !== values.confirm_password) {
      notification.error({
        message: "Passwords do not match",
        description: "Please check your passwords and try again.",
      });
      return;
    }

    if (values.password.length < 8) {
      notification.error({
        message: "Password must be at least 8 characters long",
        description: "Please check your password and try again.",
      });
      return;
    }

    createNewPassword({
      user: {
        password: values.password,
        confirm_password: values.confirm_password,
        token: token,
      },
    });
  };

  const onFinishFailed = (errorInfo) => {
    notification.error({
      message: "Failed to Login",
      description: "Please correct the errors and try again.",
      onClick: () => {
        console.log("Notification Clicked!");
      },
    });
  };

  useEffect(() => {
    getHospital(subdomain);
  }, []);

  return (
    <Layout>
      <div className="container mx-auto mb-4">
        <section className="login-container">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                {loadingHospital == false ? (
                  <Image
                    src={`/assets/images/hospitals/${logo}`}
                    preview={false}
                  />
                ) : null}
                <Image
                  style={{
                    backgroundColor: "black",
                    paddingTop: "20px",
                    marginTop: "15px",
                  }}
                  src="/assets/images/MyTEAM-new-logo.png"
                  preview={false}
                />
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Create a new password.
                </h3>
                <p className="mt-1 text-sm text-gray-600">
                  Create a new password for your account. Your password must be
                  at least 8 characters long.
                </p>
              </div>
            </div>
            <div className="mt-5 md:mt-0 md:col-span-2 text-right">
              <Form onFinish={onFinish} onFinishFailed={onFinishFailed}>
                <div className="shadow sm:rounded-md sm:overflow-hidden">
                  <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-3 sm:col-span-2">
                        <Form.Item
                          label="Password"
                          name="password"
                          rules={[
                            {
                              required: true,
                              message: "Please enter your password.",
                            },
                          ]}
                        >
                          <Input.Password visibilityToggle={true} />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-3 sm:col-span-2">
                        <Form.Item
                          label="Confirm Password"
                          name="confirm_password"
                          rules={[
                            {
                              required: true,
                              message: "Please confirm your password.",
                            },
                          ]}
                        >
                          <Input.Password visibilityToggle={true} />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                    <Form.Item>
                      <Button
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 rounded"
                        htmlType="submit"
                      >
                        Create Password
                      </Button>
                    </Form.Item>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
}
