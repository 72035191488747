import React from "react";
import {
  Button,
  Col,
  Descriptions,
  Divider,
  Form,
  Input,
  Row,
  Skeleton,
  Space,
} from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import editMetricStyles from "./editMetricStatus.module.css";
import { Content } from "antd/lib/layout/layout";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useUserStore } from "../../../../../context/UserStore";

export default function EditMetricStatus() {
  const { metricId } = useParams();
  const { TextArea } = Input;
  const { graphData, setGraphData } = useState([]);
  const [loadingMetric, setLoadingMetric] = useState(true);
  const [loadingMetricData, setLoadingMetricData] = useState(true);
  const [metric, setMetric] = useState({});
  const [metricData, setMetricData] = useState({});
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({});
  const user = useUserStore((state) => state.user);

  const capitalize = (str) => {
    return `${str[0].toUpperCase()}${str.slice(1)}`;
    // return str[0].toUpperCase() + str.slice(1)   // without template string
  };

  const getMetricData = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/users/metrics/${metricId}`,
      {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        type: "json",
      },
    )
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((response) => {
        // setGraphData(response.data.attributes.graph_data);
        setMetric(response.metric.data);
        setMetricData(response.metric.data.attributes.metric_data);
        setLoadingMetric(false);
        setLoadingMetricData(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Function to determine the lowest data point for the Y-axis
  const getMinY = (data) => {
    const values = data.map((item) => item.value);
    const goals = data.map((item) => item.goal_target);
    return Math.min(...values, ...goals);
  };

  const onMetricDataChange = (id, field, value) => {
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/users/metrics/${metricId}/metric_data/${id}`,
      {
        method: "put",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({
          metric_data: {
            id: `${id}`,
            field: `${field}`,
            value: `${value}`,
          },
        }),
        type: "json",
      },
    )
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((response) => {
        setMetric(response.metric.data);
        setMetricData(response.metric.data.attributes.metric_data);
        setLoadingMetric(false);
        setLoadingMetricData(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getMetricData();
  }, []);

  return (
    <>
      <div
        className={`py-5 grid grid-cols-1 mb-5 ${editMetricStyles.headerBar}`}
      >
        <h1 className="text-4xl text-center text-white">Metric Status</h1>
      </div>
      <Content>
        <Skeleton loading={loadingMetric} active>
          {metric && metric.attributes && (
            <>
              <div className="bg-white shadow">
                <div className="px-4 py-5 sm:px-6 flex justify-between items-center">
                  <div className="flex items-center space-x-2">
                    <button
                      onClick={() => navigate(-1)}
                      className="text-gray-600 hover:text-gray-900"
                    >
                      {/* Here you can insert an SVG or icon for back navigation */}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15 19l-7-7 7-7"
                        />
                      </svg>
                    </button>
                    <div>
                      <h3 className="text-lg leading-6 font-medium text-gray-900">
                        {metric.attributes.name}
                      </h3>
                      <p className="mt-1 max-w-2xl text-sm text-gray-500">
                        {metric.attributes.goal_name}
                      </p>
                    </div>
                  </div>
                  <button
                    onClick={() =>
                      navigate(
                        `/align/monitoring-results/metrics/${metricId}/edit`,
                      )
                    }
                    className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-orange-500 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                  >
                    Edit Metric Properties
                  </button>
                </div>
                <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                  <dl className="sm:divide-y sm:divide-gray-200">
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                      <dt className="text-sm font-medium text-gray-500">
                        Unit
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {metric.attributes.unit.name}
                      </dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                      <dt className="text-sm font-medium text-gray-500">
                        Goal Year
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {metric.attributes.metric_year}
                      </dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                      <dt className="text-sm font-medium text-gray-500">
                        Area
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {metric.attributes.area_type}
                      </dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                      <dt className="text-sm font-medium text-gray-500">
                        Measurement Type
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {metric.attributes.measurement_type}
                      </dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                      <dt className="text-sm font-medium text-gray-500">
                        Metric Interval
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {metric.attributes.measurement_interval}
                      </dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                      <dt className="text-sm font-medium text-gray-500">
                        Creation Date
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {metric.attributes.friendly_created_at}
                      </dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                      <dt className="text-sm font-medium text-gray-500">
                        Description
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {metric.attributes.description}
                      </dd>
                    </div>
                    {/* ...other items */}
                  </dl>
                </div>
              </div>
              <ResponsiveContainer width="100%" height={400}>
                <AreaChart
                  data={metric.attributes.graph_data}
                  margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis
                    domain={[getMinY(metric.attributes.graph_data), "auto"]}
                  />
                  <Tooltip />
                  <Legend />
                  <Area
                    type="monotone"
                    dataKey="goal_target"
                    activeDot={{ stroke: "#888", strokeWidth: 2, r: 2 }}
                    dot={{ stroke: "#888", strokeWidth: 2 }}
                    stackId="2"
                    strokeWidth="3"
                    stroke="#ffc658"
                    fill="#ffc65860"
                  />
                  <Area
                    type="monotone"
                    dataKey="value"
                    activeDot={{ stroke: "#82ca9d", strokeWidth: 2, r: 2 }}
                    dot={{ stroke: "#82ca9d", strokeWidth: 2 }}
                    stackId="1"
                    strokeWidth="3"
                    stroke="#82ca9d"
                    fill="#82ca9d60"
                  />
                </AreaChart>
              </ResponsiveContainer>
              <div className="container mx-auto px-4 sm:px-6 lg:px-8">
                <Form form={form} layout="vertical" className="w-full">
                  <div className="flex flex-wrap -mx-2">
                    {metricData &&
                      metricData.length > 0 &&
                      metricData.map((data, index) => {
                        return (
                          <div
                            className="px-2 mb-4 flex flex-col w-full sm:w-1/2 md:w-1/3 lg:w-1/4"
                            key={`metric-data-${index}`}
                          >
                            <h3 className="text-center text-xs font-bold">
                              {capitalize(data.simple_date)}
                            </h3>
                            <Form.Item
                              label="Goal Target"
                              name={`${data.id}-goal_target`}
                              initialValue={data.goal_target}
                            >
                              <Input
                                className="w-full"
                                onChange={(e) => {
                                  onMetricDataChange(
                                    data.id,
                                    "goal_target",
                                    e.target.value,
                                  );
                                }}
                              />
                            </Form.Item>
                            <Form.Item
                              label="Actual"
                              name={`${data.id}-value`}
                              initialValue={data.value}
                            >
                              <Input
                                className="w-full"
                                onChange={(e) => {
                                  onMetricDataChange(
                                    data.id,
                                    "value",
                                    e.target.value,
                                  );
                                }}
                              />
                            </Form.Item>
                          </div>
                        );
                      })}
                  </div>
                </Form>
              </div>
            </>
          )}
        </Skeleton>
      </Content>
    </>
  );
}
