import React from 'react'
import { Button, Col, Form, notification, Row, Skeleton, Space, Tag } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import adminUserStyles from './AdminUser.module.css';
import { useEffect, useState } from 'react';
import { useUserStore } from '../../../../context/UserStore';
import { useNavigate, useParams } from 'react-router-dom';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

export default function AdminUser() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [hospitals, setHospitals] = useState([]);
    const [adminUser, setAdminUser] = React.useState([]);
    const [initialValues, setInitialValues] = React.useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingHospitals, setIsLoadingHospitals] = useState(true);
    const [isLoadingRoles, setIsLoadingRoles] = useState(true);
    const [roles, setRoles] = React.useState([]);
    const [units, setUnits] = React.useState([]);
    const user = useUserStore((state) => state.user);


    const loadHospitals = async () => {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/v1/admin/hospitals`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                "Authorization": `Bearer ${user.token}`,
            },
            type: 'json',
        }).then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                throw new Error(response.statusText);
            }
        })
            .then(response => {
                const newData = response.hospitals.data.map((item) => {
                    return {
                        label: item.attributes.name,
                        value: item.attributes.id,
                        key: item.id,
                    }
                })
                setHospitals(newData);
                setIsLoadingHospitals(false);
            })
            .catch(error => {
                console.log(error);
            })
    };
    const loadRoles = async () => {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/v1/admin/roles`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                "Authorization": `Bearer ${user.token}`,
            },
            type: 'json',
        }).then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                throw new Error(response.statusText);
            }
        })
            .then(response => {
                const newData = response.roles.data.map((item) => {
                    return {
                        label: item.attributes.name,
                        value: item.attributes.id,
                        key: item.id,
                    }
                })
                setRoles(newData);
                setIsLoadingHospitals(false);
            })
            .catch(error => {
                console.log(error);
            })
    };
    const loadUser = async () => {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/v1/admin/users/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                "Authorization": `Bearer ${user.token}`,
            },
            type: 'json',
        }).then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                throw new Error(response.statusText);
            }
        })
            .then(response => {
                setAdminUser(response.user.data);
                setInitialValues(response.user.data.attributes);
                setUnits(response.user.included.filter(item => item.type === 'unit'))
                setIsLoading(false);
            })
            .catch(error => {
                console.log(error);
            })
    };

    const disableUser = async (id) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/admin/users/${id}/disable`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                "Authorization": `Bearer ${user.token}`,
            },
            type: 'json',
        }).then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                throw new Error(response.statusText);
            }
        }).then(response => {
            notification.success({
                message: response.message,
                description: response.message,
            });
        }).catch(error => {
            notification.error({
                message: error.errors,
            });
            console.log(error);
        })
    };

    useEffect(() => {
        loadUser();
        loadHospitals();
        loadRoles();
    }, []);

    return (
        <>
            <div className={`py-5 grid grid-cols-1 mb-5 ${adminUserStyles.headerBar}`}>
                <h1 className='text-4xl text-center text-white'>View User</h1>
            </div>
            <Skeleton loading={isLoading} active>
                {(isLoading == false) && (
                    <>
                        <PageHeader
                            onBack={() => navigate(-1)}
                            ghost={false}
                            title={`${adminUser.attributes.full_name}`}
                            extra={[
                                <Space size='small'>
                                    <Button
                                        className='bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 rounded'
                                        key='back'
                                        onClick={() => navigate('/admin/users')}>
                                        Back
                                    </Button>
                                    <Button
                                        className='bg-orange-500 hover:bg-orange-700 font-bold rounded'
                                        key='edit'
                                        onClick={() => navigate(`/admin/users/${id}/edit`)}>
                                        <EditOutlined />
                                    </Button>
                                    <Button
                                        className='bg-red-500 hover:bg-red-700 font-bold rounded'
                                        key='delete'
                                        onClick={() => disableUser(id)}>
                                        <DeleteOutlined />
                                    </Button>
                                </Space>
                            ]}></PageHeader>
                        <Row>
                            <Col span={20}>
                                <Form
                                    layout='vertical'
                                    initialValues={initialValues}
                                >
                                    <Row>
                                        <Col md={10} xs={20}>
                                            <Form.Item label='Role'>
                                                <Form.Item rules={[{ required: true, message: 'Please choose a Role' }]}>
                                                    {adminUser.attributes.role}
                                                </Form.Item>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row justify='space-between'>
                                        <Col md={10} xs={20}>
                                            <Form.Item label='Hospital'>
                                                <Form.Item rules={[{ required: false, message: 'Please choose a Hospital' }]}>
                                                    {adminUser.attributes.hospital_name}
                                                </Form.Item>
                                            </Form.Item>
                                        </Col>
                                        <Col md={10} xs={20}>
                                            <Form.Item label='Units'>
                                                <Form.Item rules={[{ required: false, message: 'Please choose a Hospital' }]}>
                                                    {units.map(item => (
                                                        <Tag color="blue" key={item.id}>{item.attributes.name}</Tag>
                                                    ))}
                                                </Form.Item>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row justify='space-between'>
                                        <Col md={10} xs={20}>
                                            <Form.Item label='First Name'>
                                                <Form.Item rules={[{ required: true, message: 'Input a First Name' }]}>
                                                    {adminUser.attributes.first_name}
                                                </Form.Item>
                                            </Form.Item>

                                        </Col>
                                        <Col md={10} xs={20}>
                                            <Form.Item label='Last Name'>
                                                <Form.Item rules={[{ required: true, message: 'Select a Last Name' }]}>
                                                    {adminUser.attributes.last_name}
                                                </Form.Item>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row justify='space-between'>
                                        <Col md={10} xs={20}>
                                            <Form.Item label='Job Title'>
                                                <Form.Item rules={[{ required: true, message: 'Input a First Name' }]}>
                                                    {adminUser.attributes.job_title}
                                                </Form.Item>
                                            </Form.Item>

                                        </Col>
                                        <Col md={10} xs={20}>
                                            <Form.Item label='Employee ID'>
                                                <Form.Item rules={[{ required: false, message: 'Select a Last Name' }]}>
                                                    {adminUser.attributes.employee_id}
                                                </Form.Item>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row justify='space-between'>
                                        <Col md={10} xs={20}>
                                            <Form.Item label='Email'>
                                                <Form.Item rules={[{ required: true, message: 'Input an Email Address' }]}>
                                                    {adminUser.attributes.email}
                                                </Form.Item>
                                            </Form.Item>

                                        </Col>
                                        <Col md={10} xs={20}>

                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    </>
                )}
            </Skeleton>
        </>
    )
}
