import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

export const useUserStore = create(
    persist(
        (set, get) => ({
            user: {
                exp: '',
                token: '',
                username: '',
                user_role: ''
            },
            me: {
                attributes: {}
            },
            addAFish: () => set({ fishes: get().fishes + 1 }),
            setMe: (payload) => set({ me: payload }),
            setUser: (payload) => set({ user: payload }),
            getUser: () => get().user,
        }),
        {
            name: 'UserObject', // unique name
            storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
        }
    )
)