import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form, Input, Layout, notification } from "antd";
import { userStore } from "../Providers/UserStore";
import { addUser } from "../Providers/UserReducer";
import { Link } from "react-router-dom";
import "./CreateAccount.css";

export default function CreateAccount() {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const navigate = useNavigate();
  const [, dispatch] = userStore();
  const loginUser = async (credentials) => {
    return fetch(`${process.env.REACT_APP_API_URL}/api/v1/users`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(credentials),
    })
      .then((res) => {
        if (res.ok) {
          localStorage.setItem("access-token", res.headers.get("access-token"));
          localStorage.setItem("uid", res.headers.get("uid"));
          localStorage.setItem("client", res.headers.get("client"));
          return res.json();
        } else {
          return res.text().then((text) => {
            throw new Error(text);
          });
        }
      })
      .then((json) => {
        dispatch(addUser(json));
        switch (json["user"]["user_role"]) {
          case "superadmin":
            navigate("/admin/admin-reviews");
            break;
          case "admin":
            navigate("/admin");
            break;
          default:
            navigate("/companies");
            break;
        }
        window.location.reload(false);
      })
      .catch((error) => {
        notification.error({
          message: "Error: Please try again.",
          description:
            "Registration failed. Please confirm your passwords match and are at least 8 characters.",
        });
      });
  };

  const onFinish = (values) => {
    loginUser({
      user: {
        first_name: values.first_name,
        last_name: values.last_name,
        username: values.first_name + "_" + values.last_name,
        email: values.email,
        password: values.password,
        password_confirmation: values.password_confirmation,
      },
    });
  };

  const onFinishFailed = (errorInfo) => {
    notification.error({
      message: "Failed to Update",
      description: "Please correct the errors and try again.",
      onClick: () => {
        console.log("Notification Clicked!");
      },
    });
  };

  return (
    <Layout>
      <div className="container mx-auto mb-4">
        <section className="login-container">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Create an Account.
                </h3>
                <p className="mt-1 text-sm text-gray-600">
                  It's easy to create an account!
                </p>
              </div>
            </div>
            <div className="mt-5 md:mt-0 md:col-span-2">
              <Form onFinish={onFinish} onFinishFailed={onFinishFailed}>
                <div className="shadow sm:rounded-md sm:overflow-hidden">
                  <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-3 sm:col-span-2">
                        <Form.Item
                          label="First Name"
                          name="first_name"
                          rules={[
                            {
                              required: true,
                              message: "Please enter your first name.",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-3 sm:col-span-2">
                        <Form.Item
                          label="Last Name"
                          name="last_name"
                          rules={[
                            {
                              required: true,
                              message: "Please enter your last name.",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-3 sm:col-span-2">
                        <Form.Item
                          label="Email Address"
                          name="email"
                          rules={[
                            {
                              required: true,
                              message: "Please enter your email address.",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-3 sm:col-span-2">
                        <Form.Item
                          label="Password"
                          name="password"
                          rules={[
                            {
                              required: true,
                              message: "Please enter your password.",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-3 sm:col-span-2">
                        <Form.Item
                          label="Confirm Password"
                          name="password_confirmation"
                          rules={[
                            {
                              required: true,
                              message: "Confirm password.",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                    <Form.Item>
                      <Button
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 rounded"
                        htmlType="submit"
                        // className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        Save
                      </Button>
                    </Form.Item>
                  </div>
                </div>
              </Form>
            </div>
            <div className="mt-5 md:mt-0 md:col-span-2">
              <p>
                Do you already have an account? You can{" "}
                <Link to={`/login`}>login in here.</Link>
              </p>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
}
