import React from 'react';
import { Button, Descriptions, notification, Row, Skeleton, Space, } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import questionStyles from './Question.module.css';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useUserStore } from '../../../../context/UserStore';
import dayjs from 'dayjs';

export default function Question() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [interactionType, setInteractionType] = React.useState(null);
    const [question, setQuestion] = React.useState(null);
    const [roundType, setRoundType] = React.useState(null);
    const [isLoadingQuestion, setIsLoadingQuestion] = useState(true);
    const user = useUserStore((state) => state.user);

    const disableQuestion = async (id) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/admin/questions/${id}/disable`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                "Authorization": `Bearer ${user.token}`,
            },
            type: 'json',
        }).then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                throw new Error(response.statusText);
            }
        }).then(response => {
            notification.success({
                message: response.message,
                description: response.message,
            });
        }).catch(error => {
            notification.error({
                message: error.errors,
            });
            console.log(error);
        })
    };

    const loadQuestion = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/admin/questions/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            },
            type: 'json',
        }).then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                throw new Error(response.statusText);
            }
        }).then(response => {
            setInteractionType(response.question.included.find(item => item.type === 'interaction_type'));
            setQuestion(response.question.data);
            setRoundType(response.question.included.find(item => item.type === 'round_type'));
            setIsLoadingQuestion(false);
        }).catch(error => {
            console.log(error);
        })
    };

    useEffect(() => {
        loadQuestion();
    }, []);

    return (
        <>
            <div className={`py-5 grid grid-cols-1 mb-5 ${questionStyles.headerBar}`}>
                <h1 className='text-4xl text-center text-white'>View Question</h1>
            </div>
            <Skeleton loading={isLoadingQuestion} active>
                {(question && roundType) && (
                    <>
                        <PageHeader
                            onBack={() => navigate(-1)}
                            ghost={false}
                            title={`${question.attributes.text}`}
                            extra={[
                                <Space size='small'>
                                    <Button
                                        className='bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 rounded'
                                        key='back'
                                        onClick={() => navigate('/admin/questions')}>
                                        Back
                                    </Button>
                                    <Button
                                        className='bg-orange-500 hover:bg-orange-700 font-bold rounded'
                                        key='edit'
                                        onClick={() => navigate(`/admin/questions/${id}/edit`)}>
                                        <EditOutlined />
                                    </Button>
                                    <Button
                                        className='bg-red-500 hover:bg-red-700 font-bold rounded'
                                        key='delete'
                                        onClick={() => disableQuestion(id)}>
                                        <DeleteOutlined />
                                    </Button>
                                </Space>
                            ]}
                        ></PageHeader>
                        <Descriptions bordered column={{ xxl: 4, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}>
                            <Descriptions.Item label='Question Text'>
                                {question.attributes.text}
                            </Descriptions.Item>
                            <Descriptions.Item label='Created At'>
                                {dayjs(question.attributes.created_at).format('DD/MM/YYYY')}
                            </Descriptions.Item>
                            <Descriptions.Item label='Before Help Content'>
                                {question.attributes.help_before}
                            </Descriptions.Item>
                            <Descriptions.Item label='After Help Content'>
                                {question.attributes.help_after}
                            </Descriptions.Item>
                            <Descriptions.Item label='Round Type'>
                                {question.attributes.round_type_text}
                            </Descriptions.Item>
                            <Descriptions.Item label='Perspective'>
                                {question.attributes.perspective_text}
                            </Descriptions.Item>
                            <Descriptions.Item label='Question Type'>
                                {question.attributes.question_type_text}
                            </Descriptions.Item>
                            <Descriptions.Item label='Status'>
                                {question.attributes.active}
                            </Descriptions.Item>
                            <Descriptions.Item label='Page'>
                                {question.attributes.page_number}
                            </Descriptions.Item>
                            <Descriptions.Item label='Order'>
                                {question.attributes.order}
                            </Descriptions.Item>
                        </Descriptions>
                    </>
                )}
            </Skeleton>
        </>
    )
}
