import React from "react";
import { Button, Descriptions, Space } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { useNavigate, useParams } from "react-router-dom";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Content } from "antd/lib/layout/layout";
import {
  DeleteOutlined,
  EditOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import { useUserStore } from "../../../../../context/UserStore";

export default function AdminSmallMetric({ metric, removeMetric }) {
  const navigate = useNavigate();
  const user = useUserStore((state) => state.user);

  const deleteMetric = (id) => {
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/users/metrics/${id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        type: "json",
      },
    ).then((response) => {
      if (response.ok) {
        removeMetric(id);
        return response.json();
      } else {
        throw new Error(response.statusText);
      }
    });
  };

  // Function to determine the lowest data point for the Y-axis
  const getMinY = (data) => {
    const values = data.map((item) => item.value);
    const goals = data.map((item) => item.goal_target);
    return Math.min(...values, ...goals);
  };

  return (
    <>
      <Content>
        <PageHeader
          ghost={false}
          title={`${metric.attributes.name} [${metric.attributes.unit.name}]`}
          extra={[
            <Space size="small">
              <Button
                key="1"
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 rounded"
                onClick={() =>
                  navigate(
                    `/admin/monitoring-results/metrics/${metric.id}/status`,
                  )
                }
              >
                Status Update
              </Button>
              <Button
                key="2"
                className="bg-orange-500 hover:bg-orange-700 font-bold rounded"
                icon={<EditOutlined />}
                onClick={() =>
                  navigate(
                    `/admin/monitoring-results/metrics/${metric.id}/edit`,
                  )
                }
              />
              <Button
                key="3"
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 rounded"
                icon={<PrinterOutlined />}
                onClick={() =>
                  navigate(`/admin/monitoring-results/metrics/${metric.id}`)
                }
              >
                View/Print
              </Button>
              <Button
                key="4"
                className="bg-red-500 hover:bg-red-700 font-bold rounded"
                icon={<DeleteOutlined />}
                onClick={() => deleteMetric(metric.id)}
              />
            </Space>,
          ]}
        >
          <Descriptions size="small" column={3} bordered>
            <Descriptions.Item label="Goal Year">
              <a>
                {metric.attributes.metric_year} - {metric.attributes.goal_name}
              </a>
            </Descriptions.Item>
          </Descriptions>
        </PageHeader>
        <ResponsiveContainer width="100%" height={400}>
          <AreaChart
            data={metric.attributes.graph_data}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis domain={[getMinY(metric.attributes.graph_data), "auto"]} />
            <Tooltip />
            <Legend />
            <Area
              type="monotone"
              dataKey="goal_target"
              activeDot={{ stroke: "#888", strokeWidth: 2, r: 2 }}
              dot={{ stroke: "#888", strokeWidth: 2 }}
              stackId="2"
              strokeWidth="3"
              stroke="#ffc658"
              fill="#ffc65860"
            />
            <Area
              type="monotone"
              dataKey="value"
              activeDot={{ stroke: "#82ca9d", strokeWidth: 2, r: 2 }}
              dot={{ stroke: "#82ca9d", strokeWidth: 2 }}
              stackId="1"
              strokeWidth="3"
              stroke="#82ca9d"
              fill="#82ca9d60"
            />
          </AreaChart>
        </ResponsiveContainer>
      </Content>
    </>
  );
}
