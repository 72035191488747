import React from 'react';
import { Button, Checkbox, Form, Input, InputNumber, message, notification, Radio, Select } from 'antd';
import { useNavigate } from 'react-router-dom';
import newEmployeesStyles from './NewMyEmployee.module.css';
import { useUserStore } from '../../../context/UserStore';

const { useState, useEffect } = React;

export default function NewMyEmployee() {
    const navigate = useNavigate();
    const [employee, setEmployee] = useState({});
    const [initialValues, setInitialValues] = React.useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [units, setUnits] = useState([]);
    const user = useUserStore((state) => state.user);
    const me = useUserStore((state) => state.me);
    const { TextArea } = Input;

    const createEmployee = async (values) => {
        setIsLoading(true);
        return fetch(`${process.env.REACT_APP_API_URL}/api/v1/users/employees`, {
            method: "POST",
            headers: {
                "Accept": "application/json",
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            },
            body: JSON.stringify(values),
            type: 'json'
        }).then(response => {
            if (!response.ok) {
                setIsLoading(false);
                return;
            }
            setIsLoading(false);
            notification.success({
                message: 'Employee successfully created',
            })
            navigate('/my-employees');
        }).catch(error => {
            setIsLoading(false);
            console.log(error);
        });
    };

    const customFetch = async (page, pageSize) => {
        setIsLoading(true);
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/v1/admin/hospitals/${me.attributes.hospital_id}/unit_manager_options`, {
            method: "get",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
            },
            type: "json"
        }).then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        }).then(response => {
            const newData = response.unit_manager_options.map((item) => {
                return {
                    label: item.label,
                    value: item.value,
                    key: item.key,
                }
            })

            setUnits(newData);
            setIsLoading(false);
            setInitialValues({
                needs_attention: 30,
            });
        }).catch(error => {
            console.log(error)
        });
    };

    const onFinish = (values) => {
        createEmployee(
            {
                "employee": {
                    first_name: values.first_name,
                    last_name: values.last_name,
                    email: values.email,
                    job_title: values.job_title,
                    employee_id: values.employee_id,
                    needs_attention: values.needs_attention,
                    notes: values.notes,
                    units: values.units,
                }
            });
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    useEffect(() => {
        customFetch({});
    }, []);


    return (
        <>
            <div className={`py-5 grid grid-cols-1 mb-5 ${newEmployeesStyles.headerBar}`}>
                <h1 className='text-4xl text-center text-white'>Add A New Employee</h1>
            </div>
            <div className='grid grid-cols-6 gap-4'>
                <div className='col-span-6'>
                    <Form
                        initialValues={initialValues}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        layout='vertical'
                    >
                        <div className="shadow sm:rounded-md sm:overflow-hidden">
                            <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                                <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-12 sm:col-span-2">
                                        <Form.Item
                                            label="First Name"
                                            name="first_name"
                                            rules={[{ required: true, message: "Please input the Employee's first name!" }]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                            <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                                <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-12 sm:col-span-2">
                                        <Form.Item
                                            label="Last Name"
                                            name="last_name"
                                            rules={[{ required: true, message: "Please input the Emplyee's last name!" }]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                            <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                                <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-12 sm:col-span-2">
                                        <Form.Item label='Job Title'>
                                            <Form.Item name='job_title' rules={[{ required: true, message: 'Input a First Name' }]}>
                                                <Input />
                                            </Form.Item>
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                            <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                                <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-12 sm:col-span-2">
                                        <Form.Item
                                            label="Email"
                                            name="email"
                                            rules={[{ required: true, message: "Please input the Employee's email!" }]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                            <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                                <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-12 sm:col-span-2">
                                        <Form.Item
                                            label="Employee Number"
                                            name="employee_id"
                                            rules={[{ required: true, message: "Please input the Employee Number." }]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                            <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                                <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-12 sm:col-span-2">
                                        <Form.Item
                                            initialValue={30}
                                            label="Needs Attention in X Days"
                                            name="needs_attention"
                                            rules={[{ required: false, message: "Needs attention in X Days." }]}
                                        >
                                            <InputNumber />
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                            <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                                <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-12 sm:col-span-2">
                                        <Form.Item
                                            label="Units"
                                            name="units"
                                            rules={[{ required: true, message: "Please select the Employee's units." }]}
                                        >
                                            <Checkbox.Group options={units} />
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                            <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                                <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-12 sm:col-span-2">
                                        <Form.Item
                                            label="Notes"
                                            name="notes"
                                            rules={[{ required: false, message: "Please enter some notes." }]}
                                        >
                                            <TextArea rows={4} />
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                            <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                                <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-12 sm:col-span-2">
                                        <Form.Item
                                            wrapperCol={{
                                                offset: 8,
                                                span: 16,
                                            }}
                                        >
                                            <Button
                                                className='bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 rounded'
                                                htmlType="submit"
                                            >
                                                Submit
                                            </Button>
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </>
    )
}
