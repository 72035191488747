import React from "react";
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import { ConfigProvider } from 'antd';
import "./index.css";
import { CookiesProvider } from "react-cookie";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from "@apollo/client";
import { UserStoreProvider } from './components/Providers/UserStore';
import { initialUserState, userReducer } from "./components/Providers/UserReducer";

import { setContext } from '@apollo/client/link/context';

import App from "./App";
import { Honeybadger, HoneybadgerErrorBoundary } from '@honeybadger-io/react';

const rootElement = ReactDOM.createRoot(document.getElementById('root'));

const httpLink = createHttpLink({
  uri: 'https://client-backend.ngrok.io/graphql',
});

const config = {
  apiKey: 'hbp_wV34xtzWcEcrrzR7RFyZg36ZvPzCpR1gSxRt',
  environment: 'production',
  revision: 'git SHA/project version'
}

const honeybadger = Honeybadger.configure(config)

const getAuthtoken = () => {
  const cookie = document.cookie.split(';').find(c => c.trim().startsWith('authToken='));
  return cookie ? decodeURI(cookie.split('=')[1]) : null;
}

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  // const token = localStorage.getItem('authToken');
  const token = getAuthtoken();
  // return the headers to the context so httpLink can read them
  const authorization = token ? `authorization:  ${token}` : null;
  return {
    headers: {
      ...headers,
      ...(token ? { authorization: `${token}` } : {})
    }
  }
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});

rootElement.render(
  <React.StrictMode>
    <HoneybadgerErrorBoundary honeybadger={honeybadger}>
      <ApolloProvider client={client}>
        <BrowserRouter>
          <CookiesProvider>
            <UserStoreProvider initialState={initialUserState} reducer={userReducer}>
              <ConfigProvider>
                <App />
              </ConfigProvider>
            </UserStoreProvider>
          </CookiesProvider>
        </BrowserRouter>
      </ApolloProvider>
    </HoneybadgerErrorBoundary>
  </React.StrictMode>,
);
