import React from "react";
import {
  Button,
  Input, Row,
  Space,
  Table,
  Typography,
} from "antd";
import {
  SearchOutlined,
  CheckOutlined,
  DeleteOutlined,
  FileTextOutlined,
  EditOutlined,
  UserOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import myEmployeesStyles from "./MyEmployees.module.css";
import { useUserStore } from "../../../context/UserStore";
import mentorReviewStyles from "../Review/mentorReview.module.css";

const { Text, Title } = Typography;

const { useState, useEffect } = React;

export default function MyEmployees() {
  const navigate = useNavigate();
  const [employees, setEmployees] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [order, setOrder] = useState("asc");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [sort, setSort] = useState("id");
  const [total, setTotal] = useState(1);
  const user = useUserStore((state) => state.user);
  const me = useUserStore((state) => state.me);

  const toggleActive = async (id) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/users/employees/${id}/toggle_active`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user.token}`,
          "Content-Type": "application/json",
        },
        type: "json",
      },
    );
    if (!response.ok) {
      setIsLoading(false);
      return;
    }

    const data = await response.json();

    // Replace the employee with the updated employee
    // Set the employees state to the new data
    const newData = employees.map((item) => {
      if (item.id === data.employee.data.id) {
        return {
          ...item,
          ...data.employee.data,
        };
      } else {
        return item;
      }
    });
    setEmployees(newData);
  };

  const customFetch = async (page, pageSize) => {
    typeof page === "object" || typeof page === "undefined"
      ? (page = 1)
      : (page = page);
    typeof pageSize === "undefined" ? (pageSize = 25) : (pageSize = pageSize);
    setIsLoading(true);
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/users/employees/list?page=${page}&per_page=${pageSize}` +
        `&sort=${sort}&order=${order}`,
      {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        type: "json",
      },
    )
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((response) => {
        const newData = response.employees.data.map((item) => {
          return {
            ...item,
            key: item.id,
          };
        });

        setEmployees(newData);
        setTotal(response.total);
        setPage(response.current_page);
        setPageSize(pageSize);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "attributes.full_name",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <div style={{ padding: 8 }}>
            <Input
              autoFocus
              placeholder="Search"
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
              value={selectedKeys}
              style={{ marginBottom: 8, display: "block" }}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropDown: false });
              }}
            />
            <Space space="small">
              <Button
                onClick={() => {
                  confirm(true);
                }}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 rounded"
                icon={<SearchOutlined />}
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button
                onClick={() => {
                  clearFilters({confirm: true});
                }}
                className="bg-orange-500 hover:bg-orange-700 font-bold rounded"
              >
                Reset
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) =>
        record.attributes.full_name.toLowerCase().includes(value.toLowerCase()),
      render: (id, record) => {
        return `${record.attributes.full_name}`;
      },
      width: "10%"
    },
    {
      title: "Last Updated",
      dataIndex: "attributes.updated_at",
      sorter: (a, b) => (a.updated_at > b.updated_at ? 1 : -1),
      render: (id, record) => {
        return `${dayjs(record.attributes.updated_at, "YYYY-MM-DD").format("MM/DD/YYYY")}`;
      },
      width: "5%",
    },
    {
      title: "Action",
      dataIndex: "id",
      render: (id, record) => {
        return (
          <>
            <div className="grid grid-cols-2 gap-1 sm:flex sm:gap-1 sm:flex-wrap sm:flex-nowrap">
              <div className="flex flex-col gap-1 sm:flex-row">
                <Button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold px-2 rounded"
                  onClick={() => navigate(`/my-employees/${record.id}/coaching`)}
                >
                  <FileTextOutlined />
                  Interactions
                </Button>
                <Button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold px-2 rounded"
                  onClick={() => navigate(`/my-employee/${record.id}`)}
                >
                  <UserOutlined />
                  Profile
                </Button>
              </div>
              <div className="flex flex-col gap-1 sm:flex-row">
                <Button
                  className="bg-orange-500 hover:bg-orange-700 font-bold rounded"
                  onClick={() => navigate(`/my-employee/${record.id}/edit`)}
                >
                  <EditOutlined />
                </Button>
                {record.attributes.active ? (
                  <Button
                    className="bg-red-500 hover:bg-red-700 font-bold rounded"
                    onClick={() => toggleActive(record.id)}
                  >
                    <DeleteOutlined />
                  </Button>
                ) : (
                  <Button
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold rounded"
                    onClick={() => toggleActive(record.id)}
                  >
                    <CheckOutlined />
                  </Button>
                )}
              </div>
            </div>
          </>
        );
      },
      width: "22%",
    },
    {
      title: "Id",
      dataIndex: "id",
      sorter: (a, b) => (a.id > b.id ? 1 : -1),
      render: (id) => `${id}`,
      width: "10%",
      responsive: ['md'],
    },
    {
      title: "Active",
      dataIndex: "attributes.active",
      filters: [
        {
          text: "Active",
          value: true,
        },
        {
          text: "Inactive",
          value: false,
        },
      ],
      onFilter: (value, record) => record.attributes.active === value,
      defaultFilteredValue: ["true"],
      render: (id, record) => {
        return record.attributes.active ? (
            <Text type="success">Active</Text>
        ) : (
            <Text type="danger">Inactive</Text>
        );
      },
      width: "5%",
      responsive: ['md'],
    },
    {
      title: "Hospital",
      dataIndex: "attributes.hospital_name",
      render: (id, record) => {
        return `${record.attributes.hospital_name}`;
      },
      width: "15%",
      responsive: ['md'],
    },
  ];

  useEffect(() => {
    customFetch({});
  }, []);

  return (
    <>
      <div
        className={`py-8 mb-10 flex items-center justify-center rounded-lg shadow-lg ${myEmployeesStyles.headerBar}`}
        style={{ background: 'linear-gradient(135deg, #2485B2 0%, #70AEDD 100%)', color: '#fff' }}
      >
        <h1 className="text-4xl md:text-4xl text-center font-bold text-white tracking-wide">My Employees</h1>
      </div>
      <div className="flex flex-col items-center md:items-start">
        <div className="w-full">
          <div className="bg-white rounded-lg p-0">
            <div className="flex justify-center md:justify-start space-x-2 mb-4">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 py-2 rounded"
                onClick={() => navigate("/my-employees/new")}
              >
                <svg
                  className="w-4 h-4 inline-block mr-2"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                Add New Employee
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="oveflow-x-auto">
        <Table
          columns={columns}
          dataSource={employees}
          loading={isLoading}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          pagination={{
            current: page,
            pageSize: pageSize,
            total: total,
            showSizeChanger: true,
            showQuickJumper: true,
            onChange: (page, pageSize) => {
              customFetch(page, pageSize);
            },
          }}
          rowKey="id"
          scroll={{ x: 'max-content' }}
        />
      </div>
    </>
  );
}
