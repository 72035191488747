import React from 'react';
import unitsStyles from './Units.module.css';
import { Button, Card, Col, Input, notification, Row, Skeleton, Space, Table, } from 'antd';
import { useEffect, useState } from 'react';
import { useUserStore } from '../../../context/UserStore';
import { useNavigate } from 'react-router-dom';
import { SearchOutlined, EditOutlined, CheckOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

export default function Units() {
    const navigate = useNavigate();
    const [units, setUnits] = React.useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const me = useUserStore((state) => state.me);
    const user = useUserStore((state) => state.user);

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
                return (
                    <div style={{ padding: 8 }}>
                        <Input autoFocus
                            placeholder="Search"
                            onPressEnter={() => { confirm() }}
                            onBlur={() => { confirm() }}
                            value={selectedKeys}
                            style={{ marginBottom: 8, display: 'block' }}
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({ closeDropDown: false });
                            }
                            } />
                        <Space space='small'>
                            <Button
                                onClick={() => { confirm() }}
                                className='bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 rounded'
                                icon={<SearchOutlined />}
                                style={{ width: 90 }}>
                                Search
                            </Button>
                            <Button
                                onClick={() => { clearFilters() }}

                                className='bg-orange-500 hover:bg-orange-700 font-bold rounded'>
                                Reset
                            </Button>
                        </Space>
                    </div>
                )
            },
            filterIcon: () => {
                return <SearchOutlined />;
            },
            onFilter: (value, record) => record.attributes.name.toLowerCase().includes(value.toLowerCase()),
            render: (id, record) => {
                return (
                    record.attributes.name
                )
            }
        },
        {
            title: 'Last Updated',
            dataIndex: 'updated_at',
            key: 'updated',
            render: (id, record) => {
                return `${dayjs(record.attributes.updated_at).format('DD/MM/YYYY')}`;
            }
        },
        {
            title: 'Action',
            key: 'action',
            render: (id, record) => (
                <Space size="small">
                    <Button className='bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 rounded' onClick={() => navigate(`/admin/units/${record.id}`)}>View</Button>
                    <Button className='bg-orange-500 hover:bg-orange-700 font-bold rounded' onClick={() => navigate(`/admin/units/${record.id}/edit`)}>
                        <EditOutlined />
                    </Button>
                    <Button className='bg-red-500 hover:bg-red-700 font-bold rounded' onClick={() => disableUnit(record.id)}>
                        <DeleteOutlined />
                    </Button>
                </Space>
            ),
        },
    ];

    const disableUnit = async (id) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/admin/hospitals/${me.attributes.hospital_id}/units/${id}/disable`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                "Authorization": `Bearer ${user.token}`,
            },
            type: 'json',
        }).then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                throw new Error(response.statusText);
            }
        }).then(response => {
            notification.success({
                message: response.message,
                description: response.message,
            });
        }).catch(error => {
            notification.error({
                message: error.errors,
            });
            console.log(error);
        })
    };

    const loadUnits = async () => {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/v1/admin/hospitals/${me.attributes.hospital_id}/units/list`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                "Authorization": `Bearer ${user.token}`,
            },
            type: 'json',
        }).then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                throw new Error(response.statusText);
            }
        })
            .then(response => {
                const newData = response.units.data.map((item) => {
                    return {
                        ...item,
                        key: item.id,
                    }
                })
                setUnits(newData);
                setIsLoading(false);
            })
            .catch(error => {
                console.log(error);
            })
    };

    useEffect(() => {
        loadUnits();
    }, []);

    return (
        <>
            <div className={`py-5 grid grid-cols-1 mb-5 ${unitsStyles.headerBar}`}>
                <h1 className='text-4xl text-center text-white'>Units</h1>
            </div>
            <Row>
                <Col span={24}>
                    <div className='max-w-sm rounded overflow-hidden mb-4'>
                        <div className='flex flex-row justify-center space-x-4 md:justify-start'>
                            <Button
                                className='bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 rounded'
                                icon={<PlusOutlined />}
                                onClick={() => navigate('/admin/units/new')}>
                                Add Unit
                            </Button>
                        </div>
                    </div>
                </Col>
            </Row>
            <Skeleton loading={isLoading} active>
                <Table
                    dataSource={units}
                    columns={columns}
                />
            </Skeleton>
        </>
    )
}
