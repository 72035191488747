import React from 'react'
import { Button, Card, Col, Input, notification, Row, Skeleton, Space, Table, } from 'antd';
import adminUsersStyles from './AdminUsers.module.css';
import { useEffect, useState } from 'react';
import { useUserStore } from '../../../context/UserStore';
import { useNavigate } from 'react-router-dom';
import { SearchOutlined, EditOutlined, CheckOutlined, DeleteOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

export default function AdminUsers() {
    const navigate = useNavigate();
    dayjs.extend(utc);
    const [hospitals, setHospitals] = useState([]);
    const [users, setUsers] = React.useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const me = useUserStore((state) => state.me);
    const user = useUserStore((state) => state.user);

    const roleTypes = [
        {
            text: 'Super Administrator',
            value: 'super administrator',
        },
        {
            text: 'Administrator',
            value: 'administrator',
        },
        {
            text: 'Manager',
            value: 'manager',
        },
    ];

    const statusTypes = [
        {
            text: 'Active',
            value: 0,
        },
        {
            text: 'Inactive',
            value: 1,
        },
    ];

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
                return (
                    <div style={{ padding: 8 }}>
                        <Input autoFocus
                            placeholder="Search"
                            onPressEnter={() => { confirm() }}
                            onBlur={() => { confirm() }}
                            value={selectedKeys}
                            style={{ marginBottom: 8, display: 'block' }}
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({ closeDropDown: false });
                            }
                            } />
                        <Space space='small'>
                            <Button
                                onClick={() => { confirm() }}
                                className='bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 rounded'
                                icon={<SearchOutlined />}
                                style={{ width: 90 }}>
                                Search
                            </Button>
                            <Button
                                onClick={() => { clearFilters() }}

                                className='bg-orange-500 hover:bg-orange-700 font-bold px-4 rounded'>
                                Reset
                            </Button>
                        </Space>
                    </div>
                )
            },
            filterIcon: () => {
                return <SearchOutlined />;
            },
            onFilter: (value, record) => record.attributes.full_name.toLowerCase().includes(value.toLowerCase()),
            render: (id, record) => {
                return (
                    record.attributes.full_name
                )
            }
        },
        {
            title: 'Hospital',
            dataIndex: 'hospital',
            key: 'hospital',
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
                return (
                    <div style={{ padding: 8 }}>
                        <Input autoFocus
                            placeholder="Search"
                            onPressEnter={() => { confirm() }}
                            onBlur={() => { confirm() }}
                            value={selectedKeys}
                            style={{ marginBottom: 8, display: 'block' }}
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({ closeDropDown: false });
                            }
                            } />
                        <Space>
                            <Button
                                onClick={() => { confirm() }}
                                className='bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 rounded'
                                icon={<SearchOutlined />}
                                style={{ width: 90 }}>
                                Search
                            </Button>
                            <Button
                                onClick={() => { clearFilters() }}

                                className='bg-orange-500 hover:bg-orange-700 font-bold px-4 rounded'>
                                Reset
                            </Button>
                        </Space>
                    </div>
                )
            },
            filterIcon: () => {
                return <SearchOutlined />;
            },
            onFilter: (value, record) => record.attributes.hospital_name.toLowerCase().includes(value.toLowerCase()),
            render: (id, record) => {
                return (
                    record.attributes.hospital_name
                )
            }
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
            filters: roleTypes,
            filterMode: 'tree',
            filterSearch: true,
            onFilter: (value, record) => {
                return record.attributes.role.toLowerCase().includes(value.toLowerCase());
            },
            render: (id, record) => {
                return (
                    record.attributes.role
                )
            }
        },
        {
            title: 'Interactions',
            dataIndex: 'interactions',
            key: 'interactions',
            render: (id, record) => {
                return (
                    record.attributes.interaction_count
                )
            }
        },
        {
            title: 'Mentors',
            dataIndex: 'mentors',
            key: 'mentors',
            render: (id, record) => {
                return (
                    record.attributes.mentor_count
                )
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            filters: statusTypes,
            filterMode: 'tree',
            filterSearch: true,
            onFilter: (value, record) => {
                return record.attributes.deactivated.includes(value);
            },
            render: (id, record) => {
                if (record.attributes.deactivated === '1') {
                    return (
                        'Inactive'
                    )
                } else {
                    return (
                        'Active'
                    )
                }
            }
        },
        {
            title: 'Last Active',
            dataIndex: 'lastActive',
            key: 'lastActive',
            render: (id, record) => {
                const date = dayjs.utc(record.attributes.last_active);
                if (date.isValid()) {
                    return `${date.format("MM/DD/YYYY hh:mm A")}`;
                } else {
                    return 'N/A';
                }
            }
        },
        {
            title: 'Action',
            key: 'action',
            render: (id, record) => (
                <Space size="small">
                    <Button className='bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 rounded' onClick={() => navigate(`/admin/users/${record.id}`)}>View</Button>
                    <Button className='bg-orange-500 hover:bg-orange-700 font-bold rounded' onClick={() => navigate(`/admin/users/${record.id}/edit`)}>
                        <EditOutlined />
                    </Button>
                    <Button className='bg-red-500 hover:bg-red-700 font-bold rounded' disabled={(me.attributes.role == 'Administrator' && record.attributes.role == 'Administrator')} onClick={() => disableUser(record.id)}>
                        <DeleteOutlined />
                    </Button>
                </Space>
            ),
        },
    ];

    const disableUser = async (id) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/admin/users/${id}/disable`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                "Authorization": `Bearer ${user.token}`,
            },
            type: 'json',
        }).then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                throw new Error(response.statusText);
            }
        }).then(response => {
            notification.success({
                message: response.message,
                description: response.message,
            });
        }).catch(error => {
            notification.error({
                message: error.errors,
            });
            console.log(error);
        })
    };

    const loadUsers = async () => {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/v1/admin/users/list`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                "Authorization": `Bearer ${user.token}`,
            },
            type: 'json',
        }).then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                throw new Error(response.statusText);
            }
        })
            .then(response => {
                const newData = response.users.data.map((item) => {
                    return {
                        ...item,
                        key: item.id,
                    }
                })
                if (me.attributes.role == 'Administrator') {
                    setUsers(newData.filter(item => item.attributes.hospital_id === me.attributes.hospital_id));
                } else {
                    setUsers(newData);
                }
                setIsLoading(false);
            })
            .catch(error => {
                console.log(error);
            })
    };

    useEffect(() => {
        loadUsers();
    }, []);

    return (
        <>
            <div className={`py-5 grid grid-cols-1 mb-5 ${adminUsersStyles.headerBar}`}>
                <h1 className='text-4xl text-center text-white'>Users</h1>
            </div>
            <Row>
                <Col span={24}>
                    <div className='max-w-sm rounded overflow-hidden mb-4'>
                        <div className='flex flex-row justify-center space-x-4 md:justify-start'>
                            <Button
                                className='bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 rounded'
                                icon={<UploadOutlined />} disabled={me.attributes.role == 'Administrator'}
                                onClick={() => navigate('/admin/users/import')}>
                                Import Users
                            </Button>
                            <Button
                                className='bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 rounded'
                                icon={<PlusOutlined />}
                                onClick={() => navigate('/admin/users/new')}>
                                Add User
                            </Button>
                        </div>
                    </div>
                </Col>
            </Row>
            <Skeleton loading={isLoading} active>
                <Table
                    dataSource={users}
                    columns={columns}
                />
            </Skeleton>
        </>
    )
}
