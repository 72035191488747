import React from 'react';
import createDevelopmentDialogueStyles from './CreateDevelopmentDialogue.module.css';
import { Button, DatePicker, Form, Input, message, notification, Radio, Select } from 'antd';
import dayjs from 'dayjs';
import LoadingSpinner from '../../../../Shared/LoadingSpinner/LoadingSpinner';
import { useNavigate, useParams } from 'react-router-dom';
import { useUserStore } from '../../../../../context/UserStore';

const { useState, useEffect } = React;

export default function CreateDevelopmentDialogue() {
    const navigate = useNavigate();
    const todaysDate = dayjs(new Date()).format('MM/DD/YYYY');
    const timeNow = dayjs(new Date()).format('HH:mm');
    const { employeeId, mentorId } = useParams();
    const [employee, setEmployee] = useState({});
    const [feedback, setFeedback] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const { TextArea } = Input;
    const user = useUserStore((state) => state.user);

    const customFetch = async (page, pageSize) => {
        setIsLoading(true);
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/v1/users/employees/${employeeId}`, {
            method: "get",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
            },
            type: "json"
        }).then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        }).then(response => {
            setEmployee(response.employee.data);
            setIsLoading(false);
        }).catch(error => {
            console.log(error)
        });
    };

    const onFinish = (values) => {
        createMentor({
            "mentor": {
                mentor_type_id: 2,
                mentor_type_name: "Development Dialogue",
                time_legible: timeNow,
                date_legible: todaysDate,
                change: values.change,
                followup_note: values.followup_note,
                improvement: values.improvement,
                skills_to_work_on: values.skills_to_work_on,
                strengths: values.strengths,
                needs_attention: values.needs_attention,
            }
        });
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const createMentor = async (values) => {
        return fetch(`${process.env.REACT_APP_API_URL}/api/v1/users/employees/${employeeId}/mentors`, {
            method: "post",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,

            },
            body: JSON.stringify(values),
            type: "json"
        }).then(response => {
            if (!response.ok) {
                message.error(response.statusText)
            }
            return response.json();
        }).then(response => {
            message.success('Feedback successfully created');
            navigate(`/my-employees/mentor/review`);
        }).catch(error => {
            message.error(error)
        });
    }

    useEffect(() => {
        customFetch({});
    }, []);

    return (
        <>
            <div className={`py-5 grid grid-cols-1 mb-5 ${createDevelopmentDialogueStyles.headerBar}`}>
                <h1 className='text-4xl text-center text-white'>Add Development Dialogue</h1>
            </div>
            <div className='flex flex-col justify-left'>
                {(
                    (Object.keys(employee).length === 0) ? <LoadingSpinner /> :
                        <>
                            <h1 className='text-xl'><strong>Name:</strong> {employee.attributes.first_name} {employee.attributes.last_name}</h1>
                            <p className='text-lg'><strong>Email:</strong> {employee.attributes.email}</p>
                            <p className='text-lg'><strong>Date: </strong> {todaysDate}</p>
                            <p className='text-lg'><strong>Time: </strong> {timeNow}</p>
                        </>
                )}
            </div>
            <div className='grid grid-cols-6 gap-4'>
                <div className='col-span-6 mt-5'>
                    <Form
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        labelWrap
                        labelAlign="left"
                        layout="vertical"
                        className='w-full'
                    >
                        <div className="shadow sm:rounded-md sm:overflow-hidden">
                            <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                                <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-12 sm:col-span-2">
                                        <Form.Item
                                            label="Area(s) of Strength:"
                                            name="strengths"
                                            className='col-span-6 sm:col-span-2'
                                            rules={[{ required: true, message: 'Please enter comments!' }]}
                                        >
                                            <TextArea rows={4} placeholder='Elaborate' />
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                            <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                                <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-12 sm:col-span-2">
                                        <Form.Item
                                            label="Area(s) for Improvement:"
                                            name="improvement"
                                            className='col-span-6 sm:col-span-2'
                                            rules={[{ required: true, message: 'Please enter comments!' }]}
                                        >
                                            <TextArea rows={4} placeholder='Elaborate' />
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                            <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                                <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-12 sm:col-span-2">
                                        <Form.Item
                                            label="Career goals/status:"
                                            name="change"
                                            className='col-span-6 sm:col-span-2'
                                            rules={[{ required: true, message: 'Please enter comments!' }]}
                                        >
                                            <TextArea rows={4} placeholder='Elaborate' />
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                            <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                                <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-12 sm:col-span-2">
                                        <Form.Item
                                            label="Specific skills or behavior to work on:"
                                            name="skills_to_work_on"
                                            className='col-span-6 sm:col-span-2'
                                            rules={[{ required: true, message: 'Please enter comments!' }]}
                                        >
                                            <TextArea rows={4} placeholder='Elaborate' />
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                            <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                                <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-12 sm:col-span-2">
                                        <Form.Item
                                            label="Plan for follow up:"
                                            name="followup_note"
                                            className='col-span-6 sm:col-span-2'
                                            rules={[{ required: true, message: 'Please enter comments!' }]}
                                        >
                                            <TextArea rows={4} placeholder='Elaborate' />
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                            <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                                <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-12 sm:col-span-2">
                                        <Form.Item
                                            label="Next Follow Up Date"
                                            name="needs_attention"
                                            className='col-span-6 sm:col-span-2'
                                        >
                                            <DatePicker />
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                            <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                                <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-12 sm:col-span-2">
                                        <Form.Item
                                            wrapperCol={{
                                                offset: 8,
                                                span: 16,
                                            }}
                                        >
                                            <Button
                                                className='bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 rounded'
                                                htmlType="submit"
                                            >
                                                Submit
                                            </Button>
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </>
    )
}
