import React, { useEffect, useState } from "react";
import {
  Button,
  notification,
  Space,
  Skeleton,
  Table,
} from "antd";
import { useNavigate } from "react-router-dom";
import sharedDecisionsStyles from "./SharedDecisions.module.css";
import { useUserStore } from "../../../context/UserStore";
import {
  EditOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import dailyHuddlesStyles from "../../Teach/DailyHuddles/DailyHuddles.module.css";

export default function SharedDecisions() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [problems, setProblems] = useState([]);
  const me = useUserStore((state) => state.me);
  const user = useUserStore((state) => state.user);

  const loadProblems = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/users/problems`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        type: "json",
      },
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((response) => {
        const newData = response.problems.data.map((item) => {
          return {
            ...item,
            key: item.id,
          };
        });
        setProblems(newData.sort((a, b) => b.id - a.id));
        setIsLoading(false);
      })
      .catch((error) => {
        notification.error({
          message: "Error",
          description: `Failed to load problems. ${error}`,
        });
      });
  };

  const markComplete = async (id) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/users/problems/${id}/mark_complete`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        type: "json",
      },
    )
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error(response.statusText);
        }
      })
      .then((response) => {
        loadProblems();
      })
      .catch((error) => {
        notification.error({
          message: error.errors,
        });
      });
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Problem",
      dataIndex: "attributes.problem",
      key: "problem",
      render: (id, record) => {
        return `${record.attributes.problem}`;
      },
    },
    {
      title: "Description",
      dataIndex: "attributes.description",
      key: "description",
      render: (id, record) => {
        return `${record.attributes.description}`;
      },
    },
    {
      title: "Complete",
      dataIndex: "attributes.complete",
      key: "complete",
      render: (id, record) => {
        return record.attributes.complete ? "Yes" : "No";
      },
    },
    {
      title: "Action",
      key: "action",
      render: (id, record) => (
        <Space size="small">
          <Button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 rounded"
            onClick={() => navigate(`/empower/shared-decisions/${record.id}`)}
          >
            View
          </Button>
          <Button
            className="bg-orange-500 hover:bg-orange-700 font-bold rounded"
            onClick={() =>
              navigate(`/empower/shared-decisions/${record.id}/edit`)
            }
          >
            Edit
            <EditOutlined />
          </Button>
          <Button
            className="bg-red-500 hover:bg-red-700 font-bold rounded"
            type="default"
            onClick={() => markComplete(record.id)}
          >
            <CheckOutlined />
          </Button>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    loadProblems();
  }, []);
  return (
    <>
      <div
        className={`py-8 mb-10 flex flex-col items-center justify-center bg-gradient-to-r from-[#64C2EE] to-[#A3DBF5] rounded-lg shadow-lg space-y-5 ${sharedDecisionsStyles.headerBar}`}
      >
        <img
          className="w-48 rounded-full shadow-lg"
          src="/assets/images/decisions-icon.png"
          alt="Decisions Icon"
        />
        <h1 className="text-center text-white text-4xl font-bold tracking-wide">Shared Decisions</h1>
        <p className="text-center text-white text-lg italic max-w-xl">
          “Leaders become great not because of their power, but because of their ability to empower others.”
          <br />– John Maxwell
        </p>
      </div>
      <div className="flex flex-col items-center md:items-start">
        <div className="w-full">
          <div className="bg-white rounded-lg p-0">
            <div className="flex justify-center md:justify-start space-x-2 mb-4">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 py-2 rounded"
                onClick={() => navigate("/empower/shared-decisions/new")}
              >
                <svg
                  className="w-4 h-4 inline-block mr-2"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                Add New Improvement Team Charter
              </button>
            </div>
          </div>
        </div>
      </div>
      <Space />
      <Skeleton loading={isLoading} active>
        <Table
          dataSource={problems}
          columns={columns}
          loading={isLoading}
          rowKey="id"
          scroll={{ x: 'max-content' }}
        />
      </Skeleton>
    </>
  );
}
