import React, { useState } from "react";
import technicalHelpStyles from "./TechnicalHelp.module.css";

export default function TechnicalHelp() {
  const [problem, setProblem] = useState("");

  const handleSubmit = () => {
    const mailtoLink = `mailto:support@stampandchase.com?subject=Need Technical Support&body=${encodeURIComponent(problem)}`;
    window.location.href = mailtoLink;
  };

  return (
    <div className="container mx-auto">
      <div
        className={`py-8 mb-10 flex flex-col items-center justify-center bg-gradient-to-r from-[#e87c10] to-[#f59222] rounded-lg shadow-lg ${technicalHelpStyles.headerBar}`}
      >
        <h1 className="text-4xl md:text-4xl text-center font-bold text-white tracking-wide">
          Need Technical Support?
        </h1>
      </div>
      <p>
        Fill out the form below and we will get back to you as soon as possible.
      </p>
      <textarea
        className="w-full border-2 border-gray-200 p-2 mt-4"
        rows="5"
        placeholder="Describe the problem you are having..."
        value={problem}
        onChange={(e) => setProblem(e.target.value)}
      />
      <button
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
        onClick={handleSubmit}
      >
        Submit
      </button>
    </div>
  );
}
