import React from "react";
import { useNavigate } from "react-router-dom";
import sideNavBarStyles from "./SideNavBar.module.css";
import { Button, Divider, Image, Layout, Menu, Row, Col } from "antd";
import { HomeOutlined, ProfileOutlined } from "@ant-design/icons";
import { useUserStore } from "../../context/UserStore";

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
    className: sideNavBarStyles.menuItem,
  };
}

export default function SideNavBar(props) {
  const navigate = useNavigate();
  const user = useUserStore((state) => state.user);
  const me = useUserStore((state) => state.me);

  const items = [
    getItem(
      "Home",
      "",
      <span className="mr-1">
        <HomeOutlined style={{ fontSize: "20px" }} />
      </span>,
    ),
    getItem(
      "Teach",
      "1",
      <Image
        preview={false}
        className={sideNavBarStyles.menuIcon}
        src="assets/images/teach-icon.png"
      />,
      [
        getItem("Integrated Rounds", "teach/integrated-rounds"),
        getItem("Huddles", "teach/daily-huddles"),
        getItem("Staff Meetings", "teach/staff-meetings"),
      ],
    ),
    getItem(
      "Empower",
      "2",
      <Image
        preview={false}
        className={sideNavBarStyles.menuIcon}
        src="assets/images/empower-icon.png"
      />,
      [getItem("PI Team Charters", "empower/shared-decisions")],
    ),
    getItem(
      "Align",
      "3",
      <Image
        preview={false}
        className={sideNavBarStyles.menuIcon}
        src="assets/images/align-icon.png"
      />,
      [
        getItem("Goal Setting Info", "align/cascading-goals"),
        getItem("Results / Graphs", "align/monitoring-results"),
      ],
    ),
    getItem(
      "Mentor",
      "4",
      <Image
        preview={false}
        className={sideNavBarStyles.menuIcon}
        src="assets/images/mentor-icon.png"
      />,
      [
        getItem("Add Feedback", "my-employees/feedback/new"),
        getItem("Conversations Journal", "my-employees/mentor/review"),
        getItem("My Employees", "my-employees"),
      ],
    ),
    getItem(
      "My Profile",
      "5",
      <span className="mr-1">
        <ProfileOutlined style={{ fontSize: "20px" }} />
      </span>,
      [
        getItem("Edit Profile", `user/${me.attributes.id}/edit`),
        getItem("Help", `help`),
      ],
    ),
  ];

  const hospitalAdminItems = [
    getItem(
      "Home",
      "",
      <span className="mr-4">
        <HomeOutlined style={{ fontSize: "20px" }} />
      </span>,
    ),
    getItem(
      "Metrics",
      "1",
      <Image
        preview={false}
        className={sideNavBarStyles.menuIcon}
        src="assets/images/metrics-icon.png"
      />,
      [getItem("View Metrics", "admin/monitoring-results")],
    ),
    getItem(
      "Users",
      "2",
      <Image
        preview={false}
        className={sideNavBarStyles.menuIcon}
        src="assets/images/users-icon.png"
      />,
      [
        getItem("Add Users", "admin/users/new"),
        getItem("View Users", "admin/users"),
      ],
    ),
    getItem(
      "Employees",
      "3",
      <Image
        preview={false}
        className={sideNavBarStyles.menuIcon}
        src="assets/images/employees-icon.png"
      />,
      [
        getItem("Add Employees", "admin/employees/new"),
        getItem("View Employees", "admin/employees"),
      ],
    ),
    getItem(
      "Units",
      "4",
      <Image
        preview={false}
        className={sideNavBarStyles.menuIcon}
        src="assets/images/hospital-icon.png"
      />,
      [
        getItem("Add Units", "admin/units/new"),
        getItem("View Units", "admin/units"),
      ],
    ),
    getItem(
      "Questions",
      "5",
      <Image
        preview={false}
        className={sideNavBarStyles.menuIcon}
        src="assets/images/questions-icon.png"
      />,
      [
        getItem("Add Questions", "admin/questions/new"),
        getItem("View Questions", "admin/questions"),
      ],
    ),
    getItem(
      "My Profile",
      "6",
      <span className="mr-4">
        <ProfileOutlined style={{ fontSize: "20px" }} />
      </span>,
      [
        getItem("Edit Profile", `user/${me.attributes.id}/edit`),
        getItem("Help", `help`),
      ],
    ),
  ];

  const superAdminItems = [
    getItem(
      "Home",
      "",
      <span className="mr-4">
        <HomeOutlined style={{ fontSize: "20px" }} />
      </span>,
    ),
    getItem(
      "Hospitals",
      "1",
      <Image
        preview={false}
        className={sideNavBarStyles.menuIcon}
        src="assets/images/hospital-icon.png"
      />,
      [
        getItem("Add Hospitals", "admin/hospitals/new"),
        getItem("View Hospitals", "admin/hospitals"),
      ],
    ),
    getItem(
      "Users",
      "2",
      <Image
        preview={false}
        className={sideNavBarStyles.menuIcon}
        src="assets/images/users-icon.png"
      />,
      [
        getItem("Add Users", "admin/users/new"),
        getItem("View Users", "admin/users"),
      ],
    ),
    getItem(
      "Employees",
      "3",
      <Image
        preview={false}
        className={sideNavBarStyles.menuIcon}
        src="assets/images/employees-icon.png"
      />,
      [
        getItem("Add Employees", "admin/employees/new"),
        getItem("View Employees", "admin/employees"),
      ],
    ),
    getItem(
      "Questions",
      "4",
      <Image
        preview={false}
        className={sideNavBarStyles.menuIcon}
        src="assets/images/questions-icon.png"
      />,
      [
        getItem("Add Questions", "admin/questions/new"),
        getItem("View Questions", "admin/questions"),
      ],
    ),
    getItem(
      "My Profile",
      "5",
      <span className="mr-4">
        <ProfileOutlined style={{ fontSize: "20px" }} />
      </span>,
      [
        getItem("Edit Profile", `user/${me.attributes.id}/edit`),
        getItem("Help", `help`),
      ],
    ),
  ];

  const menuItems = () => {
    switch (me.attributes.role) {
      case "Super Administrator":
        return superAdminItems;
        break;
      case "Administrator":
        return hospitalAdminItems;
        break;
      default:
        return items;
        break;
    }
  };

  function onMenuItemClick(value) {
    navigate(`/${value.key}`);
  }

  const logOutUser = () => {
    localStorage.removeItem("access-token");
    localStorage.removeItem("uid");
    localStorage.removeItem("client");
    localStorage.removeItem("UserObject");
    navigate("/");
    window.location.reload(true);
  };

  return (
    <>
      <Row
        justify="left"
        align="left"
        className={`mt-2 mb-2 ${sideNavBarStyles.nameBadge}`}
      >
        <Col span={2}></Col>
        <Col span={20}>
          <p className={sideNavBarStyles.welcomeMessage}>
            Welcome, {me.attributes.first_name}
          </p>
          <p className={sideNavBarStyles.hospitalName}>{me.attributes.hospital_name}</p>
          <Button
            className="mt-2 px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white bg-blue-500 hover:bg-blue-600 hover:shadow-md transition duration-300 ease-in-out transform hover:scale-105"
            onClick={() => logOutUser()}
          >
            Logout
          </Button>
          <Divider />
        </Col>
      </Row>
      <Menu
        onClick={(value) => onMenuItemClick(value)}
        theme="dark"
        defaultSelectedKeys={["1"]}
        mode="inline"
        items={menuItems()}
      />
      <Row justify="left" align="left" className="mt-2 mb-2">
        <Col span={2}></Col>
        <Col span={20}>
          <Button
            className="mt-2 px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white bg-blue-500 hover:bg-blue-600 hover:shadow-md transition duration-300 ease-in-out transform hover:scale-105"
            onClick={() => navigate(`/technical-help`)}
          >
            Technical Help
          </Button>
        </Col>
      </Row>
    </>
  );
}
