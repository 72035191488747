import React from 'react';
import { Button, Form, Input, message, notification, Radio, Select } from 'antd';
import editDailyCoachingStyles from './EditDailyCoaching.module.css';
import LoadingSpinner from '../../../../Shared/LoadingSpinner/LoadingSpinner';
import { useNavigate, useParams } from 'react-router-dom';
import { useUserStore } from '../../../../../context/UserStore';

const { useState, useEffect } = React;


export default function EditDailyCoaching() {
    const navigate = useNavigate();
    const { employeeId, mentorId } = useParams();
    const [employee, setEmployee] = useState({});
    const [feedback, setFeedback] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const { TextArea } = Input;
    const user = useUserStore((state) => state.user);

    const customFetch = async (page, pageSize) => {
        setIsLoading(true);
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/v1/users/employees/${employeeId}/mentors/${mentorId}`, {
            method: "get",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
            },
            type: "json"
        }).then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        }).then(response => {
            setEmployee(response.employee.data);
            setFeedback(response.mentor.data);
            setIsLoading(false);
        }).catch(error => {
            console.log(error)
        });
    };

    const onFinish = (values) => {
        updateMentor({
            "mentor": {
                feedback_type: values.feedback_type,
                feedback_text: values.feedback_text
            }
        });
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const updateMentor = async (values) => {
        return fetch(`${process.env.REACT_APP_API_URL}/api/v1//users/employees/${employeeId}/mentors/${mentorId}`, {
            method: "put",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,

            },
            body: JSON.stringify(values),
            type: "json"
        }).then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        }).then(response => {
            message.success('Feedback successfully updated');
        }).catch(error => {
            console.log(error)
        });
    }


    useEffect(() => {
        customFetch({});
    }, []);

    return (
        <>
            <div className={`py-5 grid grid-cols-1 mb-5 ${editDailyCoachingStyles.headerBar}`}>
                <h1 className='text-4xl text-center text-white'>Add or Edit Coaching</h1>
            </div>
            <div className='flex flex-col justify-left'>
                {(
                    (Object.keys(employee).length === 0) ? <LoadingSpinner /> :
                        <>
                            <h1 className='text-xl'><strong>Name:</strong> {employee.attributes.first_name} {employee.attributes.last_name}</h1>
                            <p className='text-lg'><strong>Email:</strong> {employee.attributes.email}</p>
                        </>
                )}
            </div>
            {(
                (Object.keys(feedback).length === 0) ? <LoadingSpinner /> :
                    <>
                        <div className='flex flex-col justify-left'>
                            <h1 className='text-xl'><strong>Date:</strong> {feedback.attributes.date_legible} </h1>
                            <p className='text-lg'><strong>Time:</strong> {feedback.attributes.time_legible} </p>
                        </div>
                        <div className='grid grid-cols-6 gap-4'>
                            <div className='col-span-6'>
                                <Form
                                    onFinish={onFinish}
                                    onFinishFailed={onFinishFailed}
                                    layout="verticle"
                                    className='w-full'
                                    initialValues={{
                                        feedback_type: feedback.attributes.feedback_type,
                                        feedback_text: feedback.attributes.feedback_text,
                                        file: ''
                                    }}
                                >
                                    <div className="shadow sm:rounded-md sm:overflow-hidden">
                                        <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                                            <div className="grid grid-cols-6 gap-6">
                                                <div className="col-span-12 sm:col-span-2">
                                                    <Form.Item
                                                        label="Feedback Type"
                                                        name="feedback_type"
                                                        className='col-span-6 sm:col-span-2'
                                                        rules={[{ required: true, message: 'Please select feedback type!' }]}
                                                    >
                                                        <Select
                                                            loading={isLoading}
                                                            options={[
                                                                {
                                                                    value: 'positive',
                                                                    label: 'Positive Feedback',
                                                                },
                                                                {
                                                                    value: 'negative',
                                                                    label: 'Contructive Criticism',
                                                                },
                                                            ]}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                                            <div className="grid grid-cols-6 gap-6">
                                                <div className="col-span-12 sm:col-span-2">
                                                    <Form.Item
                                                        label="Comments"
                                                        name="feedback_text"
                                                        className='col-span-6 sm:col-span-2'
                                                        rules={[{ required: true, message: 'Please enter comments!' }]}
                                                    >
                                                        <TextArea rows={4} placeholder='Enter comments here' />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                                            <div className="grid grid-cols-6 gap-6">
                                                <div className="col-span-12 sm:col-span-2">
                                                    <Form.Item
                                                        label="Upload File"
                                                        name="file"
                                                        className='col-span-6 sm:col-span-2'
                                                    >
                                                        <Input type="file" />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                                            <div className="grid grid-cols-6 gap-6">
                                                <div className="col-span-12 sm:col-span-2">
                                                    <Form.Item
                                                        wrapperCol={{
                                                            offset: 8,
                                                            span: 16,
                                                        }}
                                                    >
                                                        <Button
                                                            className='bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 rounded'
                                                            htmlType="submit"
                                                        >
                                                            Submit
                                                        </Button>
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </>
            )}

        </>
    )
}
