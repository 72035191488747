import React from 'react';
import { Image } from 'antd';
import developmentDialoguesStyles from './DevelopmentDialogues.module.css';


export default function DevelopmentDialogues() {
    return (
        <>
            <div className={`py-5 grid grid-cols-1 mb-5 ${developmentDialoguesStyles.headerBar}`}>
                <Image width={200} src='/assets/images/dialogue-icon.png' />
                <h1 className='text-4xl text-center text-white'>Development Dialogues</h1>
            </div>
            <div className='grid grid-cols-6 gap-4'>
                <div className='col-span-6'>
                    <h4 className='text-xl'>Recommendations for Providing Constructive Feedback</h4>
                    <p className='text-lg mt-5'>Communicate areas of strength and/or for improvement:</p>
                    <ul className="sub-menu list-disc list-inside">
                        <li>Be as specific as possible.</li>
                        <li>Focus on the behavior, not the person.</li>
                        <li>Site examples, if possible, when the specific behavior was not appropriate or compromised patient care.</li>
                        <li>Avoid generalities and “everybody says …” statements.</li>
                    </ul>
                    <p className='text-lg mt-5'>Why changes are important:</p>
                    <ul className="sub-menu list-disc list-inside">
                        <li>Explain the impact the behavior has on patient care, the work environment and/or patient satisfaction.</li>
                        <li>Emphasize both personal and professional benefits in changing the behavior.</li>
                    </ul>
                    <p className='text-lg mt-5'>Skills or behaviors to work on:</p>
                    <ul className="sub-menu list-disc list-inside">
                        <li>Be as specific as possible.</li>
                        <li>Use tips and techniques from the CAREmunication curriculum if possible.</li>
                        <li>Simply making the person aware of the behavior and why it is a problem may be the most important step in changing the behavior.</li>
                    </ul>
                    <p className='text-lg mt-5'>Plan for follow-up:</p>
                    <ul className="sub-menu list-disc list-inside">
                        <li>Specify a timeframe to talk again about the behavior and progress the individual has made.</li>
                        <li>Ask if they have any questions about your recommendations.</li>
                        <li>And ask if there are ways you or other team members could be helpful to them.</li>
                    </ul>
                </div>
            </div>
        </>
    )
}
