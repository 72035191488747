import React, { useState } from "react";
import reportHelpStyles from "./ReportHelp.module.css";

export default function ReportHelp() {
  const [problem, setProblem] = useState("");

  const handleSubmit = () => {
    const mailtoLink = `mailto:support@stampandchase.com?subject=Need Help with a Report&body=${encodeURIComponent(problem)}`;
    window.location.href = mailtoLink;
  };

  return (
    <div className="container mx-auto">
      <div
        className={`py-5 grid grid-cols-1 mb-5 ${reportHelpStyles.headerBar}`}
      >
        <h1 className="text-4xl text-center text-white">
          Need Help with a Report?
        </h1>
      </div>
      <p>
        Fill out the form below and we will get back to you as soon as possible.
      </p>
      <textarea
        className="w-full border-2 border-gray-200 p-2 mt-4"
        rows="5"
        placeholder="Describe the problem you are having..."
        value={problem}
        onChange={(e) => setProblem(e.target.value)}
      />
      <button
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
        onClick={handleSubmit}
      >
        Submit
      </button>
    </div>
  );
}
