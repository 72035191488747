import * as React from "react";
import reqwest from "reqwest";
import { Button, Checkbox, Divider, Form, Input, notification } from "antd";
import useToken from "../useToken";
import { useParams } from "react-router-dom";
import editUserStyles from "./EditUser.module.css";
import { useNavigate } from "react-router-dom";
import dashboardStyles from "../Dashboard/Dashboard.module.css";

const { useState, useEffect } = React;

export default function EditUser(props) {
  const navigate = useNavigate();
  let { id } = useParams();

  const [initialValues, setInitialValues] = React.useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState({});
  const { token, setToken } = useToken();

  const onFinish = (values) => {
    customPatch(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const customFetch = async (params = {}) => {
    setIsLoading(true);
    const response = await reqwest({
      url: `${process.env.REACT_APP_API_URL}/api/v1/users/` + id,
      method: "get",
      headers: {
        Accept: "application/json",
        "access-token": token.access_token,
        client: token.client,
        uid: token.uid,
      },
      type: "json",
    });
    setUser(response.user.data);

    setInitialValues(response.user.data.attributes);

    setIsLoading(false);
  };

  const customPatch = async (params = {}) => {
    setIsLoading(true);
    const requestOptions = {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "access-token": token.access_token,
        client: token.client,
        uid: token.uid,
        "content-type": "application/json",
      },
      body: JSON.stringify({ user: params }),
      type: "json",
    };
    fetch(`${process.env.REACT_APP_API_URL}/api/v1/users/` + id, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setUser(data);
        setIsLoading(false);
        notification.success({
          message: "Updated successfully",
          description: "Your profile was updated successfully.",
          onClose: () => {
            navigate("/");
          },
          onClick: () => {
            navigate("/");
          },
        });
      })
      .catch(() => {
        setIsLoading(false);
        notification.error({
          message: "Failed to Update User",
          description: "Please correct the errors and try again.",
          onClick: () => {
            console.log("Notification Clicked!");
          },
        });
      });
  };

  useEffect(() => {
    customFetch({});
  }, []);

  return (
    <>
      <div className={`py-8 mb-10 flex flex-col items-center justify-center bg-gradient-to-r from-[#e87c10] to-[#f59222] rounded-lg shadow-lg ${editUserStyles.headerBar}`}>
        <h1 className="text-4xl md:text-4xl text-center font-bold text-white tracking-wide">Edit Profile</h1>
      </div>
      <div className="grid grid-cols-6 gap-4">
        <div className="col-start-2 col-span-3">
          {isLoading ? (
            <div className="text-center">Loading...</div>
          ) : (
            <Form
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              initialValues={initialValues}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  { required: true, message: "Please input your email!" },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="First Name"
                name="first_name"
                rules={[
                  { required: true, message: "Please input your First Name!" },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Last Name"
                name="last_name"
                rules={[
                  { required: true, message: "Please input your Last Name!" },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Full Name"
                name="full_name"
                rules={[
                  { required: true, message: "Please input your Full Name!" },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Employee Number"
                name="employee_id"
                rules={[
                  {
                    required: false,
                    message: "Please input your Employee Number!",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Job Title"
                name="job_title"
                rules={[
                  { required: true, message: "Please input your Job Title!" },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[
                  { required: false, message: "Please input your password!" },
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item
                name="remember"
                valuePropName="checked"
                wrapperCol={{ offset: 8, span: 16 }}
              >
                <Checkbox>Remember me</Checkbox>
              </Form.Item>

              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 rounded"
                  htmlType="submit"
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          )}
        </div>
      </div>
    </>
  );
}
