import React from "react";
import { Button, Descriptions, Divider, Row, Skeleton, Space } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import viewMetricStyles from "./viewMetric.module.css";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useState } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { PrinterOutlined } from "@ant-design/icons";
import { useUserStore } from "../../../../../context/UserStore";

export default function ViewMetric() {
  const navigate = useNavigate();
  const [loadingMetric, setLoadingMetric] = useState(true);
  const [metric, setMetric] = useState({});
  const { metricId } = useParams();
  const user = useUserStore((state) => state.user);

  const getMetric = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/users/metrics/${metricId}`,
      {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        type: "json",
      },
    )
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((response) => {
        setMetric(response.metric.data);
        setLoadingMetric(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Function to determine the lowest data point for the Y-axis
  const getMinY = (data) => {
    const values = data.map((item) => item.value);
    const goals = data.map((item) => item.goal_target);
    return Math.min(...values, ...goals);
  };

  const printView = (e) => {
    window.print();
  };

  useEffect(() => {
    getMetric();
  }, []);

  return (
    <>
      <div
        className={`py-5 mb-5 flex flex-col items-center justify-center ${viewMetricStyles.headerBar}`}
      >
        <h1 className="text-4xl text-center text-white">View Metric</h1>
      </div>

      <Skeleton loading={loadingMetric} active>
        {metric && metric.attributes && (
          <>
            <div className="bg-white shadow">
              <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                <div className="flex justify-between items-center">
                  <div className="flex items-center">
                    <button
                      onClick={() => navigate(-1)}
                      className="text-gray-500 hover:text-gray-700"
                    >
                      {/* Here you can use an SVG or an icon from a library for the back arrow */}
                      <svg
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M15 19l-7-7 7-7"
                        />
                      </svg>
                    </button>
                    <div className="ml-4">
                      <h2 className="text-lg leading-6 font-medium text-gray-900">
                        {metric.attributes.name}
                      </h2>
                      <p className="mt-1 max-w-2xl text-sm text-gray-500">
                        {metric.attributes.goal_name}
                      </p>
                    </div>
                  </div>
                  <button
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700"
                    onClick={() => printView()}
                  >
                    {/* Replace `PrinterIcon` with your chosen icon component */}
                    <PrinterOutlined
                      className="mr-2 h-5 w-5"
                      aria-hidden="true"
                    />
                    Print
                  </button>
                </div>
              </div>
              {/* Descriptions replaced with simple flex div */}
              <div className="mt-5 border-t border-gray-200">
                <dl className="sm:grid sm:grid-cols-3 sm:gap-4 p-4">
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Unit</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {metric.attributes.unit.name}
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Goal Year
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {metric.attributes.metric_year}
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Area</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {metric.attributes.area_type}
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Measurement Type
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {metric.attributes.measurement_type}
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Metric Interval
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {metric.attributes.measurement_interval}
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Creation Date
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {metric.attributes.friendly_created_at}
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Effective Time
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">2017-10-10</dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Description
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {metric.attributes.description}
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
            <ResponsiveContainer width="100%" height={800}>
              <AreaChart
                data={metric.attributes.graph_data}
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Area
                  type="monotone"
                  dataKey="goal_target"
                  activeDot={{ stroke: "#888", strokeWidth: 2, r: 2 }}
                  dot={{ stroke: "#888", strokeWidth: 2 }}
                  stackId="2"
                  strokeWidth="3"
                  stroke="#ffc658"
                  fill="#ffc65860"
                />
                <Area
                  type="monotone"
                  dataKey="value"
                  activeDot={{ stroke: "#82ca9d", strokeWidth: 2, r: 2 }}
                  dot={{ stroke: "#82ca9d", strokeWidth: 2 }}
                  stackId="1"
                  strokeWidth="3"
                  stroke="#82ca9d"
                  fill="#82ca9d60"
                />
              </AreaChart>
            </ResponsiveContainer>
          </>
        )}
      </Skeleton>
    </>
  );
}
