import React, { useEffect, useState } from 'react';
import { Button, Col, Descriptions, Divider, List, Row, Skeleton } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import integratedRoundStyles from './IntegratedRound.module.css';
import { useNavigate, useParams } from 'react-router-dom';
import { Content } from 'antd/lib/layout/layout';
import { useUserStore } from '../../../context/UserStore';


export default function IntegratedRound() {
    const resourceId = useParams().id
    const navigate = useNavigate();
    const [loadingInteraction, setLoadingInteraction] = useState(true);
    const [interaction, setInteraction] = useState({});
    const [questions, setQuestions] = useState([]);
    const user = useUserStore((state) => state.user);

    const clearFollowUp = async (answerId, questionId) => {
        setLoadingInteraction(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/users/questions/${questionId}/answers/${answerId}`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                },
                body: JSON.stringify({
                    answer: false
                }),
                type: 'json'
            });
            const data = await response.json();
            loadInteraction();
        } catch (error) {
            console.log(error);
        }
    }

    const loadInteraction = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/users/interactions/${resourceId}`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                }
            });
            const data = await response.json();
            setInteraction(data.interaction.data);
            setQuestions(data.interaction.data.attributes.questions_with_answers);
            setLoadingInteraction(false);
        } catch (error) {
            console.log(error);
        }
    }

    const showClearFollowUp = (question, answer, answerId, questionId) => {
        // return false if answer is false
        if (answer === 'No') {
            return false;
        }

        if (answer === 'Yes' && (question === 'Follow up?' || question === 'Follow up with patient?' || question === 'Follow up with staff?' || question === 'Follow up with family member?')) {
            return (
                <Button
                    className='bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 rounded'
                    onClick={() => clearFollowUp(answerId, questionId)}>
                    Clear Follow Up
                </Button>
            )
        }
    }

    useEffect(() => {
        loadInteraction();
    }, []);

    return (
        <>
            <div className={`py-5 mb-5 flex flex-col items-center justify-center ${integratedRoundStyles.headerBar}`}>
                <h1 className='text-4xl text-center text-white'>Integrated Round</h1>
            </div>
            <Content>
                {
                    (loadingInteraction == false) ?
                        <Row>
                            <Col xs={23} sm={23} md={23} lg={23} className={integratedRoundStyles.pageHeader}>
                                <PageHeader
                                    ghost={true}
                                    onBack={() => navigate(-1)}
                                    title={interaction.attributes.round_type_text}
                                >
                                    <Descriptions title="" bordered>
                                        <Descriptions.Item label="Unit Name">
                                            <span className={integratedRoundStyles.unitName}>{interaction.attributes.unit_name}</span>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Room Number">
                                            <span className={integratedRoundStyles.roomNumber}>{interaction.attributes.room_number}</span>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Round Date">
                                            <span className={integratedRoundStyles.roundDate}>{interaction.attributes.friendly_created_at}</span>
                                        </Descriptions.Item>
                                    </Descriptions>
                                </PageHeader>
                            </Col>
                        </Row>
                        : <p></p>
                }
                <Row>
                    <Col xs={23} sm={23} md={23} lg={23} xl={23}>
                        <List
                            loading={loadingInteraction}
                            itemLayout="horizontal"
                            dataSource={questions}
                            bordered
                            gutter={[16, 16]}
                            renderItem={(item) => (
                                <List.Item>
                                    <Skeleton loading={loadingInteraction} avatar={false} title={false} active>

                                        <List.Item.Meta
                                            title={item.question}
                                        />
                                        <div className='mr-4'>{showClearFollowUp(item.question, item.answer, item.aId, item.qId)}</div><div><p>{item.answer}</p></div>

                                    </Skeleton>
                                </List.Item>
                            )}
                        />
                    </Col>
                </Row>
            </Content>
        </>
    )
}
