import React from 'react'
import sharedDecisionStyles from './SharedDecision.module.css';
import { Button, Col, Divider, notification, Row, Skeleton } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useUserStore } from '../../../../context/UserStore';
import { EditOutlined, CheckOutlined } from '@ant-design/icons';

export default function SharedDecision() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [employees, setEmployees] = useState([]);
    const [sharedDecision, setSharedDecision] = useState({});
    const [isLoadingSharedDecision, setIsLoadingSharedDecision] = useState(true);
    const [initialValues, setInitialValues] = React.useState({});
    const user = useUserStore((state) => state.user);

    const getSharedDecision = async (id) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/users/problems/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${user.token}`
            },
            type: 'json',
        }).then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                throw new Error(response.statusText);
            }
        }).then(response => {
            setSharedDecision(response.problem.data.attributes);
            setEmployees(response.employees.data.map((employee) => {
                return employee.attributes
            }));
            setIsLoadingSharedDecision(false);
        }).catch(error => {
            notification.error({
                message: error.errors,
            });
        })
    };

    const markComplete = async (id) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/users/problems/${id}/mark_complete`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${user.token}`
            },
            type: 'json',
        }).then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                throw new Error(response.statusText);
            }
        }).then(response => {
            setSharedDecision(response.problem.data.attributes);
            setIsLoadingSharedDecision(false);
        }).catch(error => {
            notification.error({
                message: error.errors,
            });
        });
    }

    useEffect(() => {
        if (id) {
            getSharedDecision(id);
        }
    }, [id]);

    return (
        <>
            <Row className={`py-5 grid grid-cols-1 mb-5 ${sharedDecisionStyles.headerBar}`}>
                <h1 className='text-4xl text-center text-white'>View Shared Decision</h1>
            </Row>
            <Button
                key='back'
                className='bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 rounded mb-4'
                onClick={() => navigate('/empower/shared-decisions')}>
                Back
            </Button>
            <Skeleton loading={isLoadingSharedDecision} active>
                <Row>
                    <Col span={12}>
                        <h3 className="text-lg font-medium leading-6 text-gray-900">Problem:</h3>
                        <p>{sharedDecision.problem}</p>
                    </Col>
                </Row>
                <Divider />
                <Row>
                    <Col span={12}>
                        <h3 className="text-lg font-medium leading-6 text-gray-900">Hospital:</h3>
                        <p>{sharedDecision.hospital_name}</p>
                    </Col>
                </Row>
                <Divider />
                <Row>
                    <Col span={12}>
                        <h3 className="text-lg font-medium leading-6 text-gray-900">Unit:</h3>
                        <p>{sharedDecision.unit_name}</p>
                    </Col>
                </Row>
                <Divider />
                <Row>
                    <Col span={12}>
                        <h3 className="text-lg font-medium leading-6 text-gray-900">Description:</h3>
                        <p>{sharedDecision.description}</p>
                    </Col>
                </Row>
                <Divider />
                <Row>
                    <Col span={12}>
                        <h3 className="text-lg font-medium leading-6 text-gray-900">Status:</h3>
                        <p>{sharedDecision.status}</p>
                    </Col>
                </Row>
                <Divider />
                <Row>
                    <Col span={12}>
                        <h3 className="text-lg font-medium leading-6 text-gray-900">Staff:</h3>
                        <p>{employees.map(employee => {
                            return <p>{employee.first_name} {employee.last_name}</p>
                        })}</p>
                    </Col>
                </Row>
                <Divider />
                <Row>
                    <Col span={12}>
                        <h3 className="text-lg font-medium leading-6 text-gray-900">Other Team:</h3>
                        <p>{sharedDecision.team}</p>
                    </Col>
                </Row>
                <Divider />
                <Row>
                    <Col span={4}>
                        <h3 className="text-lg font-medium leading-6 text-gray-900">Complete:</h3>
                        <p>{(sharedDecision.complete == true) ? 'Yes' : 'No'}</p>
                    </Col>
                    <Col span={4}>
                        <Button
                            className='bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 rounded'
                            onClick={() => markComplete(sharedDecision.id)}>
                            <CheckOutlined />
                        </Button>
                    </Col>
                </Row>

            </Skeleton>
        </>
    )
}
