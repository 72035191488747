import React from 'react'
import { Button, Col, Form, Input, notification, Row, Select, Skeleton } from 'antd';
import adminNewUserStyles from './AdminNewUser.module.css';
import { useEffect, useState } from 'react';
import { useUserStore } from '../../../../context/UserStore';
import { useNavigate, useParams } from 'react-router-dom';

export default function AdminNewUser() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [hospitals, setHospitals] = useState([]);
    const [initialValues, setInitialValues] = React.useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingHospitals, setIsLoadingHospitals] = useState(true);
    const [isLoadingRoles, setIsLoadingRoles] = useState(true);
    const [isLoadingUnits, setIsLoadingUnits] = useState(true);
    const [roles, setRoles] = React.useState([]);
    const [unitOptions, setUnitOptions] = React.useState([]);
    const [userUnits, setUserUnits] = React.useState([]);
    const [form] = Form.useForm();
    const [formData, setFormData] = useState({});
    const me = useUserStore((state) => state.me);
    const user = useUserStore((state) => state.user);

    const loadHospitals = async () => {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/v1/admin/hospitals`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                "Authorization": `Bearer ${user.token}`,
            },
            type: 'json',
        }).then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                throw new Error(response.statusText);
            }
        })
            .then(response => {
                const newData = response.hospitals.data.map((item) => {
                    return {
                        label: item.attributes.name,
                        value: item.attributes.id,
                        key: item.id,
                    }
                })
                if (me.attributes.role === 'Administrator') {
                    setHospitals(newData.filter(item => item.value === me.attributes.hospital_id));
                } else {
                    setHospitals(newData);
                }

                setIsLoadingHospitals(false);
            })
            .catch(error => {
                console.log(error);
            })
    };

    const loadRoles = async () => {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/v1/admin/roles`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                "Authorization": `Bearer ${user.token}`,
            },
            type: 'json',
        }).then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                throw new Error(response.statusText);
            }
        })
            .then(response => {
                const newData = response.roles.data.map((item) => {
                    return {
                        label: item.attributes.name,
                        value: item.attributes.id,
                        key: item.id,
                    }
                })
                if (me.attributes.role === 'Administrator') {
                    setRoles(newData.filter(item => item.value !== 3));
                } else {
                    setRoles(newData);
                }

                setIsLoadingHospitals(false);
            })
            .catch(error => {
                console.log(error);
            })
    };

    const loadUnits = async (hospital_id) => {
        setIsLoadingUnits(true);
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/v1/admin/hospitals/${hospital_id}/units`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                "Authorization": `Bearer ${user.token}`,
            },
            type: 'json',
        }).then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                throw new Error(response.statusText);
            }
        })
            .then(response => {
                const newData = response.units.data.map((item) => {
                    return {
                        label: item.attributes.name,
                        value: item.attributes.id,
                        key: item.id,
                    }
                })
                setUnitOptions(newData);
                setIsLoadingUnits(false);
            })
            .catch(error => {
                console.log(error);
            })
    };

    const createUser = async (values) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/admin/users`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            },
            body: JSON.stringify({ user: values }),
            type: 'json',
        }).then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        }).then(response => {
            notification.success({
                message: response.message,
                description: response.message,
            });
            navigate(`/admin/users`);

        }).catch(error => {
            notification.error({
                message: error.errors,
            });
        });
    };

    const onFieldsChange = (changedFields, allFields) => {
        changedFields.map((field) => {
            setFormData({ ...formData, [field.name[0]]: field.value })
        })
    };

    const onFinish = (values) => {
        createUser(formData);
    };

    const onFinishFailed = (errorInfo) => {
        let error_message = errorInfo.errorFields.map((item) => {
            return item.errors[0];
        })

        notification['error']({
            message: 'Failed to create user',
            description: `${error_message}. `,
        });
    };

    useEffect(() => {
        loadHospitals();
        loadRoles();
        setIsLoading(false);
    }, []);

    return (
        <>
            <div className={`py-5 grid grid-cols-1 mb-5 ${adminNewUserStyles.headerBar}`}>
                <h1 className='text-4xl text-center text-white'>Add User</h1>
            </div>
            <Skeleton loading={isLoading} active>
                {(isLoading == false) && (
                    <>
                        <Row>
                            <Col span={20}>
                                <Form
                                    form={form}
                                    layout='vertical'
                                    initialValues={initialValues}
                                    onFieldsChange={onFieldsChange}
                                    onFinish={onFinish}
                                    onFinishFailed={onFinishFailed}
                                >
                                    <Row>
                                        <Col md={10} xs={20}>
                                            <Form.Item label='Role'>
                                                <Form.Item name='role_id' rules={[{ required: true, message: 'Please choose a Role' }]}>
                                                    <Select
                                                        loading={isLoadingRoles}
                                                        options={roles}
                                                    ></Select>
                                                </Form.Item>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row justify='space-between'>
                                        <Col md={10} xs={20}>
                                            <Form.Item label='Hospital'>
                                                <Form.Item
                                                    name='hospital_id'
                                                    rules={[{ required: false, message: 'Please choose a Hospital' }]}
                                                >
                                                    <Select
                                                        loading={isLoadingHospitals}
                                                        options={hospitals}
                                                        onChange={(value) => {
                                                            loadUnits(value)
                                                        }}
                                                    ></Select>
                                                </Form.Item>
                                            </Form.Item>
                                        </Col>
                                        <Col md={10} xs={20}>
                                            <Form.Item label='Units'>
                                                <Form.Item
                                                    name='unit_ids'
                                                    rules={[{ required: false, message: 'Please choose a Hospital' }]}
                                                >
                                                    <Select
                                                        mode="multiple"
                                                        style={{ width: '100%' }}
                                                        loading={isLoadingUnits}
                                                        placeholder="Select Unit(s)"
                                                        options={unitOptions}
                                                    />
                                                </Form.Item>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row justify='space-between'>
                                        <Col md={10} xs={20}>
                                            <Form.Item label='First Name'>
                                                <Form.Item name='first_name' rules={[{ required: true, message: 'Input a First Name' }]}>
                                                    <Input />
                                                </Form.Item>
                                            </Form.Item>

                                        </Col>
                                        <Col md={10} xs={20}>
                                            <Form.Item label='Last Name'>
                                                <Form.Item name='last_name' rules={[{ required: true, message: 'Select a Last Name' }]}>
                                                    <Input />
                                                </Form.Item>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row justify='space-between'>
                                        <Col md={10} xs={20}>
                                            <Form.Item label='Job Title'>
                                                <Form.Item name='job_title' rules={[{ required: true, message: 'Input a First Name' }]}>
                                                    <Input />
                                                </Form.Item>
                                            </Form.Item>

                                        </Col>
                                        <Col md={10} xs={20}>
                                            <Form.Item label='Employee ID'>
                                                <Form.Item name='employee_id' rules={[{ required: false, message: 'Select a Last Name' }]}>
                                                    <Input />
                                                </Form.Item>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row justify='space-between'>
                                        <Col md={10} xs={20}>
                                            <Form.Item label='Email'>
                                                <Form.Item name='email' rules={[{ required: true, message: 'Input an Email Address' }]}>
                                                    <Input />
                                                </Form.Item>
                                            </Form.Item>

                                        </Col>
                                        <Col md={10} xs={20}>
                                            <Form.Item label='Password'>
                                                <Form.Item name='password' rules={[{ required: false, message: 'Enter a Password' }]}>
                                                    <Input.Password />
                                                </Form.Item>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row justify='space-between'>
                                        <Col md={10} xs={24}>
                                            <Form.Item>
                                                <Form.Item>
                                                    <Button
                                                        className='bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 rounded'
                                                        htmlType="submit"
                                                    >
                                                        Submit
                                                    </Button>
                                                </Form.Item>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    </>
                )}
            </Skeleton >
        </>
    )
}
