import React from 'react'
import { useLocation } from "react-router-dom";
import ChangePassword from '../ChangePassword/ChangePassword';
import Login from '../Login/Login';
import ResetPassword from '../ResetPassword/ResetPassword';

export default function UserAuthContainer() {
    // if Route path is /reset-password, render ResetPassword component
    // else render Login component

    // Get route path
    const path = useLocation().pathname

    // render ResetPassword component if path is /reset-password
    // render ChangePassword component if path is /change-password
    // else render Login component

    switch (path) {
        case '/reset-password':
            return <ResetPassword />
        case '/change-password':
            return <ChangePassword />
        default:
            return <Login />
    }
}
