import React from "react";
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Descriptions,
  notification,
  Row,
  Skeleton,
  Space,
  Table,
} from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import hospitalStyles from "./Hospital.module.css";
import { useNavigate, useParams } from "react-router";
import { useUserStore } from "../../../../context/UserStore";
import {
  SearchOutlined,
  EditOutlined,
  CheckOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

export default function Hospital() {
  const navigate = useNavigate();
  const { id } = useParams();
  const user = useUserStore((state) => state.user);
  const [employees, setEmployees] = useState([]);
  const [hospital, setHospital] = useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isLoadingEmployees, setIsLoadingEmployees] = React.useState(true);

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "attributes.full_name",
      render: (id, record) => {
        return record.attributes.full_name;
      },
    },
    {
      title: "Job Title",
      dataIndex: "attributes.job_title",
      render: (id, record) => {
        return record.attributes.job_title;
      },
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "id",
      render: (id, record) => (
        <Space size="small">
          <Button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 rounded"
            onClick={() => navigate(`/admin/employees/${record.id}`)}
          >
            View
          </Button>
          <Button
            className="bg-orange-500 hover:bg-orange-700 font-bold rounded"
            onClick={() => navigate(`/admin/employees/${record.id}/edit`)}
          >
            <EditOutlined />
          </Button>
          <Button
            className="bg-red-500 hover:bg-red-700 font-bold rounded"
            onClick={() => disableEmployee(record.id)}
          >
            <DeleteOutlined />
          </Button>
        </Space>
      ),
    },
  ];

  const disableEmployee = async (employee_id) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/admin/hospitals/${id}/employees/${employee_id}/disable`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        type: "json",
      },
    )
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error(response.statusText);
        }
      })
      .then((response) => {
        notification.success({
          message: response.message,
          description: response.message,
        });
      })
      .catch((error) => {
        notification.error({
          message: error.errors,
        });
        console.log(error);
      });
  };

  const disableHospital = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/admin/hospitals/${id}/disable`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        type: "json",
      },
    )
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error(response.statusText);
        }
      })
      .then((response) => {
        notification.success({
          message: response.message,
          description: response.message,
        });
      })
      .catch((error) => {
        notification.error({
          message: error.errors,
        });
        console.log(error);
      });
  };

  const loadHospital = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/admin/hospitals/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        type: "json",
      },
    )
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error(response.statusText);
        }
      })
      .then((response) => {
        setHospital(response.hospital.data);
        setIsLoading(false);
        loadHospitalEmployees();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const loadHospitalEmployees = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/admin/hospitals/${id}/employees`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        type: "json",
      },
    )
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error(response.statusText);
        }
      })
      .then((response) => {
        setEmployees(response.employees.data);
        setIsLoadingEmployees(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    loadHospital();
  }, []);

  return (
    <>
      <div className={`py-5 grid grid-cols-1 mb-5 ${hospitalStyles.headerBar}`}>
        <h1 className="text-4xl text-center text-white">View Hospital</h1>
      </div>
      <Skeleton loading={isLoading} active>
        {/* Show Hospital data that is stored in hospital.attributes */}
        {hospital && (
          <>
            <PageHeader
              ghost={false}
              onBack={() => navigate(-1)}
              title={hospital.attributes.name}
              extra={[
                <Space size="small">
                  <Button
                    key={1}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 rounded"
                    onClick={() =>
                      navigate(`/admin/hospitals/${hospital.id}/edit`)
                    }
                  >
                    <EditOutlined />
                  </Button>
                  <Button
                    key={2}
                    className="bg-red-500 hover:bg-red-700 font-bold rounded"
                    onClick={() => disableHospital()}
                  >
                    <DeleteOutlined />
                  </Button>
                  <Button
                    key={3}
                    className="bg-orange-500 hover:bg-orange-700 font-bold rounded"
                    onClick={() =>
                      navigate(`/admin/hospitals/${hospital.id}/import`)
                    }
                  >
                    Import Staff
                  </Button>
                </Space>,
              ]}
            >
              <Descriptions size="small" column={1} bordered>
                <Descriptions.Item label="Address">
                  {hospital.attributes.address}, {hospital.attributes.city},{" "}
                  {hospital.attributes.state}, {hospital.attributes.zip}
                </Descriptions.Item>
              </Descriptions>
            </PageHeader>
            <Skeleton loading={isLoadingEmployees} active>
              <Row
                className={`py-5 grid grid-cols-1 mb-5 ${hospitalStyles.headerBar}`}
              >
                <h1 className="text-4xl text-center text-white">Employees</h1>
              </Row>
              <Table
                dataSource={employees}
                columns={columns}
                loading={isLoadingEmployees}
                rowKey="id"
              />
            </Skeleton>
          </>
        )}
      </Skeleton>
    </>
  );
}
