import React from "react";
import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Descriptions,
  Form,
  Row,
  Skeleton,
  Space,
  Tag,
} from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import employeeStyles from "./Employee.module.css";
import { useNavigate, useParams } from "react-router";
import { useUserStore } from "../../../../context/UserStore";
import {
  SearchOutlined,
  EditOutlined,
  CheckOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";

export default function Employee() {
  const navigate = useNavigate();
  const { id } = useParams();
  const user = useUserStore((state) => state.user);
  const [isLoading, setIsLoading] = useState(true);
  const [employee, setEmployee] = useState(null);
  const [hospital, setHospital] = useState(null);
  const [units, setUnits] = useState(null);

  const loadEmployee = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/admin/employees/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        type: "json",
      },
    )
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error(response.statusText);
        }
      })
      .then((response) => {
        setEmployee(response.employee.data);
        setHospital(
          response.employee.included.find((item) => item.type === "hospital"),
        );
        setIsLoading(false);

        const newUnitData =
          response.employee.data.attributes.employee_unit_options.map(
            (item) => {
              return {
                label: item.label,
                value: item.value,
                key: item.key,
              };
            },
          );
        setUnits(newUnitData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    loadEmployee(id);
  }, []);

  return (
    <>
      <div className={`py-5 grid grid-cols-1 mb-5 ${employeeStyles.headerBar}`}>
        <h1 className="text-4xl text-center text-white">View Employee</h1>
      </div>
      <Skeleton loading={isLoading} active>
        {employee && (
          <>
            <PageHeader
              ghost={false}
              onBack={() => navigate(-1)}
              title={employee.attributes.full_name}
              extra={[
                <Space size="small">
                  <Button
                    key="back"
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 rounded"
                    onClick={() => navigate("/admin/employees")}
                  >
                    Back
                  </Button>
                  <Button
                    key="edit"
                    lassName="bg-orange-500 hover:bg-orange-700 font-bold rounded"
                    onClick={() => navigate(`/admin/employees/${id}/edit`)}
                  >
                    <EditOutlined />
                  </Button>
                  <Button
                    key="delete"
                    type="danger"
                    className="bg-red-500 hover:bg-red-700 font-bold rounded"
                    onClick={() => disableUser(id)}
                  >
                    <DeleteOutlined />
                  </Button>
                </Space>,
              ]}
            ></PageHeader>
            <Row>
              <Col span={20}>
                <Form layout="vertical">
                  <Row>
                    <Col md={10} xs={20}>
                      <Form.Item label="Employee ID">
                        <Form.Item
                          rules={[
                            { required: true, message: "Please choose a Role" },
                          ]}
                        >
                          {employee.attributes.employee_id}
                        </Form.Item>
                      </Form.Item>
                    </Col>
                    <Col md={10} xs={20}>
                      <Form.Item label="Job Title">
                        <Form.Item
                          rules={[
                            { required: true, message: "Please choose a Role" },
                          ]}
                        >
                          {employee.attributes.job_title}
                        </Form.Item>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={10} xs={20}>
                      <Form.Item label="Email">
                        <Form.Item
                          rules={[
                            { required: true, message: "Please choose a Role" },
                          ]}
                        >
                          {employee.attributes.email}
                        </Form.Item>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={10} xs={20}>
                      <Form.Item label="Notes">
                        <Form.Item
                          rules={[
                            {
                              required: false,
                              message: "Please choose a Role",
                            },
                          ]}
                        >
                          {employee.attributes.notes}
                        </Form.Item>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={10} xs={20}>
                      <Form.Item label="Hospital">
                        <Form.Item
                          rules={[
                            { required: true, message: "Please choose a Role" },
                          ]}
                        >
                          {hospital && (
                            <Button
                              type="link"
                              className="bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 rounded"
                              onClick={() =>
                                navigate(
                                  `/admin/hospitals/${hospital.attributes.id}`,
                                )
                              }
                            >
                              {hospital.attributes.name}
                            </Button>
                          )}
                        </Form.Item>
                      </Form.Item>
                    </Col>
                    <Col md={10} xs={20}>
                      <Form.Item label="Units">
                        <Form.Item
                          rules={[
                            { required: true, message: "Please choose a Role" },
                          ]}
                        >
                          {units &&
                            units.map((item) => (
                              <Tag color="blue" key={item.key}>
                                {item.label}
                              </Tag>
                            ))}
                        </Form.Item>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </>
        )}
      </Skeleton>
    </>
  );
}
