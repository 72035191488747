import { useState } from 'react';

export default function useToken() {
    const getToken = () => {
        if (!localStorage.getItem('UserObject')) return false;

        const tokenString = {
            access_token: localStorage.getItem('access-token'),
            uid: localStorage.getItem('uid'),
            client: localStorage.getItem('client')
        };
        return tokenString
    };

    const [token, setToken] = useState(getToken());

    const saveToken = userToken => {
        localStorage.setItem('token', JSON.stringify(userToken));
        setToken(userToken.token);
    };

    return {
        setToken: saveToken,
        token
    }

}