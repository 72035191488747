import React from 'react'
import createObservationRssStyles from './CreateObservationRss.module.css'
import { Button, DatePicker, Form, Input, message, notification, Radio, Select } from 'antd';
import dayjs from 'dayjs';
import LoadingSpinner from '../../../../Shared/LoadingSpinner/LoadingSpinner';
import { useNavigate, useParams } from 'react-router-dom';
import { useUserStore } from '../../../../../context/UserStore';

const { useState, useEffect } = React;

export default function CreateObservationRss() {
    const navigate = useNavigate();
    const todaysDate = dayjs(new Date()).format('MM/DD/YYYY');
    const timeNow = dayjs(new Date()).format('HH:mm');
    const { employeeId, mentorId } = useParams();
    const [employee, setEmployee] = useState({});
    const [feedback, setFeedback] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const { TextArea } = Input;
    const user = useUserStore((state) => state.user);

    const customFetch = async (page, pageSize) => {
        setIsLoading(true);
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/v1/users/employees/${employeeId}`, {
            method: "get",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
            },
            type: "json"
        }).then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        }).then(response => {
            setEmployee(response.employee.data);
            setIsLoading(false);
        }).catch(error => {
            console.log(error)
        });
    };

    const onFinish = (values) => {
        createMentor({
            "mentor": {
                mentor_type_id: 3,
                mentor_type_name: "Observation - RRS",
                time_legible: timeNow,
                date_legible: todaysDate,
                can_help: values.can_help,
                cell_use: values.cell_use,
                comments: values.comments,
                dress_code: values.dress_code,
                explained: values.explained,
                eye_contact: values.eye_contact,
                followed_script: values.followed_script,
                introduced: values.introduced,
                protected_phi: values.protected_phi,
                thank_you: values.thank_you,
                what_else: values.what_else,
                needs_attention: values.needs_attention,
            }
        });
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const createMentor = async (values) => {
        return fetch(`${process.env.REACT_APP_API_URL}/api/v1/users/employees/${employeeId}/mentors`, {
            method: "post",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,

            },
            body: JSON.stringify(values),
            type: "json"
        }).then(response => {
            if (!response.ok) {
                message.error(response.statusText)
            }
            return response.json();
        }).then(response => {
            message.success('Feedback successfully created');
            navigate(`/my-employees/mentor/review`);
        }).catch(error => {
            message.error(error)
        });
    }

    useEffect(() => {
        customFetch({});
    }, []);

    return (
        <>
            <div className={`py-5 grid grid-cols-1 mb-5 ${createObservationRssStyles.headerBar}`}>
                <h1 className='text-4xl text-center text-white'>Add Observation - Registration/Reception/Support Staff</h1>
            </div>
            <div className='flex flex-col justify-left'>
                {(
                    (Object.keys(employee).length === 0) ? <LoadingSpinner /> :
                        <>
                            <h1 className='text-xl'><strong>Name:</strong> {employee.attributes.first_name} {employee.attributes.last_name}</h1>
                            <p className='text-lg'><strong>Email:</strong> {employee.attributes.email}</p>
                            <p className='text-lg'><strong>Date: </strong> {todaysDate}</p>
                            <p className='text-lg'><strong>Time: </strong> {timeNow}</p>
                        </>
                )}
            </div>
            <div className='grid grid-cols-6 gap-4'>
                <div className='col-span-6 mt-5'>
                    <Form
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        labelCol={{ flex: '255px' }}
                        labelWrap
                        labelAlign="left"
                        layout="horizontal"
                        className='w-full'
                    >
                        <div className="shadow sm:rounded-md sm:overflow-hidden">
                            <div className="bg-white dark:bg-gray-800 p-4">
                                <div className="flex items-center justify-between">
                                    <h3 className="text-2xl font-light text-gray-900 dark:text-white">
                                        Patient/Family/Vistor Greeting
                                    </h3>
                                </div>
                            </div>

                            <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                                <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-12 sm:col-span-2">
                                        <Form.Item
                                            label="Acknowledged individual and made eye contact?"
                                            name="eye_contact"
                                            className='col-span-6 sm:col-span-2'
                                            rules={[{ required: true, message: 'Please select feedback type!' }]}
                                        >
                                            <Select
                                                options={[
                                                    {
                                                        value: 'yes',
                                                        label: 'Yes',
                                                    },
                                                    {
                                                        value: 'no',
                                                        label: 'No',
                                                    },
                                                    {
                                                        value: 'n/a',
                                                        label: 'Not Applicable',
                                                    },
                                                ]}
                                            />
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                            <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                                <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-12 sm:col-span-2">
                                        <Form.Item
                                            label="Asked, “How can I help you?”"
                                            name="can_help"
                                            className='col-span-6 sm:col-span-2'
                                            rules={[{ required: true, message: 'Please select feedback type!' }]}
                                        >
                                            <Select
                                                options={[
                                                    {
                                                        value: 'yes',
                                                        label: 'Yes',
                                                    },
                                                    {
                                                        value: 'no',
                                                        label: 'No',
                                                    },
                                                    {
                                                        value: 'n/a',
                                                        label: 'Not Applicable',
                                                    },
                                                ]}
                                            />
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-white dark:bg-gray-800 p-4">
                                <div className="flex items-center justify-between">
                                    <h3 className="text-2xl font-light text-gray-900 dark:text-white">
                                        Interaction
                                    </h3>
                                </div>
                            </div>
                            <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                                <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-12 sm:col-span-2">
                                        <Form.Item
                                            label="Introduced self by name and role?"
                                            name="introduced"
                                            className='col-span-6 sm:col-span-2'
                                            rules={[{ required: true, message: 'Please select feedback type!' }]}
                                        >
                                            <Select
                                                options={[
                                                    {
                                                        value: 'yes',
                                                        label: 'Yes',
                                                    },
                                                    {
                                                        value: 'no',
                                                        label: 'No',
                                                    },
                                                    {
                                                        value: 'n/a',
                                                        label: 'Not Applicable',
                                                    },
                                                ]}
                                            />
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                            <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                                <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-12 sm:col-span-2">
                                        <Form.Item
                                            label="Explained documents/process and/or answered questions?"
                                            name="explained"
                                            className='col-span-6 sm:col-span-2'
                                            rules={[{ required: true, message: 'Please select feedback type!' }]}
                                        >
                                            <Select
                                                options={[
                                                    {
                                                        value: 'yes',
                                                        label: 'Yes',
                                                    },
                                                    {
                                                        value: 'no',
                                                        label: 'No',
                                                    },
                                                    {
                                                        value: 'n/a',
                                                        label: 'Not Applicable',
                                                    },
                                                ]}
                                            />
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-white dark:bg-gray-800 p-4">
                                <div className="flex items-center justify-between">
                                    <h3 className="text-2xl font-light text-gray-900 dark:text-white">
                                        Close
                                    </h3>
                                </div>
                            </div>
                            <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                                <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-12 sm:col-span-2">
                                        <Form.Item
                                            label="Asked, “What else can I help you with today?”"
                                            name="what_else"
                                            className='col-span-6 sm:col-span-2'
                                            rules={[{ required: true, message: 'Please select feedback type!' }]}
                                        >
                                            <Select
                                                options={[
                                                    {
                                                        value: 'yes',
                                                        label: 'Yes',
                                                    },
                                                    {
                                                        value: 'no',
                                                        label: 'No',
                                                    },
                                                    {
                                                        value: 'n/a',
                                                        label: 'Not Applicable',
                                                    },
                                                ]}
                                            />
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                            <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                                <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-12 sm:col-span-2">
                                        <Form.Item
                                            label="“Thank you for choosing Summa.”"
                                            name="thank_you"
                                            className='col-span-6 sm:col-span-2'
                                            rules={[{ required: true, message: 'Please select feedback type!' }]}
                                        >
                                            <Select
                                                options={[
                                                    {
                                                        value: 'yes',
                                                        label: 'Yes',
                                                    },
                                                    {
                                                        value: 'no',
                                                        label: 'No',
                                                    },
                                                    {
                                                        value: 'n/a',
                                                        label: 'Not Applicable',
                                                    },
                                                ]}
                                            />
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-white dark:bg-gray-800 p-4">
                                <div className="flex items-center justify-between">
                                    <h3 className="text-2xl font-light text-gray-900 dark:text-white">
                                        Miscellaneous
                                    </h3>
                                </div>
                            </div>
                            <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                                <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-12 sm:col-span-2">
                                        <Form.Item
                                            label="Avoided cell phone use?"
                                            name="cell_use"
                                            className='col-span-6 sm:col-span-2'
                                            rules={[{ required: true, message: 'Please select feedback type!' }]}
                                        >
                                            <Select
                                                options={[
                                                    {
                                                        value: 'yes',
                                                        label: 'Yes',
                                                    },
                                                    {
                                                        value: 'no',
                                                        label: 'No',
                                                    },
                                                    {
                                                        value: 'n/a',
                                                        label: 'Not Applicable',
                                                    },
                                                ]}
                                            />
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                            <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                                <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-12 sm:col-span-2">
                                        <Form.Item
                                            label="Followed dress code?"
                                            name="dress_code"
                                            className='col-span-6 sm:col-span-2'
                                            rules={[{ required: true, message: 'Please select feedback type!' }]}
                                        >
                                            <Select
                                                options={[
                                                    {
                                                        value: 'yes',
                                                        label: 'Yes',
                                                    },
                                                    {
                                                        value: 'no',
                                                        label: 'No',
                                                    },
                                                    {
                                                        value: 'n/a',
                                                        label: 'Not Applicable',
                                                    },
                                                ]}
                                            />
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                            <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                                <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-12 sm:col-span-2">
                                        <Form.Item
                                            label="Protected PHI?"
                                            name="protected_phi"
                                            className='col-span-6 sm:col-span-2'
                                            rules={[{ required: true, message: 'Please select feedback type!' }]}
                                        >
                                            <Select
                                                options={[
                                                    {
                                                        value: 'yes',
                                                        label: 'Yes',
                                                    },
                                                    {
                                                        value: 'no',
                                                        label: 'No',
                                                    },
                                                    {
                                                        value: 'n/a',
                                                        label: 'Not Applicable',
                                                    },
                                                ]}
                                            />
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-white dark:bg-gray-800 p-4">
                                <div className="flex items-center justify-between">
                                    <h3 className="text-2xl font-light text-gray-900 dark:text-white">
                                        Registration-Specific
                                    </h3>
                                </div>
                            </div>
                            <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                                <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-12 sm:col-span-2">
                                        <Form.Item
                                            label="Followed appropriate POS cash collection script?"
                                            name="followed_script"
                                            className='col-span-6 sm:col-span-2'
                                            rules={[{ required: true, message: 'Please select feedback type!' }]}
                                        >
                                            <Select
                                                options={[
                                                    {
                                                        value: 'yes',
                                                        label: 'Yes',
                                                    },
                                                    {
                                                        value: 'no',
                                                        label: 'No',
                                                    },
                                                    {
                                                        value: 'n/a',
                                                        label: 'Not Applicable',
                                                    },
                                                ]}
                                            />
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                            <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                                <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-12 sm:col-span-2">
                                        <Form.Item
                                            label="Comments"
                                            name="comments"
                                            className='col-span-6 sm:col-span-2'
                                            rules={[{ required: true, message: 'Please enter comments!' }]}
                                        >
                                            <TextArea rows={4} placeholder='Elaborate' />
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                            <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                                <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-12 sm:col-span-2">
                                        <Form.Item
                                            label="Next Follow Up Date"
                                            name="needs_attention"
                                            className='col-span-6 sm:col-span-2'
                                        >
                                            <DatePicker />
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                            <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                                <div className="grid grid-cols-6 gap-6">
                                    <div className="col-span-12 sm:col-span-2">
                                        <Form.Item
                                            wrapperCol={{
                                                offset: 8,
                                                span: 16,
                                            }}
                                        >
                                            <Button
                                                className='bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 rounded'
                                                htmlType="submit"
                                            >
                                                Submit
                                            </Button>
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </>
    )
}
