import React from "react";
import newHospitalStyles from "./NewHospital.module.css";
import { Button, Col, Form, Input, notification, Row } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUserStore } from "../../../../context/UserStore";

export default function NewHospital() {
  const navigate = useNavigate();
  const { TextArea } = Input;
  const [initialValues, setInitialValues] = React.useState({});
  const user = useUserStore((state) => state.user);
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({});

  const createHospital = async (values) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/admin/hospitals`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({ hospital: values }),
        type: "json",
      },
    )
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((response) => {
        notification.success({
          message: response.message,
          description: response.message,
        });
        navigate(`/admin/hospitals`);
      })
      .catch((error) => {
        notification.error({
          message: error.errors,
        });
      });
  };

  const onFieldsChange = (changedFields, allFields) => {
    changedFields.map((field) => {
      setFormData({ ...formData, [field.name[0]]: field.value });
    });
  };

  const onFinish = (values) => {
    createHospital(formData);
  };

  const onFinishFailed = (errorInfo) => {
    let error_message = errorInfo.errorFields.map((item) => {
      return item.errors[0];
    });

    notification["error"]({
      message: "Failed to create question",
      description: `${error_message}. `,
    });
  };

  return (
    <>
      <div
        className={`py-5 grid grid-cols-1 mb-5 ${newHospitalStyles.headerBar}`}
      >
        <h1 className="text-4xl text-center text-white">New Hospital</h1>
      </div>
      <Row>
        <Col span={20}>
          <Form
            form={form}
            layout="vertical"
            initialValues={initialValues}
            onFieldsChange={onFieldsChange}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Row>
              <Col md={10} xs={24}>
                <Form.Item label="Name">
                  <Form.Item
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Please input Hospital name!",
                      },
                    ]}
                  >
                    <Input placeholder="Hospital Name" />
                  </Form.Item>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md={10} xs={24}>
                <Form.Item label="Street Address">
                  <Form.Item
                    name="address"
                    rules={[
                      {
                        required: true,
                        message: "Please input Street Address!",
                      },
                    ]}
                  >
                    <Input placeholder="Street Address" />
                  </Form.Item>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md={10} xs={24}>
                <Form.Item label="City">
                  <Form.Item
                    name="city"
                    rules={[{ required: true, message: "Please input City!" }]}
                  >
                    <Input placeholder="City" />
                  </Form.Item>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md={10} xs={24}>
                <Form.Item label="State">
                  <Form.Item
                    name="state"
                    rules={[{ required: true, message: "Please input State!" }]}
                  >
                    <Input placeholder="State" />
                  </Form.Item>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md={10} xs={24}>
                <Form.Item label="Zip Code">
                  <Form.Item
                    name="zip"
                    rules={[
                      { required: true, message: "Please input Zip Code!" },
                    ]}
                  >
                    <Input placeholder="Zip Code" />
                  </Form.Item>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col md={10} xs={24}>
                <Form.Item label="Subdomain">
                  <Form.Item
                    name="subdomain"
                    rules={[
                      { required: true, message: "Please input Subdomain!" },
                    ]}
                  >
                    <Input placeholder="Subdomain" />
                  </Form.Item>
                </Form.Item>
              </Col>
            </Row>
            <Row justify="space-between">
              <Col md={10} xs={24}>
                <Form.Item>
                  <Form.Item>
                    <Button
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 rounded"
                      htmlType="submit"
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  );
}
