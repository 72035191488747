import React from 'react';
import helpStyles from './Help.module.css';
import editUserStyles from "../User/EditUser.module.css";

export default function Help() {
    return (
        <>
            <div className={`py-8 mb-10 flex flex-col items-center justify-center bg-gradient-to-r from-[#e87c10] to-[#f59222] rounded-lg shadow-lg ${helpStyles.headerBar}`}>
                <h1 className='text-4xl md:text-4xl text-center font-bold text-white tracking-wide'>MyTEAM Support</h1>
            </div>
            <div className='grid grid-cols-1 px-5'>
                <p>Please read our FAQs below. If you may not find what you need, send us an email at <a className='decoration-sky-500/30 font-bold py-2 rounded' href='mailto:support@stampandchase.com'>support@stampandchase.com</a> and our support team will get back to you within 2 business days.</p>
            </div>
            <div className='grid grid-cols-1 py-8 px-5'>
                <h2 className='text-3xl'>Frequently Asked Questions</h2>
                <hr />
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-4 px-5'>
                <div>
                    <h2 className='text-2xl'>What internet browsers does MyTEAM support?</h2>
                    <p>MyTEAM is designed to function on the latest versions of desktop/laptop browsers: Google Chrome, Apple Safari, Mozilla Firefox, and Internet Explorer. On iPhones and iPads, use the standard Safari browser that comes with your operating system. On Android phones, use the standard Chrome browser.</p>
                    <h2 className='text-2xl'>I forgot my password or my password stopped working. How do I get back into MyTEAM?</h2>
                    <p>You may reset your password by clicking or tapping the "Forgot Password?" link on the login page. It will send you an email which will contain a link. Click the link to change your password.</p>
                    <h2 className='text-2xl'>Why do I keep getting logged out of MyTEAM?</h2>
                    <p>For security reasons, MyTEAM will automatically log you our after 15 minutes of inactivity.</p>
                </div>
                <div>
                    <h2 className='text-2xl'>Why am I not able to see all of my employees or team members?</h2>
                    <p>If you are missing any employees, you may add them by going to Mentor: My Employees and clicking the "+ Add New Employee" button at the top left of the screen. Please note, that MyTEAM updates your list of employees every two weeks.  </p>
                    <h2 className='text-2xl'>One of my employees left. How can I remove them from MyTEAM?</h2>
                    <p>Go to Mentor: My Employees and use the search feature to find the employee in question. Click or tap the trash can icon next to their record to deactivate them. Their information will remain in the system, but they will no longer be available to select in various forms throughout MyTEAM.</p>
                </div>
            </div>
        </>
    )
}
