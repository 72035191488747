import React from "react";
import editUnitStyles from "./EditUnit.module.css";
import {
  Button,
  Col,
  Form,
  Input,
  notification,
  Row,
  Skeleton,
  Space,
} from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { useEffect, useState } from "react";
import { useUserStore } from "../../../../context/UserStore";
import { useNavigate, useParams } from "react-router-dom";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

export default function EditUnit() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({});
  const [initialValues, setInitialValues] = React.useState({});
  const [unit, setUnit] = React.useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const me = useUserStore((state) => state.me);
  const user = useUserStore((state) => state.user);
  const { TextArea } = Input;
  const { id } = useParams();

  const disableUnit = async (id) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/admin/hospitals/${me.attributes.hospital_id}/units/${id}/disable`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        type: "json",
      },
    )
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error(response.statusText);
        }
      })
      .then((response) => {
        notification.success({
          message: response.message,
          description: response.message,
        });
      })
      .catch((error) => {
        notification.error({
          message: error.errors,
        });
        console.log(error);
      });
  };

  const loadUnit = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/admin/hospitals/${me.attributes.hospital_id}/units/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        type: "json",
      },
    )
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error(response.statusText);
        }
      })
      .then((response) => {
        setUnit(response.unit.data.attributes);
        setInitialValues(response.unit.data.attributes);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateUnit = async (values) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/admin/hospitals/${me.attributes.hospital_id}/units/${id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({ unit: values }),
        type: "json",
      },
    )
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((response) => {
        notification.success({
          message: response.message,
          description: response.message,
        });
        navigate(`/admin/units/${id}`);
      })
      .catch((error) => {
        notification.error({
          message: error.errors,
        });
      });
  };

  const onFieldsChange = (changedFields, allFields) => {
    changedFields.map((field) => {
      setFormData({ ...formData, [field.name[0]]: field.value });
    });
  };

  const onFinish = (values) => {
    updateUnit(formData);
  };

  const onFinishFailed = (errorInfo) => {
    let error_message = errorInfo.errorFields.map((item) => {
      return item.errors[0];
    });

    notification["error"]({
      message: "Failed to edit Unit",
      description: `${error_message}. `,
    });
  };

  useEffect(() => {
    loadUnit();
  }, []);

  return (
    <>
      <div className={`py-5 grid grid-cols-1 mb-5 ${editUnitStyles.headerBar}`}>
        <h1 className="text-4xl text-center text-white">Edit Unit</h1>
      </div>
      <Skeleton loading={isLoading} active>
        {isLoading == false && (
          <>
            <PageHeader
              onBack={() => navigate(-1)}
              ghost={false}
              title={`${unit.name}`}
              extra={[
                <Space size="small">
                  <Button
                    key="back"
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 rounded"
                    onClick={() => navigate("/admin/units")}
                  >
                    Back
                  </Button>
                  <Button
                    key="edit"
                    className="bg-orange-500 hover:bg-orange-700 font-bold rounded"
                    onClick={() => navigate(`/admin/units/${id}/edit`)}
                  >
                    <EditOutlined />
                  </Button>
                  <Button
                    key="delete"
                    className="bg-red-500 hover:bg-red-700 font-bold rounded"
                    onClick={() => disableUnit(id)}
                  >
                    <DeleteOutlined />
                  </Button>
                </Space>,
              ]}
            ></PageHeader>
            <Row>
              <Col span={20}>
                <Form
                  form={form}
                  layout="vertical"
                  initialValues={initialValues}
                  onFieldsChange={onFieldsChange}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                >
                  <Row>
                    <Col md={10} xs={20}>
                      <Form.Item
                        name="name"
                        label="Unit Name"
                        rules={[
                          {
                            required: true,
                            message: "Please enter a Unit Name",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={10} xs={20}>
                      <Form.Item name="description" label="Unit Description">
                        <TextArea />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row justify="space-between">
                    <Col md={10} xs={24}>
                      <Form.Item>
                        <Form.Item>
                          <Button
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 rounded"
                            htmlType="submit"
                          >
                            Submit
                          </Button>
                        </Form.Item>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </>
        )}
      </Skeleton>
    </>
  );
}
