import React, { useEffect, useState } from 'react'
import { Image } from 'antd';
import navBarStyles from './NavBar.module.css';
import { useUserStore } from '../../context/UserStore';
import { useNavigate } from 'react-router-dom';

export default function NavBar(props) {
    const navigate = useNavigate();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const user = useUserStore((state) => state.user);
    const me = useUserStore((state) => state.me);
    const subdomain = window.location.hostname.split('.')[0];
    const [logo, setLogo] = useState('');

    // Find out what hospital the user is associated with based upon the subdomain
    const getHospital = async (subdomain) => {
        return fetch(`${process.env.REACT_APP_API_URL}/api/v1/hospitals/find_by_subdomain/${subdomain}`, {
            method: "get",
            headers: {
                'Accept': 'application/json',
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    throw new Error(res);
                }
            })
            .then((json) => {
                setLogo(`${subdomain}.png`);
                localStorage.setItem('hospital', JSON.stringify(json.hospital.data));
            })
            .catch((err) => {
                setLogo('MyTEAM_original_reversed.png');
            });
    }

    useEffect(() => {
        getHospital(subdomain);
    }, []);

    return (
        <nav className={`relative flex flex-wrap items-center justify-between px-2 py-3 ${navBarStyles.bgStyle}`}>
            <div className="container mx-auto flex flex-wrap items-center justify-between px-4">
                <div className="flex justify-between w-full relative lg:w-auto lg:static lg:block lg:justify-start">
                    <a className="inline-block py-2 mr-4 text-sm font-bold leading-relaxed uppercase whitespace-nowrap text-white" href="/">
                        <Image width={175} preview={false} src={`/assets/images/hospitals/${logo}`} alt="Logo" />
                    </a>
                    <button className="text-white cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none" onClick={() => setIsMenuOpen(!isMenuOpen)}>
                        <i className="fas fa-bars"></i>
                    </button>
                </div>
            </div>
        </nav>
    )
}
