import React from 'react';
import cascadingGoalsStyles from './CascadingGoals.module.css';

export default function CascadingGoals() {
    return (
        <>
        <div className={`py-8 mb-8 flex flex-col items-center justify-center space-y-5 ${cascadingGoalsStyles.headerBar}`} style={{ background: 'linear-gradient(135deg, #ACBD37 0%, #D1E075 100%)', color: '#fff' }}>
            <img className="w-40 rounded-full shadow-xl" src='/assets/images/goals-icon.png' alt="Goals Icon" />
            <h1 className='text-center text-4xl font-bold tracking-wider'>Cascading Goals</h1>
            <p className='text-center text-lg italic max-w-xl'>“If you don’t know where you are going, any road will get you there.”<br />– Lewis Carroll</p>
        </div>
        <div className='max-w-5xl mx-auto p-8 bg-white shadow-2xl rounded-lg'>
            <div className='mb-8'>
                <h4 className='text-xl font-semibold mb-4'>Purpose</h4>
                <p className='text-base text-gray-700 leading-relaxed'>Specific, compelling goals are foundational to providing clear direction for your team.</p>
            </div>
            <div className='mb-8'>
                <h4 className='text-base font-semibold mb-4'>Department Goals Serve Three Major Purposes:</h4>
                <ul className="list-disc list-inside text-base text-gray-700 leading-relaxed">
                    <li>Set direction and priorities</li>
                    <li>Motivate teams to reach shared goals</li>
                    <li>Assess and control performance</li>
                </ul>
            </div>
            <div className='mb-8'>
                <h4 className='text-base font-semibold mb-4'>For Individual Team Members, Goals Should Answer Three Fundamental Questions:</h4>
                <ul className="list-disc list-inside text-base text-gray-700 leading-relaxed">
                    <li>What do I need to accomplish or change?</li>
                    <li>Why is this important?</li>
                    <li>How am I doing?</li>
                </ul>
            </div>
            <div className='mb-8'>
                <h4 className='text-base font-semibold mb-4'>S.M.A.R.T. Goals</h4>
                <ul className="list-none list-inside text-base text-gray-700 leading-relaxed">
                    <li><strong>S - Specific:</strong> What are we trying to accomplish? In what timeframe? How will we measure success?</li>
                    <li><strong>M - Meaningful:</strong> Why is this goal important to our team and our patients?</li>
                    <li><strong>A - Agreed Upon:</strong> Does everyone understand the goal and their role in success?</li>
                    <li><strong>R - Realistic:</strong> Do we have the resources to accomplish the goals in the established timeframe?</li>
                    <li><strong>T - Tracked:</strong> How will we report our progress, celebrating success, and making course corrections along the way?</li>
                </ul>
            </div>
        </div>
    </>
    )
}
