import React from "react";
import {
  Button,
  Card,
  Col,
  Input,
  notification,
  Row,
  Skeleton,
  Space,
  Table,
  Typography,
} from "antd";
import employeesStyles from "./Employees.module.css";
import { useEffect, useState } from "react";
import { useUserStore } from "../../../context/UserStore";
import { useNavigate } from "react-router-dom";
import {
  SearchOutlined,
  EditOutlined,
  CheckOutlined,
  DeleteOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";

const { Text, Title } = Typography;

export default function Employees() {
  const navigate = useNavigate();
  const [hospitals, setHospitals] = useState([]);
  const [employees, setEmployees] = React.useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const me = useUserStore((state) => state.me);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [total, setTotal] = useState(1);
  const user = useUserStore((state) => state.user);

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <div style={{ padding: 8 }}>
            <Input
              autoFocus
              placeholder="Search"
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
              value={selectedKeys}
              style={{ marginBottom: 8, display: "block" }}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropDown: false });
              }}
            />
            <Space space="small">
              <Button
                onClick={() => {
                  confirm();
                }}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 rounded"
                icon={<SearchOutlined />}
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button
                onClick={() => {
                  clearFilters();
                }}
                className="bg-orange-500 hover:bg-orange-700 font-bold px-4 rounded"
              >
                Reset
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) =>
        record.attributes.full_name.toLowerCase().includes(value.toLowerCase()),
      render: (id, record) => {
        return record.attributes.full_name;
      },
    },
    {
      title: "Hospital",
      dataIndex: "hospital",
      key: "hospital",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <div style={{ padding: 8 }}>
            <Input
              autoFocus
              placeholder="Search"
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
              value={selectedKeys}
              style={{ marginBottom: 8, display: "block" }}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropDown: false });
              }}
            />
            <Space>
              <Button
                onClick={() => {
                  confirm();
                }}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 rounded"
                icon={<SearchOutlined />}
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button
                onClick={() => {
                  clearFilters();
                }}
                className="bg-orange-500 hover:bg-orange-700 font-bold rounded"
              >
                Reset
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) =>
        record.attributes.hospital_name
          .toLowerCase()
          .includes(value.toLowerCase()),
      render: (id, record) => {
        return record.attributes.hospital_name;
      },
    },
    {
      title: "Active",
      dataIndex: "attributes.active",
      filters: [
        {
          text: "Active",
          value: true,
        },
        {
          text: "Inactive",
          value: false,
        },
      ],
      onFilter: (value, record) => record.attributes.active === value,
      defaultFilteredValue: ["true"],
      render: (id, record) => {
        return record.attributes.active ? (
          <Text type="success">Active</Text>
        ) : (
          <Text type="danger">Inactive</Text>
        );
      },
      width: "5%",
    },
    {
      title: "Last Active",
      dataIndex: "lastActive",
      key: "lastActive",
      render: (id, record) => {
        return `${dayjs(record.attributes.last_active).format("MM/DD/YYYY")}`;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (id, record) => (
        <Space size="small">
          <Button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 rounded"
            onClick={() => navigate(`/admin/employees/${record.id}`)}
          >
            View
          </Button>
          <Button
            className="bg-orange-500 hover:bg-orange-700 font-bold px-4 rounded"
            onClick={() => navigate(`/admin/employees/${record.id}/edit`)}
          >
            <EditOutlined />
          </Button>
          {record.attributes.active ? (
            <Button
              className="bg-red-500 hover:bg-red-700 font-bold px-4 rounded"
              onClick={() => toggleActive(record.id)}
            >
              <DeleteOutlined />
            </Button>
          ) : (
            <Button
              className="bg-red-500 hover:bg-red-700 font-bold rounded"
              type="default"
              onClick={() => toggleActive(record.id)}
            >
              <CheckOutlined />
            </Button>
          )}
        </Space>
      ),
    },
  ];

  const toggleActive = async (id) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/admin/employees/${id}/toggle_active`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user.token}`,
          "Content-Type": "application/json",
        },
        type: "json",
      },
    );
    if (!response.ok) {
      setIsLoading(false);
      return;
    }

    const data = await response.json();

    // Replace the employee with the updated employee
    // Set the employees state to the new data
    const newData = employees.map((item) => {
      if (item.id === data.employee.data.id) {
        return {
          ...item,
          ...data.employee.data,
        };
      } else {
        return item;
      }
    });
    setEmployees(newData);
  };

  const loadEmployees = async (page, pageSize) => {
    let fetchurl = "";
    if (me.attributes.role == "Administrator") {
      fetchurl = `${process.env.REACT_APP_API_URL}/api/v1/admin/hospitals/${me.attributes.hospital_id}/employees`;
    } else {
      fetchurl = `${process.env.REACT_APP_API_URL}/api/v1/admin/employees`;
    }

    const response = await fetch(`${fetchurl}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      type: "json",
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error(response.statusText);
        }
      })
      .then((response) => {
        const newData = response.employees.data.map((item) => {
          return {
            ...item,
            key: item.id,
          };
        });
        if (me.attributes.role == "Administrator") {
          setEmployees(
            newData.filter(
              (item) =>
                item.attributes.hospital_id === me.attributes.hospital_id,
            ),
          );
        } else {
          setEmployees(newData);
        }

        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    loadEmployees();
  }, []);

  return (
    <>
      <div
        className={`py-5 grid grid-cols-1 mb-5 ${employeesStyles.headerBar}`}
      >
        <h1 className="text-4xl text-center text-white">Employees</h1>
      </div>
      <Row>
        <Col span={24}>
          <div className="max-w-sm rounded overflow-hidden mb-4">
            <div className="flex flex-row justify-center space-x-4 md:justify-start">
              <Button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 rounded"
                disabled={me.attributes.role == "Administrator"}
                icon={<UploadOutlined />}
                onClick={() => navigate("/admin/employees/import")}
              >
                Import Employees
              </Button>
              <Button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 rounded"
                icon={<PlusOutlined />}
                onClick={() => navigate("/admin/employees/new")}
              >
                Add Employee
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <Skeleton loading={isLoading} active>
        <div className="overflow-x-auto">
          <Table
            dataSource={employees}
            columns={columns}
            loading={isLoading}
            pagination={{
              current: page,
              pageSize: pageSize,
              total: total,
              onChange: (page, pageSize) => {
                setPage(page);
                setPageSize(pageSize);
                loadEmployees(page, pageSize);
              },
            }}
            scroll={{ x: true }}
            rowKey="id"
          />
        </div>
      </Skeleton>
    </>
  );
}
