import React from "react";
import { Button, Card, Col, Row } from "antd";
import { ReloadOutlined, UserOutlined } from "@ant-design/icons";
import dashboardStyles from "./Dashboard.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import { useUserStore } from "../../context/UserStore";

export default function Dashboard() {
  const navigate = useNavigate();
  const [needFollowUp, setNeedFollowUp] = useState({});
  const [mentors, setMentors] = useState({});
  const [needsAttention, setNeedsAttention] = useState(0);
  const [needMentors, setNeedMentors] = useState(0);
  const user = useUserStore((state) => state.user);

  const loadInteractions = async (page, pageSize) => {
    typeof page === "object" || typeof page === "undefined"
      ? (page = 1)
      : (page = page);
    typeof pageSize === "undefined" ? (pageSize = 25) : (pageSize = pageSize);
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/users/interactions/dashboard`,
      {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        type: "json",
      },
    )
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((response) => {
        setNeedFollowUp(response.interactions);
        setNeedsAttention(response.needs_attention);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const loadMentors = async (page, pageSize) => {
    typeof page === "object" || typeof page === "undefined"
      ? (page = 1)
      : (page = page);
    typeof pageSize === "undefined" ? (pageSize = 25) : (pageSize = pageSize);
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/users/mentors/dashboard`,
      {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        type: "json",
      },
    )
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((response) => {
        setMentors(response.mentors);
        setNeedMentors(response.needs_mentor);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    loadInteractions({});
    loadMentors({});
  }, []);

  return (
    <>
        <div
            className={`py-8 mb-10 flex flex-col items-center justify-center bg-gradient-to-r from-[#e87c10] to-[#f59222] rounded-lg shadow-lg ${dashboardStyles.headerBar}`}
        >
            <h1 className="text-4xl md:text-4xl text-center font-bold text-white tracking-wide">
                My Dashboard
            </h1>
        </div>
        <div
            className={`flex flex-col gap-6 md:flex-row justify-between items-center p-6 rounded-lg shadow-lg`}
            style={{ backgroundColor: '#e2f3e6' }} // Close to #dff0d8
        >
            <div>
                <h3 className="text-xl font-semibold px-5 text-center md:text-left">
                    Rounds Last 7 Days
                </h3>
            </div>
            <button
                onClick={() => navigate(`/teach/integrated-rounds/needs_follow_up`)}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 py-2 text-sm rounded-full shadow-md transition-transform transform hover:scale-105"
            >
                <ReloadOutlined className="inline-block mr-2" /> {needsAttention} need
                follow up
            </button>
        </div>

        <div
            className={`flex flex-col gap-6 md:flex-row justify-between items-center mb-8 p-6 rounded-lg shadow-lg`}
            style={{ backgroundColor: '#e2f3e6' }} // Close to #dff0d8
        >
            {["Patient", "Family", "Staff", "Physician"].map((category) => (
                <div
                    key={category}
                    className="flex flex-col items-center justify-center bg-[#f4f4f4] p-4 rounded-lg shadow-md transition-transform transform hover:scale-105"
                >
                    <h3 className="text-4xl font-bold text-gray-800 text-center">
                        {needFollowUp[category] || 0}
                    </h3>
                    <p className="text-md text-center text-gray-600 mt-2">
                        {!needFollowUp ? "Loading..." : category}
                    </p>
                </div>
            ))}
        </div>

        <div
            className={`flex flex-col gap-6 md:flex-row justify-between items-center p-6 rounded-lg shadow-lg`}
            style={{ backgroundColor: '#e2f3e6' }} // Close to #dff0d8
        >
            <div>
                <h3 className="text-xl font-semibold px-5 text-center md:text-left">
                    Mentoring Last 30 Days
                </h3>
            </div>
            <button
                onClick={() => navigate(`/my-employees/mentor/review`)}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 py-2 text-sm rounded-full shadow-md transition-transform transform hover:scale-105"
            >
                <ReloadOutlined className="inline-block mr-2" /> {needMentors} need
                attention
            </button>
        </div>

        <div
            className={`flex flex-col gap-6 md:flex-row justify-between items-center mb-8 p-6 rounded-lg shadow-lg`}
            style={{ backgroundColor: '#e2f3e6' }} // Close to #dff0d8
        >
            {["Daily Coaching", "Development Dialogue", "Observation"].map((category) => (
                <div
                    key={category}
                    className="flex flex-col items-center justify-center bg-[#f4f4f4] p-4 rounded-lg shadow-md transition-transform transform hover:scale-105"
                >
                    <h3 className="text-4xl font-bold text-gray-800 text-center">
                        {mentors[category] || 0}
                    </h3>
                    <p className="text-md text-center text-gray-600 mt-2">
                        {!mentors ? "Loading..." : category}
                    </p>
                </div>
            ))}
        </div>
    </>
  );
}
