import React from "react";
import createObservationStyles from "./CreateObservation.module.css";
import {
  Button,
  DatePicker,
  Form,
  Input,
  message,
  notification,
  Radio,
  Select,
} from "antd";
import dayjs from "dayjs";
import LoadingSpinner from "../../../../Shared/LoadingSpinner/LoadingSpinner";
import { useNavigate, useParams } from "react-router-dom";
import { useUserStore } from "../../../../../context/UserStore";

const { useState, useEffect } = React;

export default function CreateObservation() {
  const navigate = useNavigate();
  const todaysDate = dayjs(new Date()).format("MM/DD/YYYY");
  const timeNow = dayjs(new Date()).format("HH:mm");
  const { employeeId, mentorId } = useParams();
  const [employee, setEmployee] = useState({});
  const [feedback, setFeedback] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { TextArea } = Input;
  const user = useUserStore((state) => state.user);

  const customFetch = async (page, pageSize) => {
    setIsLoading(true);
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/users/employees/${employeeId}`,
      {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        type: "json",
      },
    )
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((response) => {
        setEmployee(response.employee.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onFinish = (values) => {
    console.log("Success:", values);
    createMentor({
      mentor: {
        mentor_type_id: 1,
        mentor_type_name: "Observation",
        time_legible: timeNow,
        date_legible: todaysDate,
        respectful_entry: values.respectful_entry,
        respectful_entry_note: values.respectful_entry_note,
        encourage_questions: values.encourage_questions,
        encourage_questions_note: values.encourage_questions_note,
        teachback: values.teachback,
        teachback_note: values.teachback_note,
        listen: values.listen,
        listen_note: values.listen_note,
        followup_note: values.followup_note,
        needs_attention: values.needs_attention,
      },
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const createMentor = async (values) => {
    return fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/users/employees/${employeeId}/mentors`,
      {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify(values),
        type: "json",
      },
    )
      .then((response) => {
        if (!response.ok) {
          message.error(response.statusText);
        }
        return response.json();
      })
      .then((response) => {
        message.success("Feedback successfully created");
        navigate(`/my-employees/mentor/review`);
      })
      .catch((error) => {
        message.error(error);
      });
  };

  useEffect(() => {
    customFetch({});
  }, []);

  return (
    <>
      <div
        className={`py-5 grid grid-cols-1 mb-5 ${createObservationStyles.headerBar}`}
      >
        <h1 className="text-4xl text-center text-white">Add Observation</h1>
      </div>
      <div className="flex flex-col justify-left">
        {Object.keys(employee).length === 0 ? (
          <LoadingSpinner />
        ) : (
          <>
            <h1 className="text-xl">
              <strong>Name:</strong> {employee.attributes.first_name}{" "}
              {employee.attributes.last_name}
            </h1>
            <p className="text-lg">
              <strong>Email:</strong> {employee.attributes.email}
            </p>
            <p className="text-lg">
              <strong>Date: </strong> {todaysDate}
            </p>
            <p className="text-lg">
              <strong>Time: </strong> {timeNow}
            </p>
          </>
        )}
      </div>
      <div className="grid grid-cols-6 gap-4">
        <div className="col-span-6 mt-5">
          <Form
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            labelCol={{ flex: "65px" }}
            labelWrap
            labelAlign="left"
            layout="vertical"
            className="w-full"
          >
            <div className="shadow sm:rounded-md sm:overflow-hidden">
              <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-12 sm:col-span-2">
                    <Form.Item
                      label="Did the employee enter the patient's room respectfully (knocked and asked to come in, used the patient's preferred name, introduced his/her self by name and role, etc.)"
                      name="respectful_entry"
                      className="col-span-6 sm:col-span-2"
                      rules={[
                        {
                          required: true,
                          message: "Please select feedback type!",
                        },
                      ]}
                    >
                      <Select
                        options={[
                          {
                            value: "yes",
                            label: "Yes",
                          },
                          {
                            value: "no",
                            label: "No",
                          },
                          {
                            value: "n/a",
                            label: "Not Applicable",
                          },
                        ]}
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-12 sm:col-span-2">
                    <Form.Item
                      label="Comments:"
                      name="respectful_entry_note"
                      className="col-span-6 sm:col-span-2"
                      rules={[
                        { required: true, message: "Please enter comments!" },
                      ]}
                    >
                      <TextArea rows={4} placeholder="Elaborate" />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-12 sm:col-span-2">
                    <Form.Item
                      label="Did the employee encourage questions from the patient, probing for concerns or fears?"
                      name="encourage_questions"
                      className="col-span-6 sm:col-span-2"
                      rules={[
                        {
                          required: true,
                          message: "Please select feedback type!",
                        },
                      ]}
                    >
                      <Select
                        options={[
                          {
                            value: "yes",
                            label: "Yes",
                          },
                          {
                            value: "no",
                            label: "No",
                          },
                          {
                            value: "n/a",
                            label: "Not Applicable",
                          },
                        ]}
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-12 sm:col-span-2">
                    <Form.Item
                      label="Comments:"
                      name="encourage_questions_note"
                      className="col-span-6 sm:col-span-2"
                      rules={[
                        { required: true, message: "Please enter comments!" },
                      ]}
                    >
                      <TextArea rows={4} placeholder="Elaborate" />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-12 sm:col-span-2">
                    <Form.Item
                      label="Did the employee use teach-back?"
                      name="teachback"
                      className="col-span-6 sm:col-span-2"
                      rules={[
                        {
                          required: true,
                          message: "Please select feedback type!",
                        },
                      ]}
                    >
                      <Select
                        options={[
                          {
                            value: "yes",
                            label: "Yes",
                          },
                          {
                            value: "no",
                            label: "No",
                          },
                          {
                            value: "n/a",
                            label: "Not Applicable",
                          },
                        ]}
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-12 sm:col-span-2">
                    <Form.Item
                      label="Comments:"
                      name="teachback_note"
                      className="col-span-6 sm:col-span-2"
                      rules={[
                        { required: true, message: "Please enter comments!" },
                      ]}
                    >
                      <TextArea rows={4} placeholder="Elaborate" />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-12 sm:col-span-2">
                    <Form.Item
                      label="Did the employee listen carefully and explain things in a way the patient could understand?"
                      name="listen"
                      className="col-span-6 sm:col-span-2"
                      rules={[
                        {
                          required: true,
                          message: "Please select feedback type!",
                        },
                      ]}
                    >
                      <Select
                        options={[
                          {
                            value: "yes",
                            label: "Yes",
                          },
                          {
                            value: "no",
                            label: "No",
                          },
                          {
                            value: "n/a",
                            label: "Not Applicable",
                          },
                        ]}
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-12 sm:col-span-2">
                    <Form.Item
                      label="Your comments:"
                      name="listen_note"
                      className="col-span-6 sm:col-span-2"
                      rules={[
                        { required: true, message: "Please enter comments!" },
                      ]}
                    >
                      <TextArea rows={4} placeholder="Elaborate" />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-12 sm:col-span-2">
                    <Form.Item
                      label="Plan for follow up:"
                      name="followup_note"
                      className="col-span-6 sm:col-span-2"
                      rules={[
                        { required: true, message: "Please enter comments!" },
                      ]}
                    >
                      <TextArea rows={4} placeholder="Elaborate" />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-12 sm:col-span-2">
                    <Form.Item
                      label="Next Follow Up Date"
                      name="needs_attention"
                      className="col-span-6 sm:col-span-2"
                    >
                      <DatePicker />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-12 sm:col-span-2">
                    <Form.Item
                      wrapperCol={{
                        offset: 8,
                        span: 16,
                      }}
                    >
                      <Button
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 rounded"
                        htmlType="submit"
                      >
                        Submit
                      </Button>
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
}
