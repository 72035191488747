import React from 'react'
import { Avatar, Card, Divider, List, Row, Skeleton} from 'antd';
import mentorReviewStyles from './mentorReview.module.css'
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { Content } from 'antd/lib/layout/layout';
import dayjs from 'dayjs';
import { useUserStore } from '../../../context/UserStore';
import newFeedbackStyles from "../Feedback/NewFeedback.module.css";

export default function MentorReview() {
    const navigate = useNavigate();
    const [employees, setEmployees] = useState([]);
    const [loadingMentorDashboard, setLoadingMentorDashboard] = useState(true);
    const [mentors, setMentors] = useState([]);
    const [needsAttention, setNeedsAttention] = useState([]);
    const user = useUserStore((state) => state.user);

    const getMentorDashbordData = async () => {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/v1/users/mentors/mentor_dashboard`, {
            method: "get",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
            },
            type: "json"
        }).then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        }).then(response => {
            setMentors(response.mentors.data)
            setNeedsAttention(response.needs_attention.data)
            setEmployees(response.employees.data)

            setLoadingMentorDashboard(false);
        }).catch(error => {
            console.log(error)
        });
    };

    const viewEmployee = (id) => {
        navigate(`/my-employees/${id}`);
    };

    const sentimentColor = (feedback_type) => {
        return feedback_type == 'negative' ? 'border-red-500' : 'border-green-500';
    }

    const returnIcon = (type_id) => {
        switch (type_id) {
            case 0: // coaching
                return '/assets/images/coaching-icon.png';
                break;
            case 1: // observation
                return '/assets/images/observation-icon.png';
                break;
            case 2: // development
                return '/assets/images/dialogue-icon.png';
                break;
            case 3: // RRS Observation
                return '/assets/images/dialogue-icon.png';
                break;
        }
    }

    useEffect(() => {
        getMentorDashbordData();
    }, [])

    return (
        <>
            <Row className={`py-8 mb-10 flex items-center justify-center rounded-lg shadow-lg ${mentorReviewStyles.headerBar}`}
                 style={{ background: 'linear-gradient(135deg, #2485B2 0%, #70AEDD 100%)', color: '#fff' }}
            >
                <h1 className='text-4xl md:text-4xl text-center font-bold text-white tracking-wide'>Mentoring Conversations</h1>
            </Row>
            <div className="flex flex-col items-center md:items-start">
                <div className="w-full">
                    <div className="bg-white rounded-lg p-0">
                        <div className="flex justify-center md:justify-start space-x-2 mb-4">
                            <button
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 py-2 rounded"
                                onClick={() => navigate('/my-employees/feedback/new')} >
                                <svg className="w-4 h-4 inline-block mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd"></path></svg>
                                Add Mentor Conversation
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Content>
                <Row>
                    <h2 className='text-lg text-left text-blue'>Touch base with these employees soon: </h2>
                </Row>
                <Skeleton loading={loadingMentorDashboard} active>
                    <List
                        grid={{
                            gutter: 16,
                            column: 4,
                            xs: 1,
                            sm: 2,
                            md: 4,
                            lg: 4,
                            xl: 6,
                            xxl: 3,
                        }}
                        dataSource={needsAttention}
                        renderItem={(item) => (
                            <List.Item
                                className={`border-l-4 border-red-500`}
                            >
                                <Card
                                    hoverable
                                    onClick={() => {
                                        viewEmployee(item.id)
                                    }}
                                >{item.attributes.first_name} {item.attributes.last_name}</Card>
                            </List.Item>
                        )}
                    />
                </Skeleton>
                <Divider />
                <Row>
                    <h2 className='text-lg text-left text-blue'>Mentor Conversations</h2>
                </Row>
                <Skeleton loading={loadingMentorDashboard} active>
                    <List
                        dataSource={mentors}
                        itemLayout="horizontal"
                        renderItem={(item) => (
                            <List.Item
                                className={`border-l-4 ${sentimentColor(item.attributes.feedback_type)} pl-2 pr-4`}
                                actions={
                                    [<Link to={`/my-employees/${item.attributes.mentored_employee.id}/${item.attributes.mentor_type_name.toLowerCase()}/${item.id}/view`}>View Mentor</Link>,
                                    dayjs(item.attributes.created_at).format('MM/DD/YYYY')]}
                            >
                                <List.Item.Meta
                                    avatar={<Avatar shape={'square'} size={40} style={{ 'backgroundColor': 'blue' }} src={returnIcon(item.attributes.mentor_type_id)} />}
                                    title={`${item.attributes.mentored_employee.first_name} ${item.attributes.mentored_employee.last_name}`}
                                    description={item.attributes.mentor_type_name}
                                />
                            </List.Item>
                        )}
                    />
                </Skeleton>

            </Content>
        </>
    )
}
