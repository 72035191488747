import React from 'react';
import { Button, Col, Form, Input, notification, Row, Select, Skeleton } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import editQuestionStyles from './EditQuestion.module.css';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useUserStore } from '../../../../context/UserStore';


export default function EditQuestion() {
    const navigate = useNavigate();
    const { id } = useParams();
    const { TextArea } = Input;
    const [interactionType, setInteractionType] = React.useState(null);
    const [initialValues, setInitialValues] = React.useState({});
    const [question, setQuestion] = React.useState(null);
    const [questionTypes, setQuestionTypes] = React.useState([null]);
    const [roundType, setRoundType] = React.useState(null);
    const [roundTypes, setRoundTypes] = React.useState(null);
    const [isLoadingQuestion, setIsLoadingQuestion] = useState(true);
    const [isLoadingRoundTypes, setIsLoadingRoundTypes] = useState(true);
    const user = useUserStore((state) => state.user);
    const [form] = Form.useForm();
    const [formData, setFormData] = useState({});
    const perspectiveTypes = [
        { label: 'Ask the Subject', value: 0 },
        { label: 'Ask Yourself', value: 1 },
    ]
    const loadQuestion = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/admin/questions/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            },
            type: 'json',
        }).then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                throw new Error(response.statusText);
            }
        }).then(response => {
            setInteractionType(response.question.included.find(item => item.type === 'interaction_type'));
            setQuestion(response.question.data);
            setQuestionTypes(response.question.data.attributes.question_types);
            setRoundType(response.question.included.find(item => item.type === 'round_type'));
            setRoundTypes(response.question.data.attributes.round_types);
            setInitialValues(response.question.data.attributes);
            setIsLoadingQuestion(false);
        }).catch(error => {
            console.log(error);
        })
    };

    const loadRoundTypes = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/v1/round_types`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            },
            type: 'json',
        }).then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                throw new Error(response.statusText);
            }
        }).then(response => {
            setRoundTypes(
                response.roundTypes.data.map(item => {
                    return {
                        value: item.id,
                        label: item.attributes.name
                    }
                })
            );
            setIsLoadingRoundTypes(false);
        }).catch(error => {
            console.log(error);
        })
    };

    const onFieldsChange = (changedFields, allFields) => {
        changedFields.map((field) => {
            setFormData({ ...formData, [field.name[0]]: field.value })
        })
    };

    const onFinish = (values) => {
        updateQuestion(formData);
    };

    const onFinishFailed = (errorInfo) => {
        let error_message = errorInfo.errorFields.map((item) => {
            return item.errors[0];
        })

        notification['error']({
            message: 'Failed to update question',
            description: `${error_message}. `,
        });
    };

    const updateQuestion = async (values) => {
        return fetch(`${process.env.REACT_APP_API_URL}/api/v1/admin/questions/${id}`, {
            method: 'put',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${user.token}`,
            },
            body: JSON.stringify({
                question: values
            }),
            type: "json"
        }).then(response => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        }).then(response => {
            notification.success({
                message: response.message,
                description: response.message,
            });
            navigate(`/admin/questions/${id}`);

        }).catch(error => {
            notification.error({
                message: error.errors,
            });
            navigate(`/admin/questions/${id}/edit`);
        });
    };

    useEffect(() => {
        loadQuestion();
    }, []);

    return (
        <>
            <div className={`py-5 grid grid-cols-1 mb-5 ${editQuestionStyles.headerBar}`}>
                <h1 className='text-4xl text-center text-white'>Edit Question</h1>
            </div>
            <Skeleton loading={isLoadingQuestion} active>
                {(question && roundTypes) && (
                    <>
                        <PageHeader onBack={() => navigate(-1)} ghost={false} title={`${question.attributes.text}`}></PageHeader>
                        <Row>
                            <Col span={20}>
                                <Form
                                    form={form}
                                    layout='vertical'
                                    initialValues={initialValues}
                                    onFieldsChange={onFieldsChange}
                                    onFinish={onFinish}
                                    onFinishFailed={onFinishFailed}
                                >
                                    <Row justify='space-between'>
                                        <Col md={10} xs={24}>
                                            <Form.Item label='Question Text'>
                                                <Form.Item name='text' rules={[{ required: true, message: 'Please input question text!' }]}>
                                                    <Input placeholder='Question Text' />
                                                </Form.Item>
                                            </Form.Item>
                                        </Col>
                                        <Col md={10} xs={24}>
                                            <Form.Item label='Before Help Content'>
                                                <Form.Item name='help_before' rules={[{ required: false, message: 'Input text to go before the question.' }]}>
                                                    <TextArea placeholder='Before Help Content' />
                                                </Form.Item>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row justify='space-between'>
                                        <Col md={10} xs={24}>
                                            <Form.Item label='Round Type'>
                                                <Form.Item name='round_type_id' rules={[{ required: true, message: 'Select a Round Type' }]}>
                                                    <Select
                                                        loading={isLoadingQuestion}
                                                        options={roundTypes}
                                                    ></Select>
                                                </Form.Item>
                                            </Form.Item>
                                        </Col>
                                        <Col md={10} xs={24}>
                                            <Form.Item label='After Help Content'>
                                                <Form.Item name='help_after' rules={[{ required: false, message: 'Input text to go after the question.' }]}>
                                                    <TextArea placeholder='After Help Content' />
                                                </Form.Item>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row justify='space-between'>
                                        <Col md={10} xs={24}>
                                            <Row justify='space-between'>
                                                <Col md={10} xs={24}>
                                                    <Form.Item label='Page'>
                                                        <Form.Item name='page_number' rules={[{ required: true, message: 'Input a Page Number' }]}>
                                                            <Input />
                                                        </Form.Item>
                                                    </Form.Item>

                                                </Col>
                                                <Col md={10} xs={24}>
                                                    <Form.Item label='Order'>
                                                        <Form.Item name='order' rules={[{ required: true, message: 'Select a Round Type' }]}>
                                                            <Input />
                                                        </Form.Item>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={10} xs={24}>
                                            <Form.Item label='Perspective'>
                                                <Form.Item name='perspective' rules={[{ required: false, message: 'Choose Perspective' }]}>
                                                    <Select
                                                        loading={isLoadingQuestion}
                                                        options={perspectiveTypes}
                                                    />
                                                </Form.Item>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row justify='space-between'>
                                        <Col md={10} xs={24}>
                                            <Form.Item label='Question Type'>
                                                <Form.Item name='question_type' rules={[{ required: true, message: 'Select a Question Type' }]}>
                                                    <Select
                                                        loading={isLoadingQuestion}
                                                        options={questionTypes}></Select>
                                                </Form.Item>
                                            </Form.Item>
                                        </Col>
                                        <Col md={10} xs={24}>
                                            <Form.Item label='Required for round to be complete?'>
                                                <Form.Item name='required' rules={[{ required: false, message: 'Input text to go after the question.' }]}>
                                                    <Select
                                                        loading={isLoadingQuestion}
                                                        options={
                                                            [
                                                                { value: true, label: 'Yes' },
                                                                { value: false, label: 'No' }
                                                            ]
                                                        }
                                                    ></Select>
                                                </Form.Item>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row justify='space-between'>
                                        <Col md={10} xs={24}>
                                            <Row justify='space-between'>
                                                <Col md={10} xs={24}>
                                                    <Form.Item label='Disabled'>
                                                        <Form.Item
                                                            name='disabled'
                                                            rules={[{ required: false, message: 'Is this question disabled?' }]}>
                                                            <Select
                                                                options={
                                                                    [
                                                                        { value: true, label: 'Yes' },
                                                                        { value: false, label: 'No' }
                                                                    ]
                                                                }
                                                            ></Select>
                                                        </Form.Item>
                                                    </Form.Item>

                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row justify='space-between'>
                                        <Col md={10} xs={24}>
                                            <Form.Item>
                                                <Form.Item>
                                                    <Button
                                                        className='bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 rounded'
                                                        htmlType="submit"
                                                    >
                                                        Submit
                                                    </Button>
                                                </Form.Item>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    </>

                )}
            </Skeleton>
        </>
    )
}
